import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import chunk from 'lodash.chunk';
import {
  TAuction,
  TAuctionNotes,
  TBid,
  TCardinalPoint,
} from '@lancelot-frontend/api';
import { ReplaceSuitIconsPipe } from '../../pipes/replace-suit-icons.pipe';
import { CaptionComponent } from '../caption/caption.component';
import { ContractComponent } from '../contract/contract.component';
import { TableComponent } from '../table/table.component';

const cardinalPoints: TCardinalPoint[] = ['S', 'W', 'N', 'E'];

@Component({
  selector: 'ffb-auction',
  templateUrl: './auction.component.html',
  styleUrls: ['./auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    ContractComponent,
    TableComponent,
    CaptionComponent,
    KeyValuePipe,
    ReplaceSuitIconsPipe,
  ],
})
export class AuctionComponent implements OnInit, OnChanges {
  private translocoService = inject(TranslocoService);

  @Input() auction!: TAuction;
  @Input() dealer!: TCardinalPoint;
  @Input() notes?: TAuctionNotes;

  columns = cardinalPoints;
  columnHeaders?: { key: TCardinalPoint; label: string }[][];
  data?: Record<TCardinalPoint, null | string>[];

  constructor() {
    this.translocoService
      .selectTranslation('components')
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.columnHeaders = [
          this.columns.map((key) => ({
            key,
            label: this.translocoService.translate(
              `components.cardinalPoints.${key}.short`,
            ),
          })),
        ];
      });
  }

  asBid(val: unknown): TBid {
    return val as TBid;
  }

  ngOnInit() {
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.auction && !changes.auction.firstChange) ||
      (changes.dealer && !changes.dealer.firstChange)
    ) {
      this.setData();
    }
  }

  setData() {
    if (!this.auction) {
      this.data = [];
    } else {
      this.data = chunk(
        Array.from(
          { length: cardinalPoints.indexOf(this.dealer) },
          () => null as null | string,
        ).concat(this.auction.split(' ')),
        4,
      ).map((row) => ({
        S: row[0],
        W: row[1],
        N: row[2],
        E: row[3],
      }));
    }
  }
}
