<ng-container *transloco="let t">
  @if (columnHeaders && data) {
    <ng-template #bidTemplate let-cellData="cellData">
      @if (cellData) {
        <ffb-contract [contract]="cellData"></ffb-contract>
      }
      @for (note of notes | keyvalue; track note.key; let i = $index) {
        @if (note.key === asBid(cellData)) {
          <sup> ({{ i + 1 }}) </sup>
        }
      }
    </ng-template>
    <ng-template #emptyTemplate>
      <p class="center italic my2">
        {{ t('components.auction.noData') }}
      </p>
    </ng-template>
    <ffb-table
      name="auction-table"
      [data]="data"
      [columns]="columns"
      [columnHeaders]="columnHeaders"
      [rowCellTemplates]="{
        S: bidTemplate,
        W: bidTemplate,
        N: bidTemplate,
        E: bidTemplate
      }"
      [emptyTemplate]="emptyTemplate"
      >
    </ffb-table>
    @if (notes) {
      <div class="p2">
        @for (note of notes | keyvalue; track note.key; let i = $index) {
          <ffb-caption>
            ({{ i + 1 }}) <ffb-contract [contract]="note.key"></ffb-contract>:
            <span [innerHTML]="note.value! | replaceSuitIcons"></span>
          </ffb-caption>
        }
      </div>
    }
  }
</ng-container>
