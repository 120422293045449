import {
  ComponentRef,
  Directive,
  EventEmitter,
  INJECTOR,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { PolymorpheusTemplate } from '@tinkoff/ng-polymorpheus';
import { DropdownMenuComponent } from './dropdown-menu.component';

@Directive({
  selector: '[ffbDropdownMenu]',
  standalone: true,
})
export class DropdownMenuDirective
  extends PolymorpheusTemplate<Record<string, unknown>>
  implements OnDestroy
{
  private readonly injector = inject(INJECTOR);
  private readonly viewContainerRef = inject(ViewContainerRef);
  readonly content: TemplateRef<Record<string, unknown>> = inject(TemplateRef);

  private dropdownMenuRef: ComponentRef<DropdownMenuComponent> | null = null;
  public isVisible = false;

  @Input()
  set ffbDropdownMenu(open: boolean) {
    if (open) {
      this.show();
    } else {
      this.hide();
    }
  }

  @Output()
  readonly ffbDropdownMenuChange = new EventEmitter<boolean>();

  @Input('ffbDropdownMenuStyle')
  style = '';

  ngOnDestroy() {
    this.hide();
  }

  private show() {
    if (this.dropdownMenuRef !== null) {
      return;
    }

    this.dropdownMenuRef = this.viewContainerRef.createComponent(
      DropdownMenuComponent,
      { injector: this.injector },
    );

    this.dropdownMenuRef.changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.isVisible = true;
    });
  }

  private hide() {
    if (this.dropdownMenuRef === null) {
      return;
    }

    this.viewContainerRef.clear();
    this.dropdownMenuRef = null;
    this.isVisible = false;
  }
}
