import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TContract } from '@lancelot-frontend/api';
import { SuitIconComponent } from '../suit-icon/suit-icon.component';

@Component({
  selector: 'ffb-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, SuitIconComponent],
})
export class ContractComponent implements OnInit, OnChanges {
  @Input({ required: true }) contract!: TContract;
  @Input() tricks?: number;
  @Input() result?: string;

  isPass?: boolean;
  level?: number;
  doubled?: boolean;
  redoubled?: boolean;
  computedResult?: number | string;

  ngOnInit() {
    this.setComputedProperties();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.contract && !changes.contract.firstChange) ||
      (changes.tricks && !changes.tricks.firstChange)
    ) {
      this.setComputedProperties();
    }
  }

  setComputedProperties() {
    this.isPass = this.contract.toUpperCase() === 'PASS';
    if (!this.isPass) {
      const level = parseInt(
        this.contract.toUpperCase().replace(/C|D|H|S|NT|X/g, ''),
      );
      if (!isNaN(level)) {
        this.level = level;
      }
    } else {
      this.level = undefined;
    }
    this.doubled = this.contract.toUpperCase().includes('X');
    this.redoubled = this.contract.toUpperCase().includes('XX');
    if (!(this.isPass || this.tricks === undefined || !this.level)) {
      const tricksDiff = this.tricks - (this.level + 6);
      this.computedResult =
        tricksDiff === 0 ? '=' : tricksDiff > 0 ? `+${tricksDiff}` : tricksDiff;
    } else {
      this.computedResult = undefined;
    }
  }
}
