<ng-container *transloco="let t">
  <ng-template
    #boardNumberTemplate
    let-cellData="cellData"
    let-rowData="rowData"
    let-rowIndex="rowIndex"
    >
    <div class="flex flex-row justify-between">
      {{ cellData }}
      @if (getRowLink?.(rowData, rowIndex)) {
        <button
          tuiIconButton
          size="xs"
          appearance="primary-outline"
          shape="rounded"
          icon="tuiIconPlus"
          class="no-print"
        ></button>
      }
    </div>
  </ng-template>

  <ng-template #contractTemplate let-cellData="cellData">
    @if (cellData && cellData !== '-') {
      <ffb-contract [contract]="cellData"></ffb-contract>
    } @else {
      <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    }
  </ng-template>

  <ng-template #declarerTemplate let-cellData="cellData">
    @if (cellData && cellData !== '-') {
      {{ t('components.cardinalPoints.' + cellData + '.short') }}
    } @else {
      <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    }
  </ng-template>

  <ng-template #leadTemplate let-cellData="cellData">
    @if (cellData && cellData !== '-') {
      <ffb-opening-lead [openingLead]="cellData"></ffb-opening-lead>
    } @else {
      <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    }
  </ng-template>

  <ng-template #placeholder>
    <span class="color-text-03">—</span>
  </ng-template>

  <ffb-table
    name="roadmap-table"
    [loading]="loading"
    [data]="data"
    [getCellData]="getCellData"
    [columns]="[
      'boardNumber',
      'openRoom.contract',
      'openRoom.declarer',
      'openRoom.lead',
      'openRoom.result',
      'openRoom.nsScore',
      'openRoom.ewScore',
      'closedRoom.contract',
      'closedRoom.declarer',
      'closedRoom.lead',
      'closedRoom.result',
      'closedRoom.nsScore',
      'closedRoom.ewScore',
      'homeTeam',
      'awayTeam'
    ]"
    [columnHeaders]="[
      [
        {
          key: 'boardNumber',
          label: t('components.board')
        },
        {
          key: 'openRoom',
          label: t('components.room.O')
        },
        {
          key: 'closedRoom',
          label: t('components.room.C')
        },
        {
          key: 'homeTeam',
          label: homeTeamLabel
        },
        {
          key: 'awayTeam',
          label: awayTeamLabel
        }
      ],
      [
        {
          key: 'openRoom.contract',
          label: t('components.contract.short')
        },
        {
          key: 'openRoom.declarer',
          label: t('components.declarer.short')
        },
        {
          key: 'openRoom.lead',
          label: t('components.openingLead.short')
        },
        {
          key: 'openRoom.result',
          label: t('components.result.short')
        },
        {
          key: 'openRoom.nsScore',
          label: t('components.orientation.NS.short')
        },
        {
          key: 'openRoom.ewScore',
          label: t('components.orientation.EW.short')
        },
        {
          key: 'closedRoom.contract',
          label: t('components.contract.short')
        },
        {
          key: 'closedRoom.declarer',
          label: t('components.declarer.short')
        },
        {
          key: 'closedRoom.lead',
          label: t('components.openingLead.short')
        },
        {
          key: 'closedRoom.result',
          label: t('components.result.short')
        },
        {
          key: 'closedRoom.nsScore',
          label: t('components.orientation.NS.short')
        },
        {
          key: 'closedRoom.ewScore',
          label: t('components.orientation.EW.short')
        }
      ]
    ]"
    [rowCellTemplates]="{
      boardNumber: boardNumberTemplate,
      'openRoom.contract': contractTemplate,
      'closedRoom.contract': contractTemplate,
      'openRoom.declarer': declarerTemplate,
      'closedRoom.declarer': declarerTemplate,
      'openRoom.lead': leadTemplate,
      'closedRoom.lead': leadTemplate
    }"
    [layout]="{
      boardNumber: { header: { rowSpan: 2 } },
      openRoom: {
        align: 'center',
        header: {
          colSpan: 6,
          borders: {
            left: true,
            bottom: true
          }
        }
      },
      closedRoom: {
        align: 'center',
        header: {
          colSpan: 6,
          borders: {
            left: true,
            right: true,
            bottom: true
          }
        }
      },
      'openRoom.contract': {
        align: 'center',
        header: {
          borders: {
            left: true
          }
        },
        cell: {
          borders: {
            left: true
          }
        }
      },
      'openRoom.declarer': { align: 'center' },
      'openRoom.lead': { align: 'center' },
      'openRoom.result': { align: 'center' },
      'openRoom.nsScore': { align: 'center' },
      'openRoom.ewScore': { align: 'center' },
      'closedRoom.contract': {
        align: 'center',
        header: {
          borders: {
            left: true
          }
        },
        cell: {
          borders: {
            left: true
          }
        }
      },
      'closedRoom.declarer': { align: 'center' },
      'closedRoom.lead': { align: 'center' },
      'closedRoom.result': { align: 'center' },
      'closedRoom.nsScore': { align: 'center' },
      'closedRoom.ewScore': {
        align: 'center',
        header: {
          borders: {
            right: true
          }
        },
        cell: {
          borders: {
            right: true
          }
        }
      },
      homeTeam: { align: 'center', header: { rowSpan: 2 } },
      awayTeam: { align: 'center', header: { rowSpan: 2 } }
    }"
[selectedRowHandler]="selectedRowHandler"
[getRowLink]="getRowLink"
>
</ffb-table>
</ng-container>
