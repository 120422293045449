import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule } from '@taiga-ui/core';

@Component({
  selector: 'ffb-locale-button',
  templateUrl: './locale-button.component.html',
  styleUrls: ['./locale-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiLinkModule],
})
export class LocaleButtonComponent {
  @Input() locale!: string;
}
