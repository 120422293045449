<ng-container *transloco="let t">
  <ffb-overlay backgroundColor="white" *tuiPortal="true">
    <div
      class="screen-height screen-width flex flex-column justify-between items-stretch"
      *ffbContentAdornment="4; backgroundColor: 'light'"
    >
      <div class="flex flex-0">
        <div class="flex flex-auto flex-column max-width-1 mx-auto p2">
          <div class="flex flex-auto justify-center items-center p3">
            <div class="error-overlay-logo-container">
              <ffb-logo class="center" tuiMode="onLight"></ffb-logo>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="max-width-1 m-auto p2">
          <h3 class="tui-text_h3 mb2">{{ t('errors.oops') }} 😕</h3>

          <ng-template #errorTemplate>
            <div class="mb2 px2">
              <p class="tui-text_body-m">
                @if (!error) {
                  {{ t('errors.unknown') }}
                } @else {
                  <ng-container *polymorpheusOutlet="error as text">
                    {{ text }}
                  </ng-container>
                }
                {{ t('errors.pleaseRetryByReloading') }}
              </p>
              <br />
              <p
                [innerHTML]="
                  t('actions.contactSupportByEmailIfTheProblemPersists')
                "
              ></p>
            </div>
          </ng-template>

          <ffb-error [error]="errorTemplate" [action]="action">
          </ffb-error>
          <ng-content></ng-content>
        </div>
      </div>
      <div class="flex-0 mt3 pt2"></div>
    </div>
  </ffb-overlay>
</ng-container>
