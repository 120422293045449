<div
  class="action flex flex-row justify-between items-center full-width full-height"
  [ngClass]="{ p2: padding }"
  >
  <div class="flex-auto">
    <ng-content></ng-content>
  </div>
  @if (icon) {
    <tui-svg [src]="icon"></tui-svg>
  }
</div>
