import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { UpperFirstPipe } from '@lancelot-frontend/core';
import { RowComponent } from '../row/row.component';

@Component({
  selector: 'ffb-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, RowComponent, UpperFirstPipe],
})
export class ResourceNotFoundComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);

  @Input() resource: string | undefined;

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ resource }) => {
      if (resource) {
        this.resource = resource;
      }
    });
  }
}
