import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { captureException } from '@sentry/capacitor';
import { TuiButtonModule, TuiModeModule, TuiSvgModule } from '@taiga-ui/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

type TPlatforms = {
  email: boolean;
  facebook: boolean;
  linkedin: boolean;
  twitter: boolean;
  whatsapp: boolean;
};

@Component({
  selector: 'ffb-share-this',
  templateUrl: './share-this.component.html',
  styleUrls: ['./share-this.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiButtonModule, TuiModeModule, TuiSvgModule],
})
export class ShareThisComponent implements OnChanges {
  private translocoService = inject(TranslocoService);
  private environmentService = inject(EnvironmentService);

  @Output() share = new EventEmitter<{ method: string; url: string }>();

  isNativePlatform = Capacitor.isNativePlatform();

  private _platforms: TPlatforms = {
    facebook: typeof FB !== 'undefined',
    twitter: true,
    linkedin: true,
    whatsapp: true,
    email: true,
  };

  @Input() url = '';
  @Input() set platforms(platforms: Partial<TPlatforms>) {
    this._platforms = { ...this._platforms, ...platforms };
  }

  availablePlatforms!: (keyof TPlatforms)[];
  fullUrl!: string;
  title!: string;
  text!: string;
  dialogTitle!: string;

  ngOnChanges() {
    this.availablePlatforms = Object.keys(this._platforms).filter(
      (platform) => this._platforms[platform as keyof TPlatforms],
    ) as (keyof TPlatforms)[];
    this.fullUrl = this.environmentService.get('baseUrl', '') + this.url;
    this.title = this.translocoService.translate('share.title');
    this.text = this.translocoService.translate('share.text');
    this.dialogTitle = this.translocoService.translate('actions.share');
  }

  nativeShare() {
    Share.share({
      title: this.title,
      text: this.text,
      url: this.fullUrl,
      dialogTitle: this.dialogTitle,
    })
      .then(() => {
        this.share.emit({ method: 'native', url: this.fullUrl });
      })
      .catch((error: Error) => {
        if (error.message !== 'Share canceled') {
          captureException(error);
        }
      });
  }

  openPopup(url: string, minWidth = 600, minHeight = 400) {
    const windowLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const windowTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;
    const windowWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    const windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    const width = Math.min(Math.max(windowWidth / 2, minWidth), windowWidth);
    const height = Math.min(
      Math.max(windowHeight / 2, minHeight),
      windowHeight,
    );
    const left = (windowWidth - width) / 2 + windowLeft;
    const top = (windowHeight - height) / 2 + windowTop + 10;

    window.open(
      url,
      '',
      `resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
  }

  shareWith(platform: keyof TPlatforms) {
    switch (platform) {
      case 'facebook': {
        this.share.emit({ method: 'Facebook', url: this.fullUrl });
        FB.ui({
          method: 'share',
          href: this.fullUrl,
          hashtag: 'LebBridgeCaCartonne',
        });
        break;
      }
      case 'twitter': {
        this.share.emit({ method: 'Twitter', url: this.fullUrl });
        this.openPopup(
          `https://twitter.com/intent/tweet?via=FF_Bridge&hashtags=FFBridge,GenerationsBridge,RejoignezLaTeam,LeBridgeCaCartonne&text=${this.title}&url=${this.fullUrl}`,
        );
        break;
      }
      case 'linkedin': {
        this.share.emit({ method: 'LinkedIn', url: this.fullUrl });
        this.openPopup(`https://linkedin.com/share/?url=${this.fullUrl}`);
        break;
      }
      case 'whatsapp': {
        this.share.emit({ method: 'WhatsApp', url: this.fullUrl });
        this.openPopup(
          `https://web.whatsapp.com/send?text=${this.title} ${this.fullUrl}`,
          760,
          610,
        );
        break;
      }
      case 'email': {
        this.share.emit({ method: 'Email', url: this.fullUrl });
        this.openPopup(
          `mailto:?subject=${this.title}&body=${this.text} ${this.fullUrl}`,
        );
        break;
      }
    }
  }
}
