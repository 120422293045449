import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { NavigationExtras, RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiLoaderModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiPaginationModule } from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import get from 'lodash.get';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';

type TCellLayout = {
  align?: 'center' | 'left' | 'right';
  borders?: {
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
    top?: boolean;
  };
  colSpan?: number;
  rowSpan?: number;
  verticalAlign?: string;
};

type TColumnHeader = {
  key: string;
  label?: string;
  labelTranslationKey?: string;
  sortable?: boolean;
};

@Component({
  selector: 'ffb-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiLetModule,
    TuiTableModule,
    NgClass,
    NgTemplateOutlet,
    TuiSvgModule,
    RouterLink,
    PolymorpheusModule,
    TuiButtonModule,
    TuiLoaderModule,
    InfiniteScrollComponent,
    AsyncPipe,
    TuiPaginationModule,
  ],
})
export class TableComponent<T extends Record<keyof T, unknown>> {
  readonly breakpointObserver = inject(BreakpointObserver);

  /* eslint-disable @typescript-eslint/member-ordering */
  @Input() name!: string;
  @Input() loading = false;
  @Input() loadingNewPage = false;
  @Input() data: T[] = [];
  @Input() getCellData: (item: T, column: string, rowIndex: number) => unknown =
    (item, column) => get(item, column);
  private _columns: string[] = [];
  get columns() {
    return (this.displayRowIndex ? ['rowIndex'] : []).concat(
      this._columns.concat(this.actions ? ['actions'] : []),
    );
  }
  @Input() set columns(columns) {
    this._columns = columns;
  }
  private _columnHeaders: TColumnHeader[][] = [];
  get columnHeaders(): TColumnHeader[][] {
    return this._columnHeaders.map((row, i) => {
      if (i === this._columnHeaders.length - 1) {
        return (
          this.displayRowIndex
            ? [
                {
                  key: 'rowIndex',
                  labelTranslationKey: 'components.rowIndex',
                },
              ]
            : []
        ).concat(
          // @ts-expect-error types are not inferred correctly
          row.concat(
            this.actions
              ? [
                  {
                    key: 'actions',
                    labelTranslationKey: 'components.actions',
                  },
                ]
              : [],
          ),
        );
      }
      return row;
    });
  }
  @Input() set columnHeaders(columnHeaders) {
    this._columnHeaders = columnHeaders;
  }
  @Input() layout: Partial<
    Record<
      string,
      {
        align?: TCellLayout['align'];
        cell?: Omit<TCellLayout, 'colSpan'>;
        header?: Omit<TCellLayout, 'verticalAlign'>;
      }
    >
  > & {
    align?: TCellLayout['align'];
    cells?: Omit<TCellLayout, 'colSpan'>;
  } = {};
  @Input() headerCellTemplates: Record<string, TemplateRef<unknown>> = {};
  @Input() rowCellTemplates: Record<string, TemplateRef<unknown>> = {};
  @Input() rowCellTemplate?: TemplateRef<unknown>;
  @Input() rowTemplate?: TemplateRef<unknown>;
  @Input() rowHeaderTemplate?: TemplateRef<unknown>;
  @Input() emptyTemplate?: TemplateRef<unknown>;
  @Input() actions?: PolymorpheusContent;
  @Input() displayRowIndex = false;
  @Input() direction: -1 | 1 = 1;
  @Input() sortBy?: string;
  @Input() pagination?: {
    itemsPerPage: number;
    page: number;
    pageCount: number;
  };
  @Input() forceInfiniteScroll = false;
  @Input() getRowId?: (row: T, index: number) => string;
  @Input() getRowClass?: (
    row: T,
    index: number,
  ) => { [klass: string]: unknown } | Set<string> | string | string[];
  @Input() selectedRowHandler?: (row: T) => boolean;
  @Input() getRowLink?: (
    row: T,
    index: number,
  ) => [
    commands: (number | string)[] | number | string,
    navigationExtras?: NavigationExtras,
  ];
  @Output() pageChange = new EventEmitter<number>();
  @Output() reachEnd = new EventEmitter<void>();
  @Output() clickOnRow = new EventEmitter<[T, number]>();
  @Output() directionChange = new EventEmitter<-1 | 1>();
  @Output() sortByChange = new EventEmitter<string>();

  onClickOnRow(item: T, index: number) {
    this.clickOnRow.emit([item, index]);
  }

  onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  onReachEnd() {
    this.reachEnd.emit();
  }

  updateSort(key: string): void {
    if (this.sortBy === key) {
      this.directionChange.emit(this.direction === 1 ? -1 : 1);
    } else {
      this.sortByChange.emit(key);
      this.directionChange.emit(1);
    }
  }
}
