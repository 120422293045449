import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TSuit } from '@lancelot-frontend/api';

const getSuitElement = (suit: TSuit) =>
  `<suit-icon-element suit=${suit} fittext="true"></suit-icon-element>`;

@Pipe({
  name: 'replaceSuitIcons',
  standalone: true,
})
export class ReplaceSuitIconsPipe implements PipeTransform {
  protected sanitizer = inject(DomSanitizer);

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      value
        .replace(/\\C/g, getSuitElement('C'))
        .replace(/\\D/g, getSuitElement('D'))
        .replace(/\\H/g, getSuitElement('H'))
        .replace(/\\S/g, getSuitElement('S')),
    );
  }
}
