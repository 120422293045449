import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TLead } from '@lancelot-frontend/api';
import { TypeofPipe } from '@lancelot-frontend/core';
import { SuitIconComponent } from '../suit-icon/suit-icon.component';

@Component({
  selector: 'ffb-opening-lead',
  templateUrl: './opening-lead.component.html',
  styleUrls: ['./opening-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, SuitIconComponent, TypeofPipe],
})
export class OpeningLeadComponent implements OnInit, OnChanges {
  @Input({ required: true }) openingLead!: TLead;

  card?: number | string;

  ngOnInit() {
    this.setComputedProperties();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.openingLead && !changes.openingLead.firstChange) ||
      (changes.tricks && !changes.tricks.firstChange)
    ) {
      this.setComputedProperties();
    }
  }

  setComputedProperties() {
    this.card = this.openingLead.replace(/C|D|H|S/g, '').replace('T', '10');
    if (!isNaN(parseInt(this.card))) {
      this.card = parseInt(this.card);
    }
  }
}
