const landscape = {
  background: [
    {
      style: {
        height: '27%',
        top: 0,
        right: 0,
        'border-top-left-radius': '0px',
        'border-top-right-radius': '0px',
        'border-bottom-right-radius': '0px',
        'border-bottom-left-radius': '28%',
      },
      class: 'bg-pink',
    },
    {
      style: {
        height: '20%',
        bottom: 0,
        left: 0,
        'aspect-ratio': '1/0.9',
        'border-top-left-radius': '0px',
        'border-top-right-radius': '20%',
        'border-bottom-right-radius': '0px',
        'border-bottom-left-radius': '0px',
      },
      class: 'bg-yellow',
    },
    {
      style: {
        height: '22.5%',
        bottom: 0,
        left: 0,
        transform: 'translate(75%, -40%)',
      },
      class: 'bg-purple',
    },
    {
      style: {
        height: '9%',
        top: 0,
        left: '70%',
        'max-height': '15vw',
        'aspect-ratio': '1/0.5',
        'border-radius': '18%/36%',
        'border-top-left-radius': '0px',
        'border-top-right-radius': '0px',
      },
      class: 'bg-blue',
    },
    {
      style: {
        height: '22.5%',
        top: 0,
        left: 0,
        'aspect-ratio': '1/2',
        'border-radius': '34%/17%',
        'border-top-left-radius': '0px',
        'border-bottom-left-radius': '0px',
        transform: 'translate(0, 30%)',
      },
      class: 'bg-orange',
    },
  ],
  foreground: [],
};

const portrait = {
  background: [
    {
      style: {
        width: '45%',
        bottom: 0,
        right: 0,
        'border-top-right-radius': '0px',
        'border-bottom-left-radius': '0px',
        'border-bottom-right-radius': '0px',
      },
      class: 'bg-purple',
    },
    {
      style: {
        width: '20%',
        bottom: 0,
        left: 0,
        transform: 'translate(0, -130%)',
        'aspect-ratio': '1/2',
        'border-radius': '30%/15%',
        'border-top-left-radius': '0px',
        'border-bottom-left-radius': '0px',
      },
      class: 'bg-orange',
    },
    {
      style: {
        width: '18%',
        top: 0,
        right: 0,
        'border-radius': '50%',
        'border-top-left-radius': '0px',
        'border-top-right-radius': '0px',
        'border-bottom-right-radius': '0px',
      },
      class: 'bg-pink',
    },
  ],
  foreground: [],
};

export default {
  landscape,
  portrait,
};
