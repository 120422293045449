<ng-container *transloco="let t">
  <div class="relative" (waResizeObserver)="onResize($event)">
    <!-- Board schema -->
    <div class="absolute top-0 left-0">
      <div class="board {{ vulnerability }}Vulnerable">
        <div
          class="flex flex-column justify-between items-center absolute top-0 right-0 bottom-0 left-0"
        >
          <ng-container
            *ngTemplateOutlet="
              boardCardinalPointTemplate;
              context: { cardinalPoint: 'N' }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              boardCardinalPointTemplate;
              context: { cardinalPoint: 'S' }
            "
          ></ng-container>
        </div>
        <div
          class="flex flex-row justify-between items-center absolute top-0 right-0 bottom-0 left-0"
        >
          <ng-container
            *ngTemplateOutlet="
              boardCardinalPointTemplate;
              context: { cardinalPoint: 'W' }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              boardCardinalPointTemplate;
              context: { cardinalPoint: 'E' }
            "
          ></ng-container>
        </div>
        <div
          class="flex flex-row justify-center items-center absolute top-0 right-0 bottom-0 left-0"
        >
          <div class="flex flex-row justify-center items-center number">
            <h5 class="tui-text_h5" data-test-id="board-number">
              {{ boardNumber }}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <!-- Board information (dealer and vulnerability) -->
    <div class="absolute top-0 right-0">
      @if (dealer) {
        <p class="right-align">{{ t('components.dealer.long') }}</p>
        <p class="bold right-align" data-test-id="dealer">
          {{ t('components.cardinalPoints.' + dealer + '.long') }}
        </p>
      }
      @if (vulnerability) {
        <p class="right-align mt1">{{ t('components.vulnerability.long') }}</p>
        <p class="bold right-align" data-test-id="vulnerability">
          {{ t('components.vulnerability.' + vulnerability + '.long') }}
        </p>
      }

      <!--  Result (on small screens) -->
      @if (shrunk === true) {
        <div class="mt1">
          <ng-container *ngTemplateOutlet="resultTemplate"></ng-container>
        </div>
      }
    </div>

    <!-- Hands -->
    <div class="flex flex-row justify-center">
      <ng-container
        *ngTemplateOutlet="handTemplate; context: { cardinalPoint: 'N' }"
      ></ng-container>
    </div>
    <div class="flex flex-row justify-between items-center my1">
      <ng-container
        *ngTemplateOutlet="handTemplate; context: { cardinalPoint: 'W' }"
      ></ng-container>
      <!--  Result (on large screens) -->
      @if (shrunk === false) {
        <ng-container *ngTemplateOutlet="resultTemplate"></ng-container>
      }
      <ng-container
        *ngTemplateOutlet="handTemplate; context: { cardinalPoint: 'E' }"
      ></ng-container>
    </div>
    <div class="flex flex-row justify-center items-center">
      <ng-container
        *ngTemplateOutlet="handTemplate; context: { cardinalPoint: 'S' }"
      ></ng-container>
    </div>
  </div>

  <!-- Hand template -->
  <ng-template #handTemplate let-cardinalPoint="cardinalPoint">
    <div class="hand">
      <ng-container
        *tuiLet="
          vulnerability === 'B' ||
          (vulnerability === 'N' && 'NS'.includes(cardinalPoint)) ||
          (vulnerability === 'E' && 'EW'.includes(cardinalPoint)) as vulnerable
        "
      >
        <ng-container
          *tuiLet="lineup?.[(cardinalPoint | as: TCardinalPoint)] as player"
        >
          <div
            class="player"
            [ngClass]="{
              vulnerable,
              'not-vulnerable': vulnerability && !vulnerable,
              flex: !player,
              'items-center': !player,
              'justify-center': !player
            }"
            attr.data-test-id="player-{{ cardinalPoint }}"
          >
            @if (!player) {
              {{ t('components.cardinalPoints.' + cardinalPoint + '.long') }}
            }
            @if (player) {
              <div class="max-full-height flex flex-row justify-start gap1">
                <div class="avatar">
                  @if (player.avatarUrl) {
                    <ffb-image [url]="getAvatarUrl(player)"></ffb-image>
                  }
                  @if (!player.avatarUrl) {
                    <tui-svg src="/assets/icon-set/avatar.svg"></tui-svg>
                  }
                </div>
                <div class="player-name flex-auto">
                  @if (player.firstName) {
                    <p class="ellipsis">{{ player.lastName | uppercase }}</p>
                    <p class="ellipsis">{{ player.firstName }}</p>
                  }
                  @else {
                    {{
                      t('components.cardinalPoints.' + cardinalPoint + '.long')
                    }}
                    <p class="ellipsis">{{ player }}</p>
                  }
                </div>
              </div>
            }
          </div>
        </ng-container>
      </ng-container>
      <div class="suits">
        @for (suit of ['spade', 'heart', 'diamond', 'club']; track suit) {
          <div
            class="cards flex flex-row justify-start items-center"
            attr.data-test-id="player-{{ cardinalPoint }}-{{ suit }}"
          >
            <ffb-suit-icon
              [suit]="suit[0].toUpperCase()"
              [fittext]="true"
            ></ffb-suit-icon>
            @for (
              card of parsedDeal?.[cardinalPoint | as: TCardinalPoint]?.[suit[0].toUpperCase() | as : TSuit];
              track card
            ) {
              <div
                class="card flex flex-column justify-center items-center"
                [ngClass]="{
                  ten: card === 10,
                  openingLead:
                    parsedLead?.card === card.toString() &&
                    parsedLead?.suit === suit[0].toUpperCase()
                }"
              >
                @if ((card | typeof) === 'number') {
                  {{ card }}
                }
                @if ((card | typeof) === 'string') {
                  {{ t('components.cards.' + card + '.short') }}
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>

  <!-- Board schema cardinal point template -->
  <ng-template #boardCardinalPointTemplate let-cardinalPoint="cardinalPoint">
    <ng-container
      *tuiLet="
        vulnerability === 'B' ||
        (vulnerability === 'N' && 'NS'.includes(cardinalPoint)) ||
        (vulnerability === 'E' && 'EW'.includes(cardinalPoint)) as vulnerable
      "
    >
      <p
        class="cardinalPoint center"
        [ngClass]="{
          vulnerable,
          'not-vulnerable': vulnerability && !vulnerable,
          dealer: dealer === cardinalPoint
        }"
      >
        @if (dealer === cardinalPoint) {
          {{ t('components.dealer.short') }}
        }
        @else {
          {{ t('components.cardinalPoints.' + cardinalPoint + '.short') }}
        }
      </p>
    </ng-container>
  </ng-template>

  <!-- Result template -->
  <ng-template #resultTemplate>
    @if (contract && contract !== '-') {
      <div data-test-id="result">
        <p [ngClass]="{ 'right-align': shrunk, center: !shrunk }" class="bold">
          <ffb-contract
            [contract]="contract | as: TContract"
            [tricks]="tricks"
            [result]="result"
          ></ffb-contract>
        </p>
        @if (
          contract &&
          contract.toUpperCase() !== 'PASS' &&
          declarer &&
          declarer !== '-'
        ) {
          <p
            [ngClass]="{ 'right-align': shrunk, center: !shrunk }"
            class="color-text-03"
          >
            <ffb-declarer
              [declarer]="declarer | as: TCardinalPoint"
            ></ffb-declarer>
          </p>
        }
        @if (note !== undefined) {
          <p [ngClass]="{ 'right-align': shrunk, center: !shrunk }">
            {{
              note
                | decimalWithUnit
                  : {}
                  : (scoringType === 'percent'
                      ? '%'
                      : scoringType === 'bam'
                        ? t('components.BAM')
                        : scoringType === 'imp' || scoringType === 'vp'
                          ? t('components.IMP')
                          : '')
            }}
          </p>
        }
      </div>
    }
  </ng-template>
</ng-container>
