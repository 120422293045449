import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { IBoardScore } from '@lancelot-frontend/api';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { ContractComponent } from '../contract/contract.component';
import { OpeningLeadComponent } from '../opening-lead/opening-lead.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-teams-board-scores',
  templateUrl: './teams-board-scores.component.html',
  styleUrls: ['./teams-board-scores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    TranslocoDirective,
    TuiLetModule,
    ContractComponent,
    OpeningLeadComponent,
    TableComponent,
  ],
})
export class TeamsBoardScoresComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() boardScores: IBoardScore[] = [];
  @Input() selectedRowHandler?: (row: IBoardScore) => boolean;
  @Input() actions?: PolymorpheusContent;
  @Input() loading?: boolean;

  @Output() clickOnRow = new EventEmitter<[IBoardScore, number]>();

  getCellData = (score: IBoardScore, column: string) => {
    switch (column) {
      case 'table': {
        return score.lineup.segment.game.tableNumber;
      }
      case 'room': {
        return score.lineup.room;
      }
      case 'homeTeam': {
        return score.lineup.segment.game.homeTeam.label;
      }
      case 'awayTeam': {
        return score.lineup.segment.game.awayTeam.label;
      }
      case 'IMP.homeTeam':
      case 'IMP.awayTeam': {
        const note =
          score[column === 'IMP.awayTeam' ? 'ewNote' : 'nsNote'] || 0;
        return note === 0 ? '—' : note > 0 ? note : '';
      }
      case 'nsScore': {
        if (score.contract.toUpperCase() === 'PASS') {
          return '';
        }

        return score.nsScore || `-${score.ewScore}`;
      }
      default: {
        return score[column as keyof IBoardScore];
      }
    }
  };
}
