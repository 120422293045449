<!--  TODO: Refactor every variant to prevent overflows and completely remove overflow hidden -->
<div
  class="wrapper bg-{{ backgroundColor }}"
  [class.overflow-hidden]="variant !== 4"
  (waResizeObserver)="onResize($event)"
  >
  @if (cards) {
    <div class="background">
      @for (card of cards.background; track card) {
        <div
          [ngStyle]="card.style"
          class="card {{ card.class }}"
        ></div>
      }
    </div>
  }
  <div class="relative">
    <ng-container *polymorpheusOutlet="content as text">
      {{ text }}
    </ng-container>
  </div>
  @if (cards) {
    <div class="foreground">
      @for (card of cards.foreground; track card) {
        <div
          [ngStyle]="card.style"
          class="card {{ card.class }}"
        ></div>
      }
    </div>
  }
</div>
