import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TCardinalPoint, TOptimumScore, TSuit } from '@lancelot-frontend/api';
import { ObjectEntries } from '@lancelot-frontend/core';
import { SuitIconComponent } from '../suit-icon/suit-icon.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-optimum-score',
  templateUrl: './optimum-score.component.html',
  styleUrls: ['./optimum-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiLetModule,
    SuitIconComponent,
    TableComponent,
    ObjectEntries,
  ],
})
export class OptimumScoreComponent {
  @Input() optimumScore?: TOptimumScore;

  getCellData = (
    datum: [TCardinalPoint, TOptimumScore[TCardinalPoint]],
    column: 'NT' | 'declarer' | TSuit,
  ) => {
    switch (column) {
      case 'declarer': {
        return datum[0];
      }
      default: {
        return datum[1][column];
      }
    }
  };
}
