import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import {
  IPairSessionScore,
  IRankTeam,
  TScoringType,
  getPairOrientationForSessionScore,
} from '@lancelot-frontend/api';
import {
  AsPipe,
  BreakpointObserver,
  DecimalWithUnitPipe,
} from '@lancelot-frontend/core';
import { ContractComponent } from '../contract/contract.component';
import { OpeningLeadComponent } from '../opening-lead/opening-lead.component';
import { TableComponent } from '../table/table.component';
import { TeamPlayersComponent } from '../team-players/team-players.component';

@Component({
  selector: 'ffb-pair-roadmap',
  templateUrl: './pair-roadmap.component.html',
  styleUrls: ['./pair-roadmap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    AsyncPipe,
    ContractComponent,
    TableComponent,
    DecimalWithUnitPipe,
    OpeningLeadComponent,
    TeamPlayersComponent,
    AsPipe,
    TuiLetModule,
    TuiButtonModule,
    NgTemplateOutlet,
  ],
})
export class PairRoadmapComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() team?: IRankTeam;
  @Input() pairSessionScores: IPairSessionScore[] = [];
  @Input() boardScoringType?: TScoringType;
  @Input() selectedRowHandler?: (row: IPairSessionScore) => boolean;
  @Input() loading?: boolean;
  @Input() getRowLink?: (
    row: IPairSessionScore,
    index: number,
  ) => [
    commands: (number | string)[] | number | string,
    navigationExtras?: NavigationExtras,
  ];

  TLineup!: IPairSessionScore['lineup'];

  getCellData = (board: IPairSessionScore, column: string) => {
    switch (column) {
      case 'score': {
        if (board.contract?.toUpperCase() === 'PASS') {
          return '';
        }

        return board.nsScore || `-${board.ewScore}`;
      }
      case 'nsScore': {
        if (board.contract?.toUpperCase() === 'PASS') {
          return '';
        }

        return board.nsScore;
      }
      case 'ewScore': {
        if (board.contract?.toUpperCase() === 'PASS') {
          return '';
        }

        return board.ewScore;
      }
      case 'note': {
        const pairOrientation = getPairOrientationForSessionScore(
          this.team,
          board,
        );
        return board.nsScore !== 'Bye' && pairOrientation === 'NS'
          ? board.nsNote
          : board.ewScore !== 'Bye' && pairOrientation === 'EW'
            ? board.ewNote
            : undefined;
      }
      default: {
        return board[column as keyof IPairSessionScore];
      }
    }
  };

  getScoreForLineup(
    lineupId: IPairSessionScore['lineup']['id'],
    rowIndex: number,
  ) {
    const allNotes = [
      this.getCellData(this.pairSessionScores[rowIndex], 'note'),
    ];

    let i = rowIndex;
    while (this.pairSessionScores[i + 1]?.lineup?.id === lineupId) {
      allNotes.push(this.getCellData(this.pairSessionScores[i + 1], 'note'));
      i++;
    }

    const notes = allNotes.filter(
      (note): note is number => typeof note === 'number',
    );

    if (!notes.length) {
      return undefined;
    }

    const sum = notes.reduce((acc, note) => acc + note, 0);

    if (this.boardScoringType === 'percent') {
      return sum / notes.length;
    }

    return sum;
  }

  getOpponentsForBoard(board: IPairSessionScore) {
    if (!board.lineup) {
      return undefined;
    }

    const pairOrientation = getPairOrientationForSessionScore(this.team, board);

    return pairOrientation === 'NS' &&
      board.lineup.westPlayer &&
      board.lineup.eastPlayer
      ? [board.lineup.westPlayer, board.lineup.eastPlayer]
      : pairOrientation === 'EW' &&
          board.lineup.southPlayer &&
          board.lineup.northPlayer
        ? [board.lineup.southPlayer, board.lineup.northPlayer]
        : undefined;
  }
}
