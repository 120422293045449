<ng-container *transloco="let t">
  <tui-tabs>
    @for (tab of tabs; track tab) {
      <a
        tuiTab
        [routerLink]="tab.routerLink"
        [routerLinkActive]
        >
        <span class="tui-text_h6">
          {{ t(tab.labelTranslationKey) }}
        </span>
      </a>
    }
  </tui-tabs>
</ng-container>
