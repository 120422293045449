import { TuiCountryIsoCode as TCountryIsoCode } from '@taiga-ui/i18n';
import { IPerson } from './users.types';

export { TuiCountryIsoCode as TCountryIsoCode } from '@taiga-ui/i18n';

export type TCard = 'A' | 'J' | 'K' | 'Q' | 'T' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type TParsedCard = 10 | Omit<TCard, 'T'>;

export type TSuit = 'C' | 'D' | 'H' | 'S';

export type TCardinalPoint = 'E' | 'N' | 'S' | 'W';

export type TVulnerability = 'B' | 'E' | 'N' | 'O';

export type TOrientation = 'EW' | 'NS';

export type THomeAway = 'A' | 'H';

export type THand = `${string}.${string}.${string}.${string}`;

export type TParsedHand = {
  C: TParsedCard[];
  D: TParsedCard[];
  H: TParsedCard[];
  S: TParsedCard[];
};

export type TDeal = `${TCardinalPoint}:${THand} ${THand} ${THand} ${THand}`;

export type TParsedDeal = {
  E: TParsedHand;
  N: TParsedHand;
  S: TParsedHand;
  W: TParsedHand;
};

export type TLead = `${TSuit}${TCard}`;

export type TParsedLead = {
  card: TCard;
  suit: TSuit;
};

type TSuitOrNoTrumpBid = `${1 | 2 | 3 | 4 | 5 | 6 | 7}${'NT' | TSuit}`;

export type TBid = 'X' | 'XX' | `Pass` | TSuitOrNoTrumpBid;

export type TContract = `${TSuitOrNoTrumpBid}${'' | 'X'}${'' | 'X'}` | `Pass`;

export type TAuction = string;

export type TAuctionNotes = Partial<Record<TBid, string>>;

export type TContractType = 'Game' | 'Partial' | 'Slam';

export type TOptimumScore = Record<
  TCardinalPoint,
  Record<'NT' | TSuit, number>
>;

export type TFrequencies = {
  count: number;
  ewNote: number;
  ewScore: string;
  nsNote: number;
  nsScore: string;
}[];

export type TTeamPlayer =
  | Pick<IPerson, 'ffbId' | 'firstName' | 'id' | 'lastName' | 'migrationId'>
  | null
  | string;

export type TTeam = ILabeledItem & {
  player1: TTeamPlayer;
  player2: TTeamPlayer;
  player3?: TTeamPlayer;
  player4?: TTeamPlayer;
  player5?: TTeamPlayer;
  player6?: TTeamPlayer;
  player7?: TTeamPlayer;
  player8?: TTeamPlayer;
};

export type TLineup = {
  eastPlayer?: TTeamPlayer;
  northPlayer?: TTeamPlayer;
  southPlayer?: TTeamPlayer;
  westPlayer?: TTeamPlayer;
};

export type TScoringType = 'bam' | 'imp' | 'percent' | 'vp';

export type TRoom = 'C' | 'O';

export type TBoard = {
  boardNumber: number;
  dds?: TOptimumScore;
  deal?: TDeal;
  frequencies?: TFrequencies;
  id: number;
};

export type TPagination = {
  current_page: number;
  has_next_page: boolean;
  has_previous_page: boolean;
  per_page: number;
  total_items: number;
  total_pages: number;
};

export type TPaginatedList<T> = {
  items: T[];
  pagination: TPagination;
};

export type TPaginationParams<TSortField = string> = {
  currentPage?: number;
  maxPerPage?: number;
  sortField?: TSortField;
  sortOrder?: 'asc' | 'desc';
};

export type TParams = {
  [p: string]:
    | boolean
    | number
    | readonly (boolean | number | string)[]
    | string;
};

export type TAddress = {
  address: string;
  address2?: string;
  address3?: string;
  cedex?: string;
  city: string;
  country: { code: TCountryIsoCode; label: string };
  id: number;
  zipCode: string;
};

export type TPhone = { id: number; number: string };

export enum EGender {
  'F',
  'M',
}

export interface ILabeledItem {
  id: number;
  label: string;
}

export interface IMigratedLabeledItem extends ILabeledItem {
  migrationId: number;
}

export type IMigratedItem = Pick<IMigratedLabeledItem, 'id' | 'migrationId'>;

export interface IPaymentForm {
  action: string;
  debug: boolean;
  fields: Record<string, string>;
  method: 'DIALOG' | 'GET' | 'POST';
}
