import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  inject,
} from '@angular/core';
import {
  TUI_ANIMATION_OPTIONS,
  TuiModeModule,
  tuiFadeIn,
} from '@taiga-ui/core';
import { LogoComponent } from '../logo/logo.component';
import { OverlayComponent } from '../overlay/overlay.component';

@Component({
  selector: 'ffb-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tuiFadeIn],
  standalone: true,
  imports: [OverlayComponent, LogoComponent, TuiModeModule],
})
export class LoadingOverlayComponent {
  @Input() backgroundColor?: 'primary' | 'transparent' | 'white';
  @Input() logo = true;

  @HostBinding('@tuiFadeIn')
  readonly animation = { value: '', ...inject(TUI_ANIMATION_OPTIONS) } as const;
}
