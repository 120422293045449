import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TuiButtonModule, TuiExpandModule } from '@taiga-ui/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { TypeofPipe } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    PolymorpheusModule,
    TuiButtonModule,
    TuiExpandModule,
    TypeofPipe,
  ],
})
export class SectionComponent {
  @Input() title?: PolymorpheusContent;
  @Input() actions?: PolymorpheusContent;
  @Input() expandable = false;
  @Input() expanded = false;

  toggle() {
    this.expanded = !this.expanded;
  }
}
