<ng-container *transloco="let t">
  <ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
    <ng-template
      #boardNumberTemplate
      let-cellData="cellData"
      let-rowData="rowData"
      let-rowIndex="rowIndex"
      >
      <div class="flex flex-row justify-between">
        {{ cellData }}
        @if (getRowLink?.(rowData, rowIndex)) {
          <button
            tuiIconButton
            size="xs"
            appearance="primary-outline"
            shape="rounded"
            icon="tuiIconPlus"
            class="no-print"
          ></button>
        }
      </div>
    </ng-template>

    <ng-template
      #contractTemplate
      let-cellData="cellData"
      let-rowData="rowData"
      >
      @if (cellData && cellData !== '-') {
        <ffb-contract [contract]="cellData"></ffb-contract>
        @if (cellData.toUpperCase() !== 'PASS') {
          {{ t('components.cardinalPoints.' + rowData.declarer + '.short') }}
          {{ rowData.result }}
        }
      } @else {
        <span class="color-text-03">—</span>
      }
    </ng-template>

    <ng-template #leadTemplate let-cellData="cellData">
      @if (cellData && cellData !== '-') {
        <ffb-opening-lead [openingLead]="cellData"></ffb-opening-lead>
      } @else {
        <span class="color-text-03">—</span>
      }
    </ng-template>

    <ng-template #scoreTemplate let-cellData="cellData">
      @if (!ltSm || cellData !== '') {
        @if (ltSm || cellData[0] !== '-') {
          {{ cellData }}
        }
      } @else {
        <span class="color-text-03">—</span>
      }
    </ng-template>

    <ng-template #noteTemplate let-cellData="cellData">
      @if (cellData) {
        @if (ltSm) {
          @if (cellData > 0) {
            +
            }{{ cellData }}
          }
          @else {
            @if (cellData > 0) {
              {{ cellData }}
            }
          }
        } @else {
          <span class="color-text-03">—</span>
        }
      </ng-template>


      <ffb-table
        name="roadmap-table"
        [loading]="loading"
        [data]="gameScores"
        [getCellData]="getCellData"
      [columns]="
        ltSm
          ? ['boardNumber', 'contract', 'lead', 'nsScore', 'nsNote']
          : [
              'boardNumber',
              'contract',
              'lead',
              'nsScore',
              'ewScore',
              'nsNote',
              'ewNote'
            ]
      "
      [columnHeaders]="[
        ltSm
          ? [
              {
                key: 'boardNumber',
                label: t('components.board.short')
              },
              {
                key: 'contract',
                label: t('components.contract.short')
              },
              {
                key: 'lead',
                label: t('components.openingLead.short')
              },
              {
                key: 'nsScore',
                label:
                  t('components.score') +
                  ' ' +
                  t('components.orientation.NS.short')
              },
              {
                key: 'nsNote',
                label:
                  t('components.IMP') +
                  ' ' +
                  t('components.orientation.NS.short')
              }
            ]
          : [
              {
                key: 'boardNumber',
                label: t('components.board')
              },
              {
                key: 'contract',
                label: t('components.contract.short')
              },
              {
                key: 'lead',
                label: t('components.openingLead.short')
              },
              {
                key: 'nsScore',
                label:
                  t('components.score') +
                  ' ' +
                  t('components.orientation.NS.short')
              },
              {
                key: 'ewScore',
                label:
                  t('components.score') +
                  ' ' +
                  t('components.orientation.EW.short')
              },
              {
                key: 'nsNote',
                label:
                  t('components.IMP') +
                  ' ' +
                  t('components.orientation.NS.short')
              },
              {
                key: 'ewNote',
                label:
                  t('components.IMP') +
                  ' ' +
                  t('components.orientation.EW.short')
              }
            ]
      ]"
      [rowCellTemplates]="{
        boardNumber: boardNumberTemplate,
        contract: contractTemplate,
        lead: leadTemplate,
        nsScore: scoreTemplate,
        ewScore: scoreTemplate,
        nsNote: noteTemplate,
        ewNote: noteTemplate
      }"
      [layout]="{
        lead: { align: 'center' },
        nsScore: { align: 'center' },
        ewScore: { align: 'center' },
        nsNote: { align: 'center' },
        ewNote: { align: 'center' }
      }"
    [selectedRowHandler]="selectedRowHandler"
    [getRowLink]="getRowLink"
    >
  </ffb-table>
</ng-container>
</ng-container>
