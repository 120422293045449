<ng-container *transloco="let t">
  <ffb-row>
    <h4 class="tui-text_h4">
      {{
        t('errors.resourceNotFound', {
          resource: resource
            ? (t('resources.' + resource + '', { count: 1 }) | upperfirst)
            : undefined
        }) | upperfirst
      }}
    </h4>
  </ffb-row>
</ng-container>
