import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { ICommittee } from '../types';

@Injectable({
  providedIn: 'root',
})
export class CommitteesService {
  private http = inject(HttpClient);
  private environmentService = inject(EnvironmentService);

  publicCommitteesUrl =
    this.environmentService.get('apiBaseUrl') + '/public/committees';
  committeesUrl = this.environmentService.get('apiBaseUrl') + '/committees';

  getPublicCommittees() {
    return this.http.get<ICommittee[]>(this.publicCommitteesUrl, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getCommittee(committeeId: ICommittee['id']) {
    return this.http.get<ICommittee>(`${this.committeesUrl}/${committeeId}`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  searchForCommittees(params: { searchValue: string }) {
    return this.http.get<ICommittee[]>(`${this.committeesUrl}/search`, {
      params,
    });
  }
}
