import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiFilterPipeModule, TuiLetModule } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { IBoardScore, TScoringType } from '@lancelot-frontend/api';
import {
  BreakpointObserver,
  DecimalWithUnitPipe,
  UpperFirstPipe,
} from '@lancelot-frontend/core';
import { stringifyPersonInformation } from '../../utils';
import { ContractComponent } from '../contract/contract.component';
import { OpeningLeadComponent } from '../opening-lead/opening-lead.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-pairs-board-scores',
  templateUrl: './pairs-board-scores.component.html',
  styleUrls: ['./pairs-board-scores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    TranslocoDirective,
    TuiLetModule,
    TuiFilterPipeModule,
    ContractComponent,
    OpeningLeadComponent,
    TableComponent,
    DecimalWithUnitPipe,
    UpperFirstPipe,
  ],
})
export class PairsBoardScoresComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() boardScores: IBoardScore[] = [];
  @Input() scoringType?: TScoringType;
  @Input() section?: IBoardScore['lineup']['segment']['game']['section'];
  @Input() selectedRowHandler?: (row: IBoardScore) => boolean;
  @Input() actions?: PolymorpheusContent;
  @Input() loading?: boolean;

  boardScoreIsInCurrentSection(
    boardScore: IBoardScore,
    section: IBoardScore['lineup']['segment']['game']['section'] | undefined,
  ) {
    if (!section) {
      return true;
    }

    return boardScore.lineup.segment.game.section === section;
  }

  getCellData = (score: IBoardScore, column: string) => {
    switch (column) {
      case 'table': {
        return score.lineup.segment.game.tableNumber;
      }
      case 'nsTeam': {
        return `${stringifyPersonInformation(
          score.lineup.southPlayer,
        )} - ${stringifyPersonInformation(score.lineup.northPlayer)}`;
      }
      case 'ewTeam': {
        return `${stringifyPersonInformation(
          score.lineup.eastPlayer,
        )} - ${stringifyPersonInformation(score.lineup.westPlayer)}`;
      }
      case 'nsScore': {
        if (score.contract.toUpperCase() === 'PASS') {
          return '';
        }

        return score.nsScore;
      }
      case 'ewScore': {
        if (score.contract.toUpperCase() === 'PASS') {
          return '';
        }

        return score.ewScore;
      }
      default: {
        return score[column as keyof IBoardScore];
      }
    }
  };
}
