import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TFrequencies, TScoringType } from '@lancelot-frontend/api';
import { DecimalWithUnitPipe } from '@lancelot-frontend/core';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-frequencies',
  templateUrl: './frequencies.component.html',
  styleUrls: ['./frequencies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TableComponent, DecimalWithUnitPipe],
})
export class FrequenciesComponent {
  @Input() frequencies?: TFrequencies;
  @Input() scoringType?: TScoringType;
  @Input() selectedRowHandler?: (row: TFrequencies[number]) => boolean;
}
