import { TAddress } from './common.types';
import { ISeason } from './competitions.types';
import { IClub } from './organizations.types';
import { IPerson } from './users.types';

export interface ISchool {
  address: TAddress;
  code: string;
  id: number;
  label: string;
  type: 'college' | 'elementary' | 'high' | 'university';
}

export interface ISchoolGroup {
  club?: Pick<
    IClub,
    'ffbCode' | 'id' | 'label' | 'migrationId' | 'type'
  > | null;
  id: number;
  label: string;
  level: 'educationalTool' | 'littleBridge' | 'schoolBridge';
  persons: (Pick<
    IPerson,
    | 'birthdate'
    | 'email'
    | 'ffbId'
    | 'firstName'
    | 'id'
    | 'lastName'
    | 'migrationId'
    | 'realEmail'
  > & { hasAccount: boolean })[];
  school: Pick<ISchool, 'id' | 'label' | 'type'>;
  schoolStudents: ISchoolStudent[];
  season: ISeason;
  sublevel?: 'cadet' | 'scholar1' | 'scholar2';
}

export type IPersonSchoolGroup = Pick<
  ISchoolGroup,
  'id' | 'label' | 'level' | 'school' | 'season' | 'sublevel'
> & { personsCount: number; schoolStudentsCount: number };

export type ITeacherClub = Pick<
  IClub,
  'ffbCode' | 'id' | 'label' | 'migrationId' | 'type'
>;

export const elementaryGrades: ISchoolStudent['grade'][] = [
  'GS',
  'CP',
  'CE1',
  'CE2',
  'CM1',
  'CM2',
  'ULIS',
];
export const collegeGrades: ISchoolStudent['grade'][] = [
  '6',
  '5',
  '4',
  '3',
  'ULIS',
];
export const highGrades: ISchoolStudent['grade'][] = ['2', '1', 'T', 'ULIS'];
export const universityGrades: ISchoolStudent['grade'][] = [
  'L1',
  'L2',
  'L3',
  'M1',
  'M2',
];
export const grades = [
  ...elementaryGrades,
  ...collegeGrades,
  ...highGrades,
  ...universityGrades,
];

export interface ISchoolStudent {
  grade:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | 'CE1'
    | 'CE2'
    | 'CM1'
    | 'CM2'
    | 'CP'
    | 'GS'
    | 'L1'
    | 'L2'
    | 'L3'
    | 'M1'
    | 'M2'
    | 'T'
    | 'ULIS';
  id: number;
  person: {
    birthdate: string;
    email?: string;
    ffbId?: number;
    firstName: string;
    hasAccount?: boolean;
    id: number;
    lastName: string;
    realEmail?: string;
  };
}

export type IPersonSchoolStudent = Pick<ISchoolStudent, 'grade' | 'id'> & {
  schoolGroup: IPersonSchoolGroup;
};
