<ng-container *transloco="let t">
  <ng-container *tuiLet="breakpointObserver.ltLg$ | async as ltLg">
    <ng-container *tuiLet="breakpointObserver.ltMd$ | async as ltMd">
      <ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
        <ng-template
          #contractTemplate
          let-cellData="cellData"
          let-rowData="rowData"
          >
          @if (cellData && cellData !== '-') {
            <ffb-contract [contract]="cellData"></ffb-contract>
          }
          @if (ltMd && cellData && cellData.toUpperCase() !== 'PASS') {
            {{
            t('components.cardinalPoints.' + rowData.declarer + '.short')
            }}
            {{ rowData.result }}
          }
        </ng-template>

        <ng-template #declarerTemplate let-cellData="cellData">
          @if (cellData && cellData !== '-') {
            {{ t('components.cardinalPoints.' + cellData + '.short') }}
          }
        </ng-template>

        <ng-template #leadTemplate let-cellData="cellData">
          @if (cellData && cellData !== '-') {
            <ffb-opening-lead [openingLead]="cellData"></ffb-opening-lead>
          } @else {
            <ng-container *ngTemplateOutlet="placeholder"></ng-container>
          }
        </ng-template>

        <ng-template #scoreTemplate let-cellData="cellData">
          @if (cellData !== '') {
            {{ cellData }}
          } @else {
            <ng-container *ngTemplateOutlet="placeholder"></ng-container>
          }
        </ng-template>

        <ng-template #noteTemplate let-cellData="cellData">
          @if (cellData !== undefined) {
            {{
            cellData
            | decimalWithUnit
            : {}
            : (scoringType === 'percent'
            ? '%'
            : scoringType === 'bam'
            ? t('components.BAM')
            : scoringType === 'imp'
            ? t('components.IMP')
            : '')
            }}
          } @else {
            <ng-container *ngTemplateOutlet="placeholder"></ng-container>
          }
        </ng-template>

        <ng-template #placeholder>
          <span class="color-text-03">—</span>
        </ng-template>

        <ng-template #rowHeaderTemplate let-rowData="rowData">
          <div class="bg-base-03 p1">
            <div *tuiLet="getCellData(rowData, 'table') as tableNumber" class="full-width flex flex-row gap1 bold">
              @if (tableNumber) {
                <div>
                  {{ t('components.table') | upperfirst }}
                  {{ tableNumber }}
                </div>
              }
              <div class="flex-0 right-align">
                {{ getCellData(rowData, 'nsTeam') }}
              </div>
              <div class="center">vs</div>
              <div class="flex-0">
                {{ getCellData(rowData, 'ewTeam') }}
              </div>
              @if (tableNumber) {
                <div class="hidden">
                  {{ t('components.table') | upperfirst }}
                  {{ tableNumber }}
                </div>
              }
            </div>
          </div>
        </ng-template>

        <ffb-table
          name="teams-board-scores-table"
          [loading]="loading"
          [data]="boardScores | tuiFilter: boardScoreIsInCurrentSection:section"
          [getCellData]="getCellData"
          [columns]="
            ltSm
              ? ['contract', 'lead', 'nsNote', 'ewNote']
              : ltMd
              ? ['contract', 'lead', 'nsScore', 'ewScore', 'nsNote', 'ewNote']
              : ltLg
              ? [
                  'contract',
                  'declarer',
                  'lead',
                  'result',
                  'nsScore',
                  'ewScore',
                  'nsNote',
                  'ewNote'
                ]
              : [
                  'table',
                  'nsTeam',
                  'ewTeam',
                  'contract',
                  'declarer',
                  'lead',
                  'result',
                  'nsScore',
                  'ewScore',
                  'nsNote',
                  'ewNote'
                ]
          "
          [columnHeaders]="
            ltSm
              ? [
                  [
                    { key: 'contract', label: t('components.contract.short') },
                    { key: 'lead', label: t('components.openingLead.short') },
                    {
                      key: 'nsNote',
                      label: t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewNote',
                      label: t('components.orientation.EW.short')
                    }
                  ]
                ]
              : ltMd
              ? [
                  [
                    { key: 'contract', label: t('components.contract.short') },
                    { key: 'lead', label: t('components.openingLead.short') },
                    {
                      key: 'nsScore',
                      label: t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewScore',
                      label: t('components.orientation.EW.short')
                    },
                    {
                      key: 'nsNote',
                      label: t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewNote',
                      label: t('components.orientation.EW.short')
                    }
                  ]
                ]
              : ltLg
              ? [
                  [
                    { key: 'contract', label: t('components.contract.short') },
                    { key: 'declarer', label: t('components.declarer.short') },
                    { key: 'lead', label: t('components.openingLead.short') },
                    { key: 'result', label: t('components.result.short') },
                    {
                      key: 'nsScore',
                      label: t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewScore',
                      label: t('components.orientation.EW.short')
                    },
                    {
                      key: 'nsNote',
                      label: t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewNote',
                      label: t('components.orientation.EW.short')
                    }
                  ]
                ]
              : [
                  [
                    {
                      key: 'table',
                      label: t('components.table')
                    },
                    {
                      key: 'nsTeam',
                      label: t('components.orientation.NS.long')
                    },
                    {
                      key: 'ewTeam',
                      label: t('components.orientation.EW.long')
                    },
                    { key: 'contract', label: t('components.contract.short') },
                    { key: 'declarer', label: t('components.declarer.short') },
                    { key: 'lead', label: t('components.openingLead.short') },
                    { key: 'result', label: t('components.result.short') },
                    {
                      key: 'nsScore',
                      label:
                        t('components.score') +
                        ' ' +
                        t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewScore',
                      label:
                        t('components.score') +
                        ' ' +
                        t('components.orientation.EW.short')
                    },
                    {
                      key: 'nsNote',
                      label:
                        t('components.note') +
                        ' ' +
                        t('components.orientation.NS.short')
                    },
                    {
                      key: 'ewNote',
                      label:
                        t('components.note') +
                        ' ' +
                        t('components.orientation.EW.short')
                    }
                  ]
                ]
          "
          [rowCellTemplates]="{
            contract: contractTemplate,
            declarer: declarerTemplate,
            lead: leadTemplate,
            nsScore: scoreTemplate,
            ewScore: scoreTemplate,
            nsNote: noteTemplate,
            ewNote: noteTemplate
          }"
  [rowHeaderTemplate]="ltLg ? rowHeaderTemplate : undefined"
          [layout]="{
            contract: { align: 'center' },
            declarer: { align: 'center' },
            lead: { align: 'center' },
            result: { align: 'center' },
            nsScore: { align: 'right' },
            ewScore: { align: 'right' },
            nsNote: { align: 'right' },
            ewNote: { align: 'right' }
          }"
  [selectedRowHandler]="selectedRowHandler"
  [actions]="actions"
  >
</ffb-table>
</ng-container>
</ng-container>
</ng-container>
</ng-container>
