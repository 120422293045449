import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { TuiLineClampModule } from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'ffb-line-clamp',
  templateUrl: './line-clamp.component.html',
  styleUrls: ['./line-clamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TuiLineClampModule, PolymorpheusModule],
})
export class LineClampComponent {
  @Input() lineHeight = 20;
  @Input() linesLimit = 1;
  @Input() content: PolymorpheusContent = '';

  isPlatformServer = isPlatformServer(inject(PLATFORM_ID));
}
