import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TCardinalPoint } from '@lancelot-frontend/api';

@Component({
  selector: 'ffb-declarer',
  templateUrl: './declarer.component.html',
  styleUrls: ['./declarer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective],
})
export class DeclarerComponent {
  @Input() declarer!: TCardinalPoint;
}
