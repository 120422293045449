const landscape = {
  background: [
    {
      style: {
        height: '33%',
        top: 0,
        left: 0,
        transform: 'translate(50%, -60%)',
      },
      class: 'bg-green-light',
    },
    {
      style: {
        height: '33%',
        bottom: 0,
        left: 0,
        transform: 'translate(-40%, 60%)',
      },
      class: 'bg-orange',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        right: 0,
        transform: 'translate(25%, 33%)',
      },
      class: 'bg-lavender',
    },
    {
      style: {
        height: '15%',
        top: 0,
        right: 0,
        transform: 'translate(40%, 75%)',
      },
      class: 'bg-yellow',
    },
  ],
  foreground: [],
};

const portrait = {
  background: [
    {
      style: {
        width: '50%',
        bottom: 0,
        left: 0,
        transform: 'translate(-50%, 80%)',
      },
      class: 'bg-orange',
    },
    {
      style: {
        width: '50%',
        top: 0,
        right: 0,
        transform: 'translate(50%, -66%)',
      },
      class: 'bg-green-light',
    },
  ],
  foreground: [],
};

export default {
  landscape,
  portrait,
};
