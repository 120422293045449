import { b as e } from "./chunk-LSZKOVGW.mjs";
import { j as o, k as a } from "./chunk-GTEBSQTL.mjs";
var O = {
    title: "Yoruba (Nigeria)",
    code: "yo_NG",
    country: "NG",
    language: "yo",
    endonym: "Yoruba (Naijiria)",
    dir: "ltr",
    script: "Latn"
  },
  i = O;
var l = ["Aanuoluwapo", "Abebi", "Abeni", "Abosede", "Adebukola", "Adenike", "Adepeju", "Adesewa", "Adesua", "Adetoke", "Adetoun", "Adunni", "Ajoke", "Amoke", "Amope", "Arike", "Arinola", "Asake", "Atinuke", "Awero", "Ayinke", "Ayoka", "Bolatito", "Boluwatife", "Bunmi", "Doyinsola", "Eniola", "Ewatomi", "Fadekemi", "Faderera", "Fehintola", "Fibikemi", "Fikayomi", "Folashade", "Ibironke", "Iretioluwa", "Iyabode", "Iyadunni", "Kikelomo", "Modupe", "Mofifoluwa", "Mojisola", "Mojisoluwa", "Moradeke", "Morayo", "Morenike", "Morolake", "Mosinmileoluwa", "Mosunmola", "Motunrayo", "Moyosore", "Ninioluwa", "Olajumoke", "Olasunmbo", "Ololade", "Olufunke", "Olufunmilayo", "Oluwakemi", "Omobolanle", "Omodunni", "Omolabake", "Omolara", "Omosalewa", "Omotara", "Omotola", "Omotoun", "Omowumi", "Oreofe", "Oyenike", "Oyindasola", "Radeke", "Ronke", "Segilola", "Similoluwa", "Simisola", "Sowande", "Subomi", "Titilayo", "Tolulope", "Toluwanimi", "Wuraola", "Yejide", "Yetunde", "Yewande"];
var n = ["Abimbola", "Abiola", "Ade", "Adebankole", "Adebola", "Adedaramola", "Adedoyin", "Adeola", "Alaba", "Ara", "Ayomide", "Ayomikun", "Ayotude", "Busayo", "Damilola", "Damola", "Darasimi", "Ebunoluwa", "FolaFoluwa", "Ibukunoluwa", "Idowu", "Ifeoluwa", "Imoleoluwa", "Iremide", "Itunuoluwa", "Iyanuoluwa", "Iyinoluwa", "Kehinde", "Mobolaji", "Odunayo", "Okiki", "Ola", "Oladara", "Olamide", "Olaoluwa", "Olatunbosun", "Olayinka", "Olufeyisayo", "Olusola", "Olutomiwa", "Oluwasemilore", "Oluwaseun", "Oluwaseyi", "Oluwatimilehin", "Oluwatobiloba", "Oluwatofunmi", "Oluwatosin", "Oluwatoyosi", "Oluwatumise", "Omolola", "Omotolani", "Opemipo", "Opeoluwa", "Opeyemi", "Oyindamola", "Pelumi", "Shola", "Taiwo", "Tejumola", "Temitope", "Toluwalope"];
var u = ["Adebisi", "Adegbite", "Adegoke", "Adekunle", "Adelakun", "Adeleke", "Adelusi", "Ademiluyi", "Aderibigbe", "Aderogba", "Adesiyan", "Adeyemo", "Adisa", "Afolabi", "Afolayan", "Afonja", "Ajao", "Ajayi", "Ajewole", "Akinrinola", "Alabi", "Aloba", "Awodiran", "Awolowo", "Ayandokun", "Ayoola", "Babtunde", "Bakare", "Balogun", "Bamidele", "Bamiloye", "Edun", "Fadipe", "Fagunwa", "Fajimi", "Falabi", "Faleti", "Faloye", "Fasasi", "Ibikunle", "Ilori", "Ilupeju", "Iyanda", "Jaiyeola", "Kolade", "Kosoko", "Koya", "Makinde", "Makinwa", "Morawo", "Ninalowo", "Odetola", "Odunsi", "Ogindan", "Oginni", "Ogulana", "Ogunbamigbe", "Ogunbiyi", "Ogunbo", "Ogunde", "Ogunwobi", "Ogunyeye", "Ojo", "Ojua", "Olabode", "Oladipupo", "Olaiya", "Olasupo", "Olowokeere", "Oloyede", "Olubode", "Olugbayila", "Olujimi", "Olukotun", "Olukunga", "Olusanya", "Oluwagbemi", "Omidina", "Omojola", "Omotoso", "Oparinde", "Oshin", "Osuntokun", "Owokoniran", "Owolabi", "Owoyemi", "Oyadiran", "Oyaifo", "Oyeniyi", "Oyetoro", "Oyeyemi", "Oyinlola", "Paimo", "Salako", "Salami", "Shekoni", "Sobowale", "Soyinka"];
var d = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var m = ["Abayomi", "Abiodun", "Abiona", "Adebiyi", "Adebowale", "Adedayo", "Adedeji", "Adekitan", "Adekola", "Adekunle", "Adeleke", "Adeniyi", "Adeolu", "Adeoti", "Aderopo", "Adeshina", "Adesoji", "Adetayo", "Adeyi", "Adigun", "Afolarin", "Ajala", "Ajani", "Akanmu", "Akinkunmi", "Akinlabi", "Akinwale", "Alade", "Alamu", "Anjolaoluwa", "Ayinde", "Ayodeji", "Ayodele", "Babasola", "Babatunji", "Babawale", "Damife", "Demilade", "Durodola", "Ekundayo", "Esupofo", "Folu", "Gbadebo", "Gbolahan", "Gbowoade", "Ibidapo", "Ige", "Ikeoluwa", "Inioluwa", "Iseoluwa", "Ishola", "Juwon", "Keji", "Kolawole", "Korede", "Leke", "Lere", "Niyilolawa", "Oba", "ObaniJesu", "Ogooluwa", "Oke", "Oladare", "Oladimeji", "Olakunle", "Olanrewaju", "Olansile", "Olumorotimi", "Oluwafemi", "Oluwagbemiga", "Oluwamumibori", "Oluwamuyiwa", "Oluwasanmi", "Oluwasegun", "Oluwole", "Omobobola", "Omotayo", "Osunleke", "Seye", "Shekoni", "Sijuade", "Tade", "Temidayo", "Toki", "Tokunbo", "Tomori"];
var A = {
    female_first_name: l,
    first_name: n,
    last_name: u,
    last_name_pattern: d,
    male_first_name: m
  },
  t = A;
var y = {
    metadata: i,
    person: t
  },
  r = y;
var N = new o({
  locale: [r, e, a]
});
export { r as a, N as b };