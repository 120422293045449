import { NgStyle, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { SafePipe } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-calameo-embed',
  templateUrl: './calameo-embed.component.html',
  styleUrls: ['./calameo-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, SafePipe],
})
export class CalameoEmbedComponent implements OnInit {
  @Input({ required: true }) publicationId!: string;
  @Input() aspectRatio?: number;

  iframeSrc!: string;
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  ngOnInit() {
    if (this.isBrowser) {
      this.iframeSrc = `//v.calameo.com/?bkcode=${this.publicationId}&mode=viewer&skinurl=${window.location.origin}/assets/calameo/skin.xml`;
    }
  }
}
