import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiPortalModule } from '@taiga-ui/cdk';
import { TuiModeModule } from '@taiga-ui/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { ContentAdornmentDirective } from '../content-adornment/content-adornment.directive';
import { ErrorComponent } from '../error/error.component';
import { LogoComponent } from '../logo/logo.component';
import { OverlayComponent } from '../overlay/overlay.component';

@Component({
  selector: 'ffb-error-overlay',
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    PolymorpheusModule,
    TuiPortalModule,
    TuiModeModule,
    ContentAdornmentDirective,
    OverlayComponent,
    ErrorComponent,
    LogoComponent,
  ],
})
export class ErrorOverlayComponent {
  @Input() action?: 'reload' | 'retry' | null = 'reload';
  @Input() error?: PolymorpheusContent;
}
