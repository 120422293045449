import { AsyncPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiExpandModule,
  TuiHintModule,
  TuiModeModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { IPerson } from '@lancelot-frontend/api';
import { PersonInformationPipe } from '../../pipes/personInformation.pipe';
import { stringifyPersonInformation } from '../../utils';

@Component({
  selector: 'ffb-team-players',
  templateUrl: './team-players.component.html',
  styleUrls: ['./team-players.component.scss'],
  standalone: true,
  imports: [
    TuiLetModule,
    NgStyle,
    TuiButtonModule,
    TuiHintModule,
    TuiSvgModule,
    TuiModeModule,
    TuiExpandModule,
    AsyncPipe,
    PersonInformationPipe,
    NgTemplateOutlet,
  ],
})
export class TeamPlayersComponent {
  @Input() players: (IPerson | string)[] = [];
  @Input() teamLabel?: string;
  @Input() winner?: boolean;
  @Input() personInformationOptions: Parameters<
    typeof stringifyPersonInformation
  >[1] = { lastName: true, firstName: true };
  @Input() inline = true;
  @Input() maxNumberOfPlayersDisplayed?: number;
  @Input() asTeamLabel?: boolean;
  @Input() hint?: boolean;
  @Input() expand?: boolean;
  @Input() iconLeft = false;
  @Input() justifyEnd = false;

  expanded = false;

  toggle(e: Event) {
    this.preventDefault(e);
    this.expanded = !this.expanded;
  }

  shrink(e: Event) {
    this.preventDefault(e);
    this.expanded = false;
  }

  preventDefault(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
}
