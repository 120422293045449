import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MODE_PROVIDER,
  TUI_MODE,
  TuiBrightness,
  TuiSvgModule,
} from '@taiga-ui/core';

@Component({
  selector: 'ffb-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MODE_PROVIDER],
  standalone: true,
  imports: [TuiSvgModule, NgClass],
})
export class LogoComponent {
  private mode: TuiBrightness | null = null;

  @Input() blink = false;

  constructor() {
    inject(TUI_MODE)
      .pipe(takeUntilDestroyed())
      .subscribe((mode) => {
        this.mode = mode;
      });
  }

  @HostBinding('attr.data-mode')
  get computedMode(): TuiBrightness | null {
    return this.mode;
  }
}
