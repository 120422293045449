import { IPerson } from '@lancelot-frontend/api';

export const stringifyPersonInformation = (
  person: Partial<IPerson> | null | string | undefined,
  options: Partial<{
    club: boolean;
    ffbId: boolean;
    firstName: 'short' | boolean;
    ic: boolean;
    iv: boolean;
    lastName: boolean;
  }> = {
    ffbId: true,
    lastName: true,
    firstName: true,
    iv: false,
    ic: true,
    club: true,
  },
): string => {
  if (!person) {
    return '—';
  }

  if (typeof person === 'string') {
    return person;
  }

  const { ffbId, lastName, firstName, iv, ic, club } = options;

  return `${ffbId && person.ffbId ? `${person.ffbId} - ` : ''}${
    lastName && person.lastName ? person.lastName.toUpperCase() : ''
  } ${
    firstName && person.firstName
      ? firstName === 'short'
        ? person.firstName.slice(0, 1) + '.'
        : person.firstName
      : ''
  }${iv && person.ranking?.iv ? ` - ${person.ranking?.iv}` : ''}${
    ic && person.previousRanking?.ic
      ? ` - ${person.previousRanking?.ic?.id}`
      : ''
  }${club && person.club?.label ? ` - ${person.club?.label}` : ''}`;
};
