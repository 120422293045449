import {
  HttpClient,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IBoardLibraryBoard,
  IBoardLibraryCatalog,
  IBoardLibraryTheme,
  TPaginatedList,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class BoardLibraryService {
  private http = inject(HttpClient);

  boardLibraryUrl =
    inject(EnvironmentService).get('apiBaseUrl') + '/boardLibrary';

  uploadCatalog({
    file,
    name,
    operatorId,
    level,
  }: {
    file: Blob;
    level?: IBoardLibraryBoard['level'];
    name: string;
    operatorId: string;
  }) {
    const formData = new FormData();

    formData.append('pbnfile', file);
    formData.append('label', name);
    formData.append('operatorId', operatorId);
    if (level !== undefined && level !== null) {
      formData.append('level', level.toString());
    }

    return this.http.post<IBoardLibraryCatalog>(
      `${this.boardLibraryUrl}/catalogs/import`,
      formData,
    );
  }

  getCatalog(catalogId: string) {
    return this.http.get<IBoardLibraryCatalog>(
      `${this.boardLibraryUrl}/catalogs/${catalogId}`,
    );
  }

  getCatalogs(params: HttpParamsOptions['fromObject'] = {}) {
    const httpParams = new HttpParams({
      fromObject: params,
    });
    return this.http.get<TPaginatedList<IBoardLibraryCatalog>>(
      `${this.boardLibraryUrl}/catalogs/`,
      { params: httpParams },
    );
  }

  deleteCatalog(catalogId: number) {
    return this.http.delete(`${this.boardLibraryUrl}/catalogs/${catalogId}`);
  }

  getThemes() {
    return this.http.get<IBoardLibraryTheme[]>(
      `${this.boardLibraryUrl}/themes/`,
    );
  }

  updateBoardThemes(boardId: number, data: number[]) {
    return this.http.post<IBoardLibraryBoard>(
      `${this.boardLibraryUrl}/boards/${boardId}/updateThemes`,
      data,
    );
  }

  qualifyBoard(boardId: number, data: Partial<IBoardLibraryBoard>) {
    return this.http.post<IBoardLibraryBoard>(
      `${this.boardLibraryUrl}/boards/${boardId}`,
      data,
    );
  }

  deleteBoard(boardId: number) {
    return this.http.delete(`${this.boardLibraryUrl}/boards/${boardId}`);
  }
}
