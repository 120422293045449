import { a as r } from "./chunk-VJTQBXK2.mjs";
import { b as o } from "./chunk-LSZKOVGW.mjs";
import { j as t, k as e } from "./chunk-GTEBSQTL.mjs";
var i = ["621 ### ###", "661 ### ###", "671 ### ###", "691 ### ###", "+352 621 ### ###", "+352 661 ### ###", "+352 671 ### ###", "+352 691 ### ###"];
var y = {
    formats: i
  },
  n = y;
var m = ["lu"];
var L = {
    domain_suffix: m
  },
  a = L;
var f = ["Diekirch", "Differdange", "Dudelange", "Echternach", "Esch-sur-Alzette", "Ettelbruck", "Grevenmacher", "Luxembourg", "Remich", "Rumelange", "Vianden", "Wiltz"];
var p = ["{{location.city_name}}"];
var l = ["####"];
var u = ["Capellen", "Clervaux", "Diekirch", "Echternach", "Esch-sur-Alzette", "Grevenmacher", "Luxembourg", "Mersch", "Redange", "Remich", "Vianden", "Wiltz"];
var g = {
    city_name: f,
    city_pattern: p,
    postcode: l,
    state: u
  },
  c = g;
var b = {
    title: "French (Luxembourg)",
    code: "fr_LU",
    country: "LU",
    language: "fr",
    endonym: "Fran\xE7ais (Luxembourg)",
    dir: "ltr",
    script: "Latn"
  },
  d = b;
var s = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var P = {
    last_name_pattern: s
  },
  x = P;
var h = ["######", "########", "+352 ######", "+352 ########"];
var k = {
    formats: h
  },
  D = k;
var E = {
    cell_phone: n,
    internet: a,
    location: c,
    metadata: d,
    person: x,
    phone_number: D
  },
  _ = E;
var mt = new t({
  locale: [_, r, o, e]
});
export { _ as a, mt as b };