<ng-container *transloco="let t">
  <div class="flex flex-column justify-center items-center center">
    @if (!error) {
      <tui-error [error]="t('errors.unknown')"></tui-error>
    }@else {
      <ng-container *polymorpheusOutlet="error as text">
        {{ text }}
      </ng-container>
    }
    <ng-content></ng-content>
    @if (action) {
      <button
        tuiButton
        type="button"
        tuiMode="onLight"
        appearance="outline"
        size="m"
        class="mt2"
        (click)="reload()"
      >
        {{ t('actions.' + action) }}
      </button>
    }
  </div>
</ng-container>
