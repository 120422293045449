@if (isBrowser) {
  <div
    class="relative"
    [class.full-height]="aspectRatio === undefined"
    [ngStyle]="
      aspectRatio !== undefined ? { 'aspect-ratio': aspectRatio } : null
    "
    >
    <div class="absolute full-width full-height">
      <iframe
        width="100%"
        height="100%"
        allow="fullscreen"
        [src]="iframeSrc | safe: 'resourceUrl'"
      ></iframe>
    </div>
  </div>
}
