import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { TCardinalPoint, TContract, TDeal, TVulnerability } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private http = inject(HttpClient);

  toolsUrl = inject(EnvironmentService).get('apiBaseUrl') + '/tools';

  extractBoardData(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<
      {
        boardNumber?: number;
        contract?: TContract;
        deal: TDeal;
        dealer?: TCardinalPoint;
        declarer?: TCardinalPoint;
        vulnerability?: TVulnerability;
      }[]
    >(`${this.toolsUrl}/extractBoardData`, formData);
  }
}
