<ng-container *transloco="let t">
  <span class="nowrap">
    @if (isPass) {
      <span class="color-text-03">{{ t('components.PASS') }}</span>
    } @else {
      <span>{{ level }}</span>
      @if (contract.includes('C')) {
        <ffb-suit-icon
          suit="C"
          [fittext]="true"
        ></ffb-suit-icon>
      }
      @else if (contract.includes('D')) {
        <ffb-suit-icon
          suit="D"
          [fittext]="true"
        ></ffb-suit-icon>
      }
      @else if (contract.includes('H')) {
        <ffb-suit-icon
          suit="H"
          [fittext]="true"
        ></ffb-suit-icon>
      }
      @else if (contract.includes('S')) {
        <ffb-suit-icon
          suit="S"
          [fittext]="true"
        ></ffb-suit-icon>
      }
      @else if (contract.includes('NT')) {
        <ffb-suit-icon
          suit="NT"
          [fittext]="true"
        ></ffb-suit-icon>
      }
      @if (doubled) {
        <span> X</span>
      }
      @if (redoubled) {
        <span>X</span>
      }
      <span> {{ result || computedResult }}</span>
    }
  </span>
</ng-container>
