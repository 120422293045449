import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { TuiModeModule } from '@taiga-ui/core';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { ContentAdornmentDirective } from '../content-adornment/content-adornment.directive';
import { RowComponent } from '../row/row.component';

@Component({
  selector: 'ffb-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContentAdornmentDirective,
    TuiModeModule,
    RowComponent,
    NgClass,
    AsyncPipe,
  ],
})
export class PageHeaderComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() title?: string;
  @Input() subtitle?: string;
}
