import { a as n } from "./chunk-VJTQBXK2.mjs";
import { a as t, b as r } from "./chunk-LSZKOVGW.mjs";
import { j as e, k as i } from "./chunk-GTEBSQTL.mjs";
var m = ["sn", "com", "net", "org"];
var u = ["gmail.com", "yahoo.com", "hotmail.com"];
var T = {
    domain_suffix: m,
    free_email: u
  },
  d = T;
var l = ["####", "###", "##", "#"];
var s = ["Bakel", "Bambey", "Bargny", "Bignona", "Dagana", "Dahra", "Dakar", "Diourbel", "Fatick", "Gandiaye", "Gossas", "Goudomp", "Guinguin\xE9o", "Gu\xE9diawaye", "Joal-Fadiouth", "Kaffrine", "Kanel", "Kaolack", "Kayar", "Khombole", "Kolda", "Koungheul", "K\xE9b\xE9mer", "K\xE9dougou", "Lingu\xE8re", "Louga", "Matam", "Mback\xE9", "Mboro", "Mbour", "NDofan", "Ndioum", "Ngaa\xFF Meckhe", "Ngu\xE9khokh", "Nioro du Rip", "Ourossogui", "Pikine", "Pout", "Richard-Toll", "Rufisque", "Saint-Louis", "Sokone", "S\xE9bikhotane", "S\xE9dhiou", "Tambacounda", "Thiadiaye", "Thi\xE8s", "Tivaouane", "Touba", "V\xE9lingara", "Ziguinchor"];
var f = ["{{location.city_name}}"];
var p = ["S\xE9n\xE9gal"];
var h = ["#####"];
var y = ["Apt. ###", "# \xE9tage"];
var b = ["Dakar", "Diourbel", "Fatick", "Kaffrine", "Kaolack", "Kolda", "K\xE9dougou", "Louga", "Matam", "Saint-Louis", "S\xE9dhiou", "Tambacounda", "Thi\xE8s", "Ziguinchor"];
var c = {
  normal: "{{location.buildingNumber}} {{location.street}}",
  full: "{{location.buildingNumber}} {{location.street}} {{location.secondaryAddress}}"
};
var S = ["{{location.street_prefix}} {{location.street_suffix}}"];
var D = ["All\xE9e", "Voie", "Rue", "Avenue", "Boulevard", "Quai", "Place", "\xC9changeur", "R\xE9sidence", "Immeuble"];
var g = ["de l'alternance", "de l'\xE9mergence", "Abdou Aziz Sy Dabakh", "Amadou Assane Ndoye", "Birago Diop", "Blaise Diagne", "Cheikh Amadou Bamba", "Cheikh Anta Diop", "Cheikh Seydi El Hadji Malick Sy", "Dial Diop", "Faidherbe", "Galandou Diouf", "Houphou\xEBt Boigny", "Lamine Gueye", "Lat Dior", "L\xE9opold Sedar Senghor", "Neslon Mandela", "Saint Michel", "St Joseph", "S\xE9rigne Fallou Mback\xE9", "Victor Hugo", "Wagane Diouf", "William Ponty", "Yacine Boubou", "de Bordeaux", "de Contournement Nord", "de Nguinth", "des Diambars", "des Jasmins", "du Baol", "du Tirailleur"];
var F = {
    building_number: l,
    city_name: s,
    city_pattern: f,
    default_country: p,
    postcode: h,
    secondary_address: y,
    state: b,
    street_address: c,
    street_pattern: S,
    street_prefix: D,
    street_suffix: g
  },
  M = F;
var L = {
    title: "French (Senegal)",
    code: "fr_SN",
    country: "SN",
    language: "fr",
    endonym: "Fran\xE7ais (S\xE9n\xE9gal)",
    dir: "ltr",
    script: "Latn"
  },
  A = L;
var a = ["Abibatou", "Aby", "Adama", "Alima", "Ami", "Amie", "Amina", "Aminata", "Amy", "Anna", "Arame", "Asta", "Astou", "Atta", "Awa", "A\xEFcha", "A\xEFda", "A\xEFssa", "A\xEFssata", "A\xEFssatou", "Banna", "Bineta", "Binta", "Bintou", "Coumba", "Dado", "Diarra", "Dieynaba", "Dior", "Elisabeth", "El\xE8ne", "Fanta", "Fatim", "Fatima", "Fatimata", "Fatma", "Fatou", "Fatoumata", "Fily", "Haby", "Hawa", "Jeanne", "Josephine", "Kadiatou", "Khadidiatou", "Khady", "Khoudia", "Kin\xE9", "Lala", "Madeleine", "Maguette", "Mariama", "Marie", "Mari\xE8me", "Mary", "Mar\xE8me", "Ma\xEF", "Ma\xEFmouna", "N\xE9n\xE9", "Oumou", "Paulette", "Penda", "Rama", "Ramata", "Ramatoulaye", "Rokhaya", "Rose", "Safiatou", "Safi\xE9tou", "Salimata", "Salimatou", "Saly", "Seynabou", "Soda", "Sokhna", "Sophie", "Th\xE9r\xE8se", "Yacine", "Yaya"];
var o = ["Abdou", "Abdoul", "Abdoulaye", "Abou", "Aboubacar", "Adama", "Ahmed", "Alassane", "Aliou", "Alioune", "Alpha", "Aly", "Amadou", "Amady", "Amath", "Amdy", "Ameth", "Antoine", "Arona", "Baba", "Babacar", "Bakary", "Birane", "Bouba", "Boubacar", "Boubou", "Cheikh", "Cheikhou", "Chekhouna", "Cherif", "Dame", "Daouda", "Demba", "Djibril", "Hamidou", "Ibou", "Ibra", "Ibrahima", "Idrissa", "Idy", "Insa", "Isma\xEFla", "Issa", "Jean", "Joseph", "Kalidou", "K\xE9ba", "Khadim", "Khalifa", "Lamine", "Lassana", "Mactar", "Mady", "Makhtar", "Malick", "Mama", "Mamadou", "Mamady", "Mamour", "Mansour", "Massamba", "Matar", "Mbaye", "M\xE9doune", "Me\xEFssa", "Michel", "Moctar", "Modou", "Mohamadou", "Mohamed", "Mohammed", "Mouhamadou", "Mouhamed", "Mouhameth", "Mouhammadou", "Moussa", "Moustapha", "Omar", "Oumar", "Ousmane", "Path\xE9", "Paul", "Pierre", "Sada", "Sadio", "Sa\xE8r", "Sagar", "Salif", "Saliou", "Samba", "Seckou", "S\xE9kou", "Seydou", "Sidy", "Sir\xE9", "Souleymane", "Thierno", "Tidiane", "Waly", "Yoro", "Youssou", "Youssouph", "Youssoupha"];
var k = t(a, o);
var B = ["A\xEFdara", "Amar", "Anne", "Aw", "Ba", "Babou", "Badiane", "Badji", "Bakhoum", "Bald\xE9", "Barry", "Beye", "Biteye", "Bodian", "Boye", "Camara", "Ciss", "Cisse", "Cissokho", "Coly", "Coulibaly", "Dabo", "Dembel\xE9", "D\xE8me", "Dia", "Diaby", "Diack", "Diagne", "Diakhat\xE9", "Diallo", "Diamanka", "Diao", "Diarra", "Diatta", "Diattara", "Diaw", "Diawara", "Di\xE9dhiou", "Di\xE9m\xE9", "Di\xE8ne", "Dieng", "Dieye", "Diome", "Dione", "Diongue", "Diop", "Diouf", "Dioum", "Djitt\xE9", "Dram\xE9", "Fall", "Faty", "Faye", "Fofana", "Gadiaga", "Gassama", "Gaye", "Gning", "Gningue", "Gomis", "Goudiaby", "Gueye", "Guiss\xE9", "Hane", "Ka", "Kamara", "Kand\xE9", "Kandji", "Kane", "Kant\xE9", "Kass\xE9", "K\xE9b\xE9", "Ke\xEFta", "Khouma", "Konat\xE9", "Kont\xE9", "Lam", "Leye", "Lo", "Loum", "Ly", "Man\xE9", "Manga", "Mangane", "Mar", "Mback\xE9", "Mballo", "Mbaye", "Mb\xE8ngue", "Mbodj", "Mboup", "Mbow", "Mendy", "Ndao", "Ndaw", "Ndiaye", "Ndione", "Ndir", "Ndong", "Ndour", "Ndoye", "Ngom", "Ngu\xE8r", "Niane", "Niang", "Niass", "Niasse", "Pouye", "Sabaly", "Sadio", "Sagna", "Sakho", "Sall", "Samb", "Samba", "Sambe", "Sambou", "San\xE9", "Sarr", "Seck", "S\xE9gnane", "S\xE8ne", "Senghor", "Seydi", "Seye", "Sidib\xE9", "Sonko", "Souare", "Soumar\xE9", "Sow", "Sy", "Sylla", "Tall", "Tamba", "Thiam", "Thiao", "Thiaw", "Thiongane", "Thioub", "Thioune", "Tine", "Top", "Tour\xE9", "Traor\xE9", "Wade", "Wane", "Willane", "Yade"];
var K = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var N = [{
  value: "{{person.firstName}} {{person.lastName}}",
  weight: 1
}];
var C = {
    female_first_name: a,
    first_name: k,
    last_name: B,
    last_name_pattern: K,
    male_first_name: o,
    name: N
  },
  x = C;
var G = {
    internet: d,
    location: M,
    metadata: A,
    person: x
  },
  _ = G;
var wa = new e({
  locale: [_, n, r, i]
});
export { _ as a, wa as b };