import { AsyncPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import {
  TuiTabComponent,
  TuiTabsComponent,
  TuiTabsModule,
} from '@taiga-ui/kit';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged, filter } from 'rxjs/operators';
import { ShowHideDirective } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-page-submenu',
  templateUrl: './page-submenu.component.html',
  styleUrls: ['./page-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiTabsModule,
    ResizeObserverModule,
    NgTemplateOutlet,
    TuiButtonModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiSvgModule,
    TuiDataListModule,
    NgStyle,
    ShowHideDirective,
    AsyncPipe,
  ],
})
export class PageSubmenuComponent implements OnChanges, AfterViewInit {
  @Input() activeItemIndex = NaN;
  protected readonly activeItem$ = new BehaviorSubject<
    TuiTabComponent | undefined
  >(undefined);
  protected readonly activeItemText$ = new BehaviorSubject<string>('');
  open = false;
  width?: number;
  overflowing = false;
  lastElementChildOffsetLeft = 0;
  displayArrowLeft = false;
  displayArrowRight = false;

  @ViewChild(TuiTabsComponent) desktopTabs?: TuiTabsComponent;
  @ContentChildren(TuiTabComponent) tabs?: QueryList<TuiTabComponent>;

  constructor() {
    inject(Router)
      .events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        delay(0),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.setTab(this.tabs?.find((tab) => tab.isActive));
      });

    this.activeItem$
      .pipe(
        distinctUntilChanged(),
        filter((activeItem) => !!activeItem),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.open = false;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setTab(
        !isNaN(this.activeItemIndex)
          ? this.tabs?.get(this.activeItemIndex)
          : this.tabs?.find((tab) => tab.isActive),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.activeItemIndex &&
      !changes.activeItemIndex.firstChange &&
      !isNaN(changes.activeItemIndex?.currentValue)
    ) {
      this.setTab(this.tabs?.get(this.activeItemIndex));
    }
  }

  setTab(tab: TuiTabComponent | undefined) {
    if (tab) {
      this.activeItem$.next(tab);
      // @ts-expect-error el is private but still accessible
      const text = tab.el.nativeElement.innerText?.trim();
      if (text) {
        this.activeItemText$.next(text);
      }
    }
  }

  onResize(entries: ResizeObserverEntry[]) {
    if (entries[0]) {
      const contentRect = entries[0].contentRect;
      this.width = contentRect.width;

      const lastElementChild = entries[0].target
        .lastElementChild as HTMLElement;

      if (lastElementChild) {
        this.lastElementChildOffsetLeft = lastElementChild.offsetLeft;
        this.overflowing =
          lastElementChild.offsetLeft + lastElementChild.offsetWidth >
          contentRect.width + 10; // allow a 10px overflow before showing buttons
        this.displayArrowRight = this.overflowing;
      }
    }
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    this.displayArrowLeft = this.overflowing && target.scrollLeft > 10;
    this.displayArrowRight =
      this.overflowing &&
      target.scrollWidth - target.scrollLeft - 10 > target.offsetWidth;
  }

  scrollLeft() {
    if (this.desktopTabs) {
      // @ts-expect-error el is private but still accessible
      this.desktopTabs.el.nativeElement.scrollLeft = 0;
    }
  }

  scrollRight() {
    if (this.desktopTabs) {
      // @ts-expect-error el is private but still accessible
      this.desktopTabs.el.nativeElement.scrollLeft =
        this.lastElementChildOffsetLeft;
    }
  }
}
