<div
  [class]="
    disposition === 'background'
      ? 'absolute top-0 right-0 bottom-0 left-0'
      : 'relative full-width full-height'
  "
  [class.screen-width]="disposition === 'fullScreenWidth'"
  (click)="stopEventPropagation($event)"
  >
  <video
    #videoPlayer
    playsinline
    disablePictureInPicture
    controlsList="nodownload"
    [autoplay]="autoplay"
    [muted]="muted"
    [loop]="loop"
    [poster]="poster"
    (play)="onPlay()"
    (pause)="onPause()"
    (ended)="onEnded()"
    (timeupdate)="onTimeUpdate()"
    (mousemove)="showControls()"
    (click)="controls.playPause && playPause()"
    class="full-width full-height"
    >
    <source src="{{ videoUrl }}" type="{{ mime }}" />
  </video>
  @if (visibleControls) {
    <div
      class="controls absolute top-0 left-0 full-width full-height flex flex-column justify-center items-center bg-blue-dark-overlay"
      (click)="controls.playPause && playPause()"
      >
      @if (controls.playPause) {
        <button
          tuiIconButton
          appearance="icon"
          size="xl"
          >
          <tui-svg
            class="xl"
            src="/assets/icon-set/{{ playing ? 'pause' : 'play' }}.svg"
          ></tui-svg>
        </button>
      }
    </div>
  }
  @if (visibleControls) {
    <div
      class="controls absolute bottom-0 left-0 full-width flex flex-row justify-between items-center"
      >
      @if (controls.playPause === true) {
        <button
          tuiIconButton
          appearance="icon"
          size="xl"
          (click)="playPause()"
          >
          <tui-svg
            src="/assets/icon-set/{{ playing ? 'pause' : 'play' }}.svg"
          ></tui-svg>
        </button>
      }
      <div class="flex-auto p1">
        <div
          (click)="controls.progress && updateTime($event)"
          class="cursor-pointer border-radius-round bg-white-overlay"
          >
          <div
            #progressBar
            class="progress-bar pt1 border-radius-round bg-white"
            [ngStyle]="{ width: progress + '%' }"
          ></div>
        </div>
      </div>
      @if (controls.mute) {
        <button
          tuiIconButton
          appearance="icon"
          (click)="toggleMuted()"
          >
          <tui-svg
            src="/assets/icon-set/{{ muted ? 'volumeOff' : 'volumeUp' }}.svg"
          ></tui-svg>
        </button>
      }
      @if (controls.fullscreen) {
        <button
          tuiIconButton
          appearance="icon"
          (click)="toggleFullscreen()"
          >
          <tui-svg
        src="/assets/icon-set/{{
          fullscreen ? 'fullscreenExit' : 'fullscreen'
        }}.svg"
          ></tui-svg>
        </button>
      }
    </div>
  }
</div>
