<div
  [class]="
    disposition === 'background'
      ? 'absolute top-0 right-0 bottom-0 left-0'
      : 'relative full-width full-height'
  "
  [class.screen-width]="disposition === 'fullScreenWidth'"
  (click)="stopEventPropagation($event)"
  >
  @if (slides) {
    <swiper
      #swiper
      [config]="swiperConfig"
      [class.screen-width]="disposition === 'fullScreenWidth'"
      (realIndexChange)="onRealIndexChange($event[0])"
      (mousemove)="showControls()"
      >
      @for (slide of slides; track $index) {
        <ng-template swiperSlide>
          <ffb-image
            [url]="slide.url"
            [alternativeText]="slide.alternativeText"
            [width]="slide.width"
            [height]="slide.height"
            [aspectRatio]="aspectRatio"
            [disposition]="disposition"
            imageClass="full-width"
            backgroundClass="full-width full-height"
            (click)="autoplay && controls.playPause && playPause()"
            >
          </ffb-image>
        </ng-template>
      }
      @if (visiblePlayPause || this.controls.navigation) {
        <span
          slot="container-end"
          >
          @if (controls.navigation) {
            <button
              tuiIconButton
              icon="tuiIconArrowLeft"
              appearance="secondary"
              shape="rounded"
              class="button-prev"
              [disabled]="!loop && activeIndex === 0"
              (click)="slidePrev()"
            ></button>
          }
          @if (controls.playPause && visiblePlayPause) {
            <button
              tuiIconButton
              appearance="icon"
              size="xl"
              class="button-play-pause"
              (click)="playPause()"
              >
              <tui-svg
                class="xl"
                src="/assets/icon-set/{{ running ? 'pause' : 'play' }}.svg"
              ></tui-svg>
            </button>
          }
          @if (controls.navigation) {
            <button
              tuiIconButton
              icon="tuiIconArrowRight"
              appearance="secondary"
              shape="rounded"
              class="button-next"
              [disabled]="!loop && activeIndex === slides.length"
              (click)="slideNext()"
            ></button>
          }
        </span>
      }
    </swiper>
  }
  @if (
    disposition !== 'background' &&
    slides?.[activeIndex]?.caption &&
    slides?.[activeIndex]?.name !== slides?.[activeIndex]?.caption
    ) {
    <ffb-caption
      >
      {{ slides?.[activeIndex]?.caption }}
    </ffb-caption>
  }
</div>
