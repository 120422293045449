import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { SwiperEvents } from 'swiper/types';
import { CaptionComponent } from '../caption/caption.component';
import { ImageComponent } from '../image/image.component';

type TSlide = {
  alternativeText?: string;
  caption?: string;
  height?: string;
  name?: string;
  url: string;
  width?: string;
};

type TControls = {
  navigation: boolean;
  playPause: boolean;
};

@Component({
  selector: 'ffb-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SwiperModule,
    ImageComponent,
    TuiButtonModule,
    TuiSvgModule,
    CaptionComponent,
  ],
})
export class CarouselComponent {
  private changeDetector = inject(ChangeDetectorRef);

  private _controls: TControls = {
    playPause: false,
    navigation: false,
  };

  @Input() slides?: TSlide[];
  @Input() aspectRatio?: number;
  @Input() autoplay = true;
  @Input() loop = true;
  @Input() disposition: 'background' | 'free' | 'fullScreenWidth' = 'free';
  @Input() set controls(controls: Partial<TControls> | boolean) {
    if (typeof controls === 'boolean') {
      this._controls = Object.keys(this._controls).reduce((acc, key) => {
        acc[key as keyof TControls] = controls;
        return acc;
      }, {} as TControls);
    } else {
      this._controls = { ...this._controls, ...controls };
    }
  }
  get controls(): TControls {
    return this._controls;
  }

  private readonly _visibleControlsSubject$ = new BehaviorSubject(
    !this.autoplay,
  );

  get visiblePlayPause() {
    return (
      this._controls.playPause &&
      this.autoplay &&
      (this._visibleControlsSubject$.value || !this.running)
    );
  }

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  get swiperConfig() {
    return {
      autoplay: this.autoplay && {
        delay: '6000',
      },
      loop: this.loop,
    };
  }

  activeIndex = 0;

  constructor() {
    this._visibleControlsSubject$
      .pipe(
        filter((visible) => visible),
        debounceTime(4000),
      )
      .subscribe(() => {
        this.hideControls();
      });
  }

  get running() {
    return this.swiper?.swiperRef.autoplay.running;
  }

  onRealIndexChange: SwiperEvents['realIndexChange'] = ({ realIndex }) => {
    if (this.slides) {
      this.activeIndex = realIndex % this.slides.length;
      this.changeDetector.detectChanges();
    }
  };

  showControls() {
    this._visibleControlsSubject$.next(true);
  }

  hideControls() {
    this._visibleControlsSubject$.next(false);
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext();
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev();
  }

  play() {
    this.swiper?.swiperRef.autoplay.start();
  }

  pause() {
    this.swiper?.swiperRef.autoplay.stop();
  }

  playPause() {
    if (this.running) {
      this.pause();
    } else {
      this.play();
    }
  }

  stopEventPropagation(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }
}
