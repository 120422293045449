import {
  ILabeledItem,
  IMigratedItem,
  IMigratedLabeledItem,
  TAddress,
  TBoard,
  TCardinalPoint,
  TContract,
  THomeAway,
  TLead,
  TLineup,
  TOrientation,
  TRoom,
  TScoringType,
  TTeam,
  TTeamPlayer,
} from './common.types';
import {
  IClub,
  IFestival,
  IGroupment,
  IOrganization,
} from './organizations.types';
import { IPerson } from './users.types';

/**
 * Model definition for season
 */
export interface ISeason extends ILabeledItem {
  endDate: string;
  startDate: string;
}

/**
 * Model definition for competition
 */
export interface ICompetition extends IMigratedLabeledItem {
  ageCategory: 'junior' | 'open' | 'senior';
  ageMax?: null | number;
  ageMin?: null | number;
  festival?: IFestival;
  format: 'four' | 'ind' | 'pair' | 'tri';
  maxPlayer?: null | number;
  mixed: boolean;
  type: TCompetitionType;
  women: boolean;
}

export interface IDivision extends IMigratedLabeledItem {
  icPlayerMax: null | number;
  icPlayerMin: null | number;
  icTeamMax: null | number;
  icTeamMin: null | number;
  ivPlayerMax: null | number;
  ivPlayerMin: null | number;
  ivPlayerMinToAllowTeam: null | number;
  ivTeamMax: null | number;
  ivTeamMin: null | number;
}

// eslint-disable-next-line
export interface ISimultaneous extends IMigratedLabeledItem {}

export type ISimultaneousGroup =
  | {
      ffbCode: IClub['ffbCode'];
      label: string;
    }
  | {
      groupId: ICompetitionGroup['id'];
      groupMigrationId: ICompetitionGroup['migrationId'];
      label: string;
    };

export interface ICompetitionDivision {
  competition: ICompetition;
  division: IMigratedLabeledItem;
  id: number;
  sponsor?: string;
}

export type TCompetitionType = 'club' | 'committee' | 'federal' | 'regional';

export type TSearchCompetitionType = 'clubSimultaneous' | TCompetitionType;

type TLocation =
  | 'bbo' // BBO
  | 'fb' // FunBridge
  | 'ftf' // Face to face
  | 'ib' // IntoBridge
  | 'lb' // LoveBridge
  | 'ot' // OnlineTutorial
  | 'rb' // RealBridge
  | 'zoom'; // Zoom

export type ICompetitionStade<TContext = 'registration' | 'results'> =
  IMigratedItem & {
    competitionDivision: ICompetitionDivision;
    groupment?: IGroupment;
    nextStade?: ICompetitionStade<TContext>['id'];
    organization?: Pick<
      IOrganization,
      'ffbCode' | 'id' | 'label' | 'labelArticle' | 'migrationId' | 'type'
    >;
    phases: ICompetitionPhase<TContext>[];
    season: ILabeledItem;
  } & (TContext extends 'registration'
      ?
          | {
              registrationAllowed: false;
              registrationDeadline: string;
              registrationError: string;
            }
          | {
              registrationAllowed: true;
              registrationDeadline: string;
            }
      : TContext extends 'results'
        ? {
            hasResult: boolean;
          }
        : // eslint-disable-next-line @typescript-eslint/ban-types
          {});

export type TCompetitionStadeWithResults = Pick<
  ICompetitionStade<'results'>,
  'groupment' | 'hasResult' | 'id' | 'nextStade'
> & {
  organization?: Pick<
    IOrganization,
    'ffbCode' | 'id' | 'label' | 'labelArticle' | 'migrationId' | 'type'
  >;
  phases: (Pick<
    ICompetitionPhase<'results'>,
    'id' | 'label' | 'nextPhase' | 'simultaneous'
  > & {
    groups: Pick<
      ICompetitionGroup<'results'>,
      'id' | 'label' | 'migrationId' | 'resultCount' | 'resultTotal'
    >[];
  })[];
};

// Searching for competitions of type 'federal' with 'results' context returns a list of competitionDivisions
export type TFederalCompetitionsWithResultsListItem = Pick<
  ICompetitionDivision,
  'id' | 'sponsor'
> & {
  competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
  division: Pick<IDivision, 'id' | 'label'>;
};

export type TFederalCompetitionWithResults = Pick<
  ICompetitionDivision,
  'id' | 'sponsor'
> & {
  competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
  division: Pick<IDivision, 'id' | 'label'>;
  stades: TCompetitionStadeWithResults[];
};

// Searching for competitions of type 'committee' with 'results' context returns a list of stades
export type TCommitteeCompetitionsWithResultsListItem =
  TCompetitionStadeWithResults & {
    competitionDivision: Pick<ICompetitionDivision, 'id' | 'sponsor'> & {
      competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
      division: Pick<IDivision, 'id' | 'label'>;
    };
  };

// Searching for competitions of type 'club' with 'results' context returns a list of stades
export type TClubCompetitionsWithResultsListItem =
  TCompetitionStadeWithResults & {
    competitionDivision: Pick<ICompetitionDivision, 'id' | 'sponsor'> & {
      competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
      division: Pick<IDivision, 'id' | 'label'>;
    };
  };

// Searching for competitions of type 'regional' with 'results' context returns a list of festivals
export type TFestivalsWithResultsListItem = Pick<IFestival, 'id' | 'label'> & {
  competitionDivisions: (Pick<ICompetitionDivision, 'id' | 'sponsor'> & {
    competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
    division: Pick<IDivision, 'id' | 'label'>;
    stades: (Pick<ICompetitionStade<'results'>, 'hasResult' | 'id'> & {
      organization?: Pick<
        IOrganization,
        'ffbCode' | 'id' | 'label' | 'labelArticle' | 'migrationId' | 'type'
      >;
    })[];
  })[];
};

export type TFestivalWithResults = Pick<IFestival, 'id' | 'label'> & {
  competitionDivisions: (Pick<ICompetitionDivision, 'id' | 'sponsor'> & {
    competition: Pick<ICompetition, 'format' | 'id' | 'label'>;
    division: Pick<IDivision, 'id' | 'label'>;
    stades: TCompetitionStadeWithResults[];
  })[];
};

export type TCompetitionsWithResultsListItem<TType = TSearchCompetitionType> =
  TType extends 'federal'
    ? TFederalCompetitionsWithResultsListItem
    : TType extends 'committee'
      ? TCommitteeCompetitionsWithResultsListItem
      : TType extends 'club'
        ? TClubCompetitionsWithResultsListItem
        : TType extends 'clubSimultaneous'
          ? ISimultaneous
          : TType extends 'regional'
            ? TFestivalsWithResultsListItem
            : never;

export interface ICompetitionPhase<TContext = 'registration' | 'results'>
  extends IMigratedLabeledItem {
  groups: ICompetitionGroup<TContext>[];
  locationLabel?: string;
  nextPhase?: ICompetitionPhase<TContext>['id'];
  prices: Record<IPerson['id'], number> & { total: number };
  simultaneous?: boolean;
}

export interface ICompetitionPhaseWithStade<
  TContext = 'registration' | 'results',
> extends ICompetitionPhase<TContext> {
  stade: Pick<
    ICompetitionStade<TContext>,
    'competitionDivision' | 'id' | 'migrationId' | 'organization'
  >;
}

export type ICompetitionGroup<TContext = 'registration' | 'results'> =
  IMigratedLabeledItem & {
    butler?: boolean;
    displayLineup?: boolean;
    location: TLocation;
    locationAddress?: TAddress;
    locationLabel?: string;
    qualifiedNumber?: number;
    setupType?: TSetupType;
    teamAverageIc: number;
    teamAverageIv: number;
    teamCount: number;
  } & (TContext extends 'results'
      ? {
          resultCount: number;
          resultTotal: number;
        }
      : // eslint-disable-next-line @typescript-eslint/ban-types
        {});

export type ICompetitionGroupWithPhase<TContext = 'registration' | 'results'> =
  ICompetitionGroup<TContext> & {
    phase: Pick<
      ICompetitionPhase<TContext>,
      'id' | 'label' | 'migrationId' | 'simultaneous'
    > & {
      stade: {
        competitionDivision: ICompetitionDivision;
        groupment?: IGroupment;
        id: number;
        organization?: Pick<
          IOrganization,
          'ffbCode' | 'id' | 'label' | 'labelArticle' | 'migrationId' | 'type'
        >;
      } & (TContext extends 'results'
        ? {
            hasResult: boolean;
          }
        : // eslint-disable-next-line @typescript-eslint/ban-types
          {});
    };
  };

export interface ICompetitionGroupSession<
  TContext = 'registration' | 'results',
> {
  date?: string;
  id: number;
  location?: TLocation;
  locationLabel?: string;
  moment?: string;
  number: number;
  occasion?: ILabeledItem;
  session: ICompetitionSession<TContext>;
  timezone?: string;
  top?: 'integral' | 'simple';
}

export type ICompetitionGroupSessionWithGroup<
  TContext = 'registration' | 'results',
> = ICompetitionGroupSession<TContext> & {
  group: ICompetitionGroupWithPhase<TContext>;
};

export type ICompetitionRound = { id: number; number: number };

export type TRankingType = 'general' | 'howell' | 'line';

export type TSetupType =
  | 'cdf'
  | 'ko'
  | 'mirror'
  | 'mul'
  | 'pam'
  | 'pas'
  | 'pou'
  | 'sim'
  | 'sue'
  | 'tpp';

export const pairishSetupTypes: TSetupType[] = ['pam', 'mirror'];

export type ICompetitionSession<TContext = 'registration' | 'results'> =
  IMigratedLabeledItem & {
    boardScoringType?: TScoringType;
    format?: ICompetition['format'];
    rankingScoringType?: TScoringType;
    rankingType?: TRankingType;
    rounds: ICompetitionRound[];
    setupType?: TSetupType;
    simultaneous?: IMigratedLabeledItem;
  } & {
    [K in TContext extends 'results' ? 'hasResult' : never]: boolean;
  };

export type ISimultaneousSessionListItem = Pick<
  ICompetitionSession,
  'id' | 'label'
> &
  Pick<ICompetitionGroupSession, 'date' | 'moment'>;

export type ICompetitionSessionWithGroupSessions<
  TContext = 'registration' | 'results',
> = Omit<ICompetitionSession<TContext>, 'round'> & {
  groupSessions: Omit<ICompetitionGroupSessionWithGroup<TContext>, 'session'>[];
  simultaneous?: ISimultaneous;
};

export interface ICompetitionStadeRegisteredTeam extends ILabeledItem {
  ic: number;
  iv: number;
  players: (Pick<IPerson, 'firstName' | 'gender' | 'id' | 'lastName'> & {
    position: number;
  })[];
}

// EASI
export interface ICompetitionRegistrationListItem {
  competition_division_id: string;
  competition_family_id: string;
  competition_id: string;
  competition_label: string;
  competition_team_id: string;
  competition_team_name: string;
  competition_type_code: string;
  festival_label: string;
  ffb_payment_enabled: boolean | null;
  online_payment_enabled: boolean | null;
  payment_acknowledgement_id: null | string;
  payment_by_organization: boolean | null;
  payment_team_debt_id: null | string;
  season_id: string;
  subscription_group_id: string;
  subscription_group_name: string;
}

// EASI
export interface ICompetitionRegistration {
  club_id: number;
  club_name: string;
  competition_id: number;
  competition_label: string;
  course: {
    competition_id: number;
    group_id: number;
    group_name: string;
    has_results: boolean;
    organization_id: number;
    parent_group_id: null | number;
    payment_acknowledgement_id: number;
    payment_team_debt_id: number;
    phase_id: number;
    phase_name: string;
    phase_position: number;
    team_iv: null | number;
  }[];
  festival_label: string;
  id: number;
  name: null | string;
  players: {
    club_id: number;
    club_name: string;
    email: string;
    firstname: string;
    gender: string;
    has_valid_email: boolean;
    id: number;
    is_alive: boolean;
    is_licensee: boolean;
    iv: number;
    lastname: string;
    license_number: string;
    nb_pe: number;
    nb_pp: number;
    position: number;
    prospect_id: null | number;
  }[];
  team_iv: number;
  team_pe: number;
  team_pp: number;
}

// EASI
export interface ICompetitionRegistrationSuccess {
  has_warnings: boolean;
  pricingData: {
    amount: number;
    brut_amount: number;
    criterias: {
      competitionRegistrationFee: string;
      ffbPaymentEnabled: '0' | '1' | null;
      groupRegistrationFee: string;
      groupSessionFee: string;
      is_delayed_authorized: '0' | '1' | null;
      nb_sections: string;
      onlinePaymentEnabled: '0' | '1' | null;
      organization_type: string;
      paymentByOrganization: '0' | '1' | null;
      phase_position: string;
    };
    ffbPaymentEnabled: boolean;
    is_delayed_authorized: boolean;
    isQualifying: boolean;
    isSubscription: boolean;
    onlinePaymentEnabled: boolean;
    payment_id: number;
    paymentByOrganization: boolean;
    rate: number;
  };
  team_id: number;
  team_iv: number;
  team_name: null | string;
  warnings: unknown[];
}

export interface IRank {
  id: number;
  orientation: TCardinalPoint | THomeAway | TOrientation;
  rank: number;
  section: string;
  sessionScore: number;
  simultaneousId?: number; // IClub['ffbCode'] | ICompetitionGroup['migrationId'];
  tableNumber?: number;
  team: TTeam;
  theoreticalRank?: number;
  totalScore: number;
}

export interface IRoundButlerRank {
  averageScore: number;
  players: TTeamPlayer[];
  rank: number;
  roundAverageScore: number;
  segments: Record<string, number>;
  team: ILabeledItem;
}

export type IRankTeam = IRank['team'] & {
  rankings: {
    id: number;
    orientation: TOrientation;
    rank: number;
    round: ICompetitionRound & { session: Omit<ICompetitionSession, 'rounds'> };
    section: string;
    sessionScore: number;
    tableNumber?: number;
    totalScore: number;
  }[];
};

export interface IPairSessionScore {
  board?: TBoard;
  boardNumber: TBoard['boardNumber'];
  contract?: '-' | TContract;
  declarer?: '-' | TCardinalPoint;
  ewNote: number;
  ewScore: string;
  id: number;
  lead: '-' | TLead;
  lineup: TLineup & { id: number };
  nsNote: number;
  nsScore: string;
  result: string;
  tricks?: number;
}

export interface IGameScore {
  board?: TBoard;
  boardNumber: number;
  contract: TContract;
  declarer: TCardinalPoint;
  ewNote: number;
  ewScore: string;
  id: number;
  lead: TLead;
  nsNote: number;
  nsScore: string;
  result: string;
  tricks?: number;
}

export interface IBoardScore {
  boardNumber: TBoard['boardNumber'];
  contract: TContract;
  declarer: TCardinalPoint;
  ewNote: number;
  ewScore: string;
  id: number;
  lead: TLead;
  lineup: TLineup & {
    id: number;
    room: TRoom;
    segment: {
      game: {
        awayTeam: TTeam & {
          orientation: THomeAway;
        };
        homeTeam: TTeam & {
          orientation: THomeAway;
        };
        id: number;
        section: string;
        tableNumber: number;
      };
    };
  };
  nsNote: number;
  nsScore: string;
  result: string;
}

export interface ITeamGame {
  awayTeam?: TTeam & {
    orientation: THomeAway;
  };
  homeTeam?: TTeam & {
    orientation: THomeAway;
  };
  id: number;
  impAway: number;
  impHome: number;
  round: ICompetitionRound;
  section: string;
  segments: {
    id: number;
    impAway: number;
    impHome: number;
    lineups: (TLineup & {
      id: number;
      room: TRoom;
      scores: IGameScore[];
    })[];
    number: number;
  }[];
  tableNumber: number;
  vpAway: number;
  vpHome: number;
}

export type ITeamRankings = Record<
  string,
  {
    label: string;
    rank: number;
    totalScore: number;
  }
>;

export type IRoundGame = Omit<ITeamGame, 'round'>;
