import { a as t } from "./chunk-5R5AEDBT.mjs";
import { b as o } from "./chunk-LSZKOVGW.mjs";
import { j as e, k as a } from "./chunk-GTEBSQTL.mjs";
var r = ["Pty Ltd", "and Sons", "Corp", "Group", "Brothers", "Partners"];
var k = {
    suffix: r
  },
  n = k;
var i = ["com.au", "com", "net.au", "net", "org.au", "org"];
var B = {
    domain_suffix: i
  },
  l = B;
var m = ["####", "###", "##"];
var p = ["Bondi", "Burleigh Heads", "Carlton", "Fitzroy", "Fremantle", "Glenelg", "Manly", "Noosa", "Stones Corner", "St Kilda", "Surry Hills", "Yarra Valley"];
var s = ["{{location.city_name}}"];
var f = ["Australia"];
var u = ["0###", "2###", "3###", "4###", "5###", "6###", "7###"];
var d = ["South East Queensland", "Wide Bay Burnett", "Margaret River", "Port Pirie", "Gippsland", "Elizabeth", "Barossa"];
var c = ["New South Wales", "Queensland", "Northern Territory", "South Australia", "Western Australia", "Tasmania", "Australian Capital Territory", "Victoria"];
var y = ["NSW", "QLD", "NT", "SA", "WA", "TAS", "ACT", "VIC"];
var h = ["Ramsay Street", "Bonnie Doon", "Cavill Avenue", "Queen Street"];
var x = ["{{location.street_name}}"];
var A = ["Avenue", "Boulevard", "Circle", "Circuit", "Court", "Crescent", "Crest", "Drive", "Estate Dr", "Grove", "Hill", "Island", "Junction", "Knoll", "Lane", "Loop", "Mall", "Manor", "Meadow", "Mews", "Parade", "Parkway", "Pass", "Place", "Plaza", "Ridge", "Road", "Run", "Square", "Station St", "Street", "Summit", "Terrace", "Track", "Trail", "View Rd", "Way"];
var T = {
    building_number: m,
    city_name: p,
    city_pattern: s,
    default_country: f,
    postcode: u,
    region: d,
    state: c,
    state_abbr: y,
    street_name: h,
    street_pattern: x,
    street_suffix: A
  },
  _ = T;
var E = {
    title: "English (Australia Ocker)",
    code: "en_AU_ocker",
    country: "AU",
    language: "en",
    variant: "ocker",
    endonym: "English (Australia)",
    dir: "ltr",
    script: "Latn"
  },
  S = E;
var C = ["Charlotte", "Ava", "Chloe", "Emily", "Olivia", "Zoe", "Lily", "Sophie", "Amelia", "Sofia", "Ella", "Isabella", "Ruby", "Sienna", "Mia+3", "Grace", "Emma", "Ivy", "Layla", "Abigail", "Isla", "Hannah", "Zara", "Lucy", "Evie", "Annabelle", "Madison", "Alice", "Georgia", "Maya", "Madeline", "Audrey", "Scarlett", "Isabelle", "Chelsea", "Mila", "Holly", "Indiana", "Poppy", "Harper", "Sarah", "Alyssa", "Jasmine", "Imogen", "Hayley", "Pheobe", "Eva", "Evelyn", "Mackenzie", "Ayla", "Oliver", "Jack", "Jackson", "William", "Ethan", "Charlie", "Lucas", "Cooper", "Lachlan", "Noah", "Liam", "Alexander", "Max", "Isaac", "Thomas", "Xavier", "Oscar", "Benjamin", "Aiden", "Mason", "Samuel", "James", "Levi", "Riley", "Harrison", "Ryan", "Henry", "Jacob", "Joshua", "Leo", "Zach", "Harry", "Hunter", "Flynn", "Archie", "Tyler", "Elijah", "Hayden", "Jayden", "Blake", "Archer", "Ashton", "Sebastian", "Zachery", "Lincoln", "Mitchell", "Luca", "Nathan", "Kai", "Connor", "Tom", "Nigel", "Matt", "Sean"];
var g = ["Smith", "Jones", "Williams", "Brown", "Wilson", "Taylor", "Morton", "White", "Martin", "Anderson", "Thompson", "Nguyen", "Thomas", "Walker", "Harris", "Lee", "Ryan", "Robinson", "Kelly", "King", "Rausch", "Ridge", "Connolly", "LeQuesne"];
var L = [{
  value: "{{person.last_name}}",
  weight: 95
}, {
  value: "{{person.last_name}}-{{person.last_name}}",
  weight: 5
}];
var v = ["Bazza", "Bluey", "Davo", "Johno", "Shano", "Shazza"];
var H = {
    first_name: C,
    last_name: g,
    last_name_pattern: L,
    ocker_first_name: v
  },
  M = H;
var b = ["0# #### ####", "+61 # #### ####", "04## ### ###", "+61 4## ### ###"];
var R = {
    formats: b
  },
  D = R;
var I = {
    company: n,
    internet: l,
    location: _,
    metadata: S,
    person: M,
    phone_number: D
  },
  P = I;
var Ne = new e({
  locale: [P, t, o, a]
});
export { P as a, Ne as b };