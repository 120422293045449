import { isPlatformServer } from '@angular/common';
import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  INJECTOR,
  PLATFORM_ID,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SuitIconComponent } from '../components/suit-icon/suit-icon.component';

export const provideComponents = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const injector = inject(INJECTOR);
        const platformId = inject(PLATFORM_ID);
        if (
          !isPlatformServer(platformId) &&
          !customElements.get('suit-icon-element')
        ) {
          const SuitIconElement = createCustomElement(SuitIconComponent, {
            injector,
          });
          customElements.define('suit-icon-element', SuitIconElement);
        }
      },
      multi: true,
    },
    { provide: TRANSLOCO_SCOPE, useValue: 'components', multi: true },
  ]);
};
