import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IClub,
  IPerson,
  IPersonSchoolGroup,
  ISchool,
  ISchoolGroup,
  ISchoolStudent,
  ISeason,
  TCountryIsoCode,
  TPaginatedList,
  TPaginationParams,
} from '../types';

export type TSchoolStudentData = {
  birthdate: ISchoolStudent['person']['birthdate'];
  email: ISchoolStudent['person']['realEmail'];
  ffbId?: ISchoolStudent['person']['ffbId'];
  firstName: ISchoolStudent['person']['firstName'];
  grade: ISchoolStudent['grade'];
  lastName: ISchoolStudent['person']['lastName'];
};

export type TSchoolStudentCheckedData = TSchoolStudentData & {
  error?:
    | {
        violations: {
          propertyPath: keyof TSchoolStudentData;
          title: string;
        }[];
      }
    | string;
  warning?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class EducationService {
  private http = inject(HttpClient);

  apiUrl = inject(EnvironmentService).get('apiBaseUrl');
  schoolsUrl = this.apiUrl + '/schools';

  searchForSchools(
    params: TPaginationParams & {
      searchAddress?: string;
      searchCountryCode?: TCountryIsoCode;
      searchLabel?: string;
    },
  ) {
    return this.http.get<TPaginatedList<ISchool>>(`${this.schoolsUrl}/search`, {
      params,
    });
  }

  addSchoolGroup(
    schoolId: ISchool['id'],
    data: {
      label?: string;
      level: ISchoolGroup['level'];
      season?: 'current' | 'previous' | Pick<ISeason, 'id'>;
      sublevel?: ISchoolGroup['sublevel'] | null;
    },
  ) {
    if (!data.season) {
      data.season = 'current';
    }
    return this.http.post<IPersonSchoolGroup>(
      `${this.schoolsUrl}/${schoolId}/groups`,
      data,
    );
  }

  getSchoolGroup(schoolGroupId: ISchoolGroup['id']) {
    return this.http.get<ISchoolGroup>(
      `${this.schoolsUrl}/groups/${schoolGroupId}`,
    );
  }

  editSchoolGroup(
    schoolGroupId: ISchoolGroup['id'],
    data: {
      label?: string;
      level: ISchoolGroup['level'];
      sublevel?: ISchoolGroup['sublevel'] | null;
    },
  ) {
    return this.http.post<ISchoolGroup>(
      `${this.schoolsUrl}/groups/${schoolGroupId}`,
      data,
    );
  }

  attachSchoolGroupToClub(
    schoolGroupId: ISchoolGroup['id'],
    clubId: IClub['id'],
  ) {
    return this.http.post<void>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/link-club/${clubId}`,
      {},
    );
  }

  deleteSchoolGroup(schoolGroupId: ISchoolGroup['id']) {
    return this.http.delete(`${this.schoolsUrl}/groups/${schoolGroupId}`);
  }

  checkSchoolGroupStudent(
    schoolGroupId: ISchoolGroup['id'],
    data: TSchoolStudentData,
  ) {
    return this.http.post<ISchoolStudent & { warning?: string[] }>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/students/check`,
      data,
    );
  }

  addSchoolGroupStudent(
    schoolGroupId: ISchoolGroup['id'],
    data: TSchoolStudentData,
  ) {
    return this.http.post<ISchoolStudent>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/students`,
      data,
    );
  }

  checkStudentsCSV(schoolGroupId: ISchoolGroup['id'], data: { file: Blob }) {
    const formData = new FormData();
    formData.append('file', data.file);

    return this.http.post<TSchoolStudentCheckedData[]>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/students/checkCSV`,
      formData,
    );
  }

  uploadStudentsCSV(schoolGroupId: ISchoolGroup['id'], data: { file: Blob }) {
    const formData = new FormData();
    formData.append('file', data.file);

    return this.http.post<ISchoolStudent[]>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/students/csv`,
      formData,
    );
  }

  addSchoolGroupCoTeacher(
    schoolGroupId: ISchoolGroup['id'],
    data: { personId: IPerson['id'] },
  ) {
    return this.http.post<ISchoolGroup>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/persons/${data.personId}`,
      {},
    );
  }

  deleteSchoolGroupCoTeacher(
    schoolGroupId: ISchoolGroup['id'],
    data: { personId: IPerson['id'] },
  ) {
    return this.http.delete<ISchoolGroup>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/persons/${data.personId}`,
      {},
    );
  }

  editStudent(
    studentId: ISchoolStudent['id'],
    data: Omit<ISchoolStudent, 'id' | 'person'> & {
      person: Omit<ISchoolStudent['person'], 'id'>;
    },
  ) {
    return this.http.post<ISchoolStudent>(
      `${this.schoolsUrl}/students/${studentId}`,
      data,
    );
  }

  deleteStudent(studentId: ISchoolStudent['id']) {
    return this.http.delete(`${this.schoolsUrl}/students/${studentId}`);
  }

  joinSchoolGroupAsStudent(
    schoolGroupId: ISchoolGroup['id'],
    data: {
      grade: ISchoolStudent['grade'];
    },
  ) {
    return this.http.post<void>(
      `${this.schoolsUrl}/groups/${schoolGroupId}/join`,
      data,
    );
  }

  sendIdea(data: { file?: Blob; message: string; subject: string }) {
    const formData = new FormData();

    formData.append('subject', data.subject);
    formData.append('message', data.message);
    if (data.file) {
      formData.append('file', data.file);
    }

    return this.http.post<void>(`${this.schoolsUrl}/suggestionBox`, formData);
  }
}
