import { Component, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiPrimitiveTextfieldModule,
} from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'ffb-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, TuiButtonModule, TuiPrimitiveTextfieldModule],
})
export class ConfirmationDialogComponent {
  private readonly context = inject<
    TuiDialogContext<
      boolean,
      {
        confirmationButtonLabel?: string;
        confirmationMessage?: string;
        confirmationTitle?: string;
        typeToConfirmMessage?: string;
        typeToConfirmValue?: string;
      }
    >
  >(POLYMORPHEUS_CONTEXT);

  typeToConfirmInputValue = '';

  get confirmationTitle() {
    return this.context.data?.confirmationTitle;
  }

  get confirmationMessage() {
    return this.context.data?.confirmationMessage;
  }

  get confirmationButtonLabel() {
    return this.context.data?.confirmationButtonLabel;
  }

  get typeToConfirmValue() {
    return this.context.data?.typeToConfirmValue;
  }

  get typeToConfirmMessage() {
    return this.context.data?.typeToConfirmMessage;
  }

  cancel() {
    this.context.completeWith(false);
  }

  confirm() {
    this.context.completeWith(true);
  }
}
