import {
  ICompetition,
  ICompetitionDivision,
  ICompetitionGroupSession,
  ICompetitionPhase,
  ICompetitionRound,
  ICompetitionStade,
  IPairSessionScore,
  IPerson,
  IRank,
  IRankTeam,
  IRoundButlerRank,
  ITeamGame,
  TCard,
  TCardinalPoint,
  TDeal,
  TFederalCompetitionWithResults,
  TLead,
  TOrientation,
  TParsedDeal,
  TParsedHand,
  TParsedLead,
  TSuit,
  TTeam,
  TTeamPlayer,
  TVulnerability,
} from '../types';

export const FFBIdRegExp = /^(\d{2,8})$/;
// any string containing an @
export const SearchEmailRegExp = /@/;
export const cardinalPoints: TCardinalPoint[] = ['N', 'E', 'S', 'W'];
export const suits: TSuit[] = ['S', 'H', 'D', 'C'];
export const cardValues: TCard[] = [
  'A',
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  'T',
  'J',
  'Q',
  'K',
];
export const vulnerabilities: TVulnerability[] = [
  'O',
  'N',
  'E',
  'B',
  'N',
  'E',
  'B',
  'O',
  'E',
  'B',
  'O',
  'N',
  'B',
  'O',
  'N',
  'E',
];

export const sortGroupSessions = (
  groupSessions: ICompetitionGroupSession[],
  order: 'asc' | 'des' = 'des',
) => {
  const sortedGroupSessions = groupSessions.sort((a, b) => {
    if (a.number !== b.number) {
      return a.number - b.number;
    } else {
      const aDate = new Date(a.date || 0).valueOf();
      const bDate = new Date(b.date || 0).valueOf();
      if (aDate !== bDate) {
        return aDate - bDate;
      }
    }

    return a.session.label.localeCompare(b.session.label);
  });

  return order === 'des' ? sortedGroupSessions.reverse() : sortedGroupSessions;
};

export const sortRounds = (
  rounds: ICompetitionRound[],
  order: 'asc' | 'des' = 'des',
) => {
  const sortedRounds = rounds.sort((a, b) => {
    return a.number - b.number;
  });

  return order === 'des' ? sortedRounds.reverse() : sortedRounds;
};

export const getPhasesFirstPhase = (phases: ICompetitionPhase[]) =>
  phases.find((phase) => !phase.nextPhase);

export const getCompetitionFirstPhase = (competitionStade: ICompetitionStade) =>
  getPhasesFirstPhase(competitionStade.phases);

export const sortCompetitionPhases = (phases: ICompetitionPhase[]) => {
  const sortedPhases: ICompetitionPhase[] = [];

  const firstPhase = getPhasesFirstPhase(phases);

  if (firstPhase) {
    sortedPhases[0] = firstPhase;
  }

  for (let i = 1; i < phases.length; i++) {
    const nextPhase = phases.find(
      (phase) => phase.nextPhase === sortedPhases[i - 1].id,
    );
    if (nextPhase) {
      sortedPhases[i] = nextPhase;
    }
  }

  return sortedPhases.reverse();
};

export const getStadesFirstStade = (stades: ICompetitionStade[]) =>
  stades.find((stade) => !stade.nextStade);

export function sortCompetitionStades<
  T extends Pick<ICompetitionStade, 'id' | 'nextStade'>,
>(stades: T[]): T[] {
  const sortedStades: T[] = [];

  for (const stade of stades) {
    const previousStadeIndex = sortedStades.findIndex(
      ({ id, nextStade }) =>
        nextStade === stade.nextStade || id === stade.nextStade,
    );
    sortedStades.splice(previousStadeIndex, 0, stade);
  }

  return sortedStades.reverse();
}

export const getCompetitionLabel = (
  data: {
    competition?: Pick<ICompetition, 'label'>;
    division?: Pick<ICompetition, 'label'>;
    sponsor?: ICompetitionDivision['sponsor'];
  },
  options: {
    competition?: boolean;
    division?: boolean;
    sponsor?: boolean;
  } = {},
) => {
  const { competition = true, division = true, sponsor = true } = options;

  const labelParts = [];

  if (competition && data.competition) {
    labelParts.push(data.competition.label);
  }
  if (division && data.division) {
    labelParts.push(data.division.label);
  }
  if (sponsor && data.sponsor) {
    labelParts.push(data.sponsor);
  }

  return labelParts.join(' ');
};

export const getPlayersFromTeam = (team?: TTeam) =>
  team
    ? Object.keys(team).reduce(
        (acc, key) => {
          if (key.startsWith('player')) {
            acc.push(team[key as keyof TTeam] as TTeam['player1']);
          }
          return acc;
        },
        [] as IRank['team']['player1'][],
      )
    : [];

export const getPairOrientationForSessionScore = (
  pair: IRankTeam | undefined,
  sessionScore: IPairSessionScore | undefined,
): TOrientation | undefined => {
  if (!pair || !sessionScore) {
    return undefined;
  }

  const players = getPlayersFromTeam(pair).map((player) =>
    typeof player === 'string' ? player : player?.id,
  );
  const northPlayer =
    typeof sessionScore.lineup.northPlayer === 'string'
      ? sessionScore.lineup.northPlayer
      : sessionScore.lineup.northPlayer?.id;
  const westPlayer =
    typeof sessionScore.lineup.westPlayer === 'string'
      ? sessionScore.lineup.westPlayer
      : sessionScore.lineup.westPlayer?.id;

  if (northPlayer && players.includes(northPlayer)) {
    return 'NS';
  } else if (westPlayer && players.includes(westPlayer)) {
    return 'EW';
  }

  return undefined;
};

export function isPersonInPlayers<T extends TTeamPlayer>(
  players: T[],
  ffbId: IPerson['ffbId'],
) {
  return players.some((player) => {
    if (!player) {
      return false;
    } else if (typeof player === 'string') {
      return player === ffbId?.toString();
    } else if (typeof player === 'object' && 'ffbId' in player) {
      return player.ffbId === ffbId;
    }
    return false;
  });
}

export function findPersonRank<T extends IRank | IRoundButlerRank>(
  ranking: T[],
  ffbId: IPerson['ffbId'],
) {
  return ranking.find((rank) => {
    const players =
      'players' in rank ? rank.players : getPlayersFromTeam(rank.team);

    return isPersonInPlayers(players, ffbId);
  });
}

export function findPersonGame<
  T extends Pick<ITeamGame, 'awayTeam' | 'homeTeam'>,
>(games: T[], ffbId: IPerson['ffbId']) {
  return games.find((game) => {
    const players = getPlayersFromTeam(game.homeTeam).concat(
      getPlayersFromTeam(game.awayTeam),
    );

    return isPersonInPlayers(players, ffbId);
  });
}

export function findPersonTeam<
  T extends Pick<ITeamGame, 'awayTeam' | 'homeTeam'>,
>(game: T, ffbId: IPerson['ffbId']) {
  const homeTeamPlayers = getPlayersFromTeam(game.homeTeam);
  const awayTeamPlayers = getPlayersFromTeam(game.awayTeam);

  return isPersonInPlayers(homeTeamPlayers, ffbId)
    ? game.homeTeam
    : isPersonInPlayers(awayTeamPlayers, ffbId)
      ? game.awayTeam
      : undefined;
}

export const getFederalCompetitionStadesForStage = (
  federalCompetitionDivision: TFederalCompetitionWithResults,
  stadeType:
    | 'committees'
    | 'districts'
    | 'leagueFinals'
    | 'nationalFinal'
    | 'zoneFinals',
) => {
  let stades: TFederalCompetitionWithResults['stades'] = [];
  switch (stadeType) {
    case 'nationalFinal': {
      const nationalFinal = federalCompetitionDivision.stades.find(
        (stade) =>
          stade.organization?.type === 'federation' && stade.phases.length,
      );
      if (nationalFinal) {
        stades.push(nationalFinal);
      }
      break;
    }
    case 'leagueFinals': {
      stades = federalCompetitionDivision.stades.filter(
        (stade) => stade.groupment?.type === 'league' && stade.phases.length,
      );
      break;
    }
    case 'zoneFinals': {
      stades = federalCompetitionDivision.stades.filter(
        (stade) => stade.groupment?.type === 'zone' && stade.phases.length,
      );
      break;
    }
    case 'committees': {
      stades = federalCompetitionDivision.stades.filter(
        (stade) =>
          stade.organization?.type === 'committee' && stade.phases.length,
      );
      break;
    }
    case 'districts': {
      stades = federalCompetitionDivision.stades.filter(
        (stade) => stade.groupment?.type === 'district' && stade.phases.length,
      );
      break;
    }
  }
  return sortCompetitionStades(stades);
};

export const parseLead = (lead: TLead): TParsedLead => ({
  suit: lead[0] as TSuit,
  card: lead.substring(1).replace('T', '10') as TCard,
});

export const parseDeal = (deal: TDeal): TParsedDeal => {
  const dealer = deal[0] as TCardinalPoint;

  return deal
    .split(':')[1]
    .split(' ')
    .reduce((acc, hand, currentIndex) => {
      acc[cardinalPoints[(currentIndex + cardinalPoints.indexOf(dealer)) % 4]] =
        hand.split('.').reduce((acc, suit, currentIndex) => {
          acc[suits[currentIndex]] = Array.from(suit).map((c) => {
            if (c === 'T') {
              return 10;
            }
            if (!isNaN(parseInt(c))) {
              return parseInt(c);
            }
            return c;
          }) as TCard[];
          return acc;
        }, {} as TParsedHand);
      return acc;
    }, {} as TParsedDeal);
};
