<ng-template #items>
  <ng-content></ng-content>
</ng-template>

<!-- Desktop (tabs) -->
<tui-tabs
  *hide.ltMd="true"
  [activeItemIndex]="activeItemIndex"
  class="uppercase-semi-bold"
  (waResizeObserver)="onResize($event)"
  (scroll)="onScroll($event)"
  >
  <ng-container *ngTemplateOutlet="items"></ng-container>
</tui-tabs>

<div *hide.ltMd="true" class="arrows-wrapper">
  <div class="arrows-container">
    @if (displayArrowLeft) {
      <button
        tuiIconButton
        icon="tuiIconArrowLeft"
        shape="rounded"
        size="m"
        class="arrow-left"
        (click)="scrollLeft()"
      ></button>
    }
    @if (displayArrowRight) {
      <button
        tuiIconButton
        icon="tuiIconArrowRight"
        shape="rounded"
        size="m"
        class="arrow-right"
        (click)="scrollRight()"
      ></button>
    }
  </div>
</div>

<!-- Mobile (dropdown menu) -->
<div *show.ltMd="true" class="px2">
  <tui-hosted-dropdown
    tuiDropdownAlign="left"
    [content]="dropdownItems"
    [(open)]="open"
    (waResizeObserver)="onResize($event)"
    >
    <a tuiTab>
      {{ activeItemText$ | async }}
      <tui-svg
        src="tuiIconChevronDown"
        class="icon"
        [class.upside-down]="open"
      ></tui-svg>
    </a>

    <ng-template #dropdownItems>
      <tui-data-list
        role="menu"
        emptyContent=" "
        [ngStyle]="{ width: width + 'px' }"
        >
        <ng-container *ngTemplateOutlet="items"></ng-container>
      </tui-data-list>
    </ng-template>
  </tui-hosted-dropdown>
</div>
