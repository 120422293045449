import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule } from '@taiga-ui/core';
import { IGameScore, ITeamGame } from '@lancelot-frontend/api';
import { ContractComponent } from '../contract/contract.component';
import { OpeningLeadComponent } from '../opening-lead/opening-lead.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-teams-game-scores',
  templateUrl: './teams-game-scores.component.html',
  styleUrls: ['./teams-game-scores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TranslocoDirective,
    TuiButtonModule,
    ContractComponent,
    OpeningLeadComponent,
    TableComponent,
  ],
})
export class TeamsGameScoresComponent implements OnChanges {
  @Input() segment?: ITeamGame['segments'][number] | undefined;
  @Input() homeTeam?: ITeamGame['homeTeam'];
  @Input() awayTeam?: ITeamGame['awayTeam'];
  @Input() selectedRowHandler?: (row: IGameScore) => boolean;
  @Input() loading?: boolean;
  @Input() getRowLink?: (
    row: IGameScore,
    index: number,
  ) => [
    commands: (number | string)[] | number | string,
    navigationExtras?: NavigationExtras,
  ];

  data: IGameScore[] = [];
  homeTeamLabel = '';
  awayTeamLabel = '';

  getCellData = (_: IGameScore, column: string, rowIndex: number) => {
    const lineup = this.segment?.lineups.find(
      (lineup) => lineup.room === (column.startsWith('closedRoom') ? 'C' : 'O'),
    );
    const subColumn = column.replace(/openRoom.|closedRoom./g, '');
    const board = lineup?.scores[rowIndex];

    switch (subColumn) {
      case 'nsScore':
      case 'ewScore': {
        if (board?.contract.toUpperCase() === 'PASS') {
          return '';
        }

        return board?.[subColumn];
      }
      case 'homeTeam':
      case 'awayTeam': {
        const note =
          board?.[subColumn === 'homeTeam' ? 'nsNote' : 'ewNote'] || 0;
        return note === 0 ? '—' : note > 0 ? note : '';
      }
      default: {
        return board?.[subColumn as keyof IGameScore];
      }
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.segment?.currentValue) {
      this.data = this.segment?.lineups[0]?.scores ?? [];
    }
    if (changes.homeTeam?.currentValue) {
      this.homeTeamLabel = this.homeTeam?.label ?? '';
      if (!this.homeTeamLabel) {
        const homeTeamCaptain = this.homeTeam?.player1;
        if (homeTeamCaptain) {
          if (typeof homeTeamCaptain === 'string') {
            this.homeTeamLabel = homeTeamCaptain;
          } else if ('lastName' in homeTeamCaptain) {
            this.homeTeamLabel = `${homeTeamCaptain.lastName.toUpperCase()} ${
              homeTeamCaptain.firstName
            }`;
          }
        }
      }
    }
    if (changes.awayTeam?.currentValue) {
      this.awayTeamLabel = this.awayTeam?.label ?? '';
      if (!this.awayTeamLabel) {
        const awayTeamCaptain = this.awayTeam?.player1;
        if (awayTeamCaptain) {
          if (typeof awayTeamCaptain === 'string') {
            this.awayTeamLabel = awayTeamCaptain;
          } else if ('lastName' in awayTeamCaptain) {
            this.awayTeamLabel = `${awayTeamCaptain.lastName.toUpperCase()} ${
              awayTeamCaptain.firstName
            }`;
          }
        }
      }
    }
  }
}
