import { a as i, b as m } from "./chunk-LSZKOVGW.mjs";
import { j as e, k as r } from "./chunk-GTEBSQTL.mjs";
var a = ["\u7D05\u8272", "\u7DA0\u8272", "\u85CD\u8272", "\u9EC3\u8272", "\u7D2B\u8272", "\u8584\u8377\u7DA0\u8272", "\u85CD\u7DA0\u8272", "\u767D\u8272", "\u9ED1\u8272", "\u6A59\u8272", "\u7C89\u7D05\u8272", "\u7070\u8272", "\u7D05\u8910\u8272", "\u85CD\u7D2B\u8272", "\u9752\u7DA0\u8272", "\u68D5\u8910\u8272", "\u5929\u85CD\u8272", "\u6DFA\u6A59\u8272", "\u7D2B\u7D05\u8272", "\u6DE1\u7D2B\u8272", "\u6DE1\u8910\u8272", "\u9752\u6AB8\u8272", "\u4E73\u767D\u8272", "\u975B\u85CD\u8272", "\u91D1\u8272", "\u9280\u8272"];
var M = {
    human: a
  },
  f = M;
var n = {
  wide: ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
  abbr: ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"]
};
var p = {
  wide: ["\u661F\u671F\u5929", "\u661F\u671F\u4E00", "\u661F\u671F\u4E8C", "\u661F\u671F\u4E09", "\u661F\u671F\u56DB", "\u661F\u671F\u4E94", "\u661F\u671F\u516D"],
  abbr: ["\u9031\u65E5", "\u9031\u4E00", "\u9031\u4E8C", "\u9031\u4E09", "\u9031\u56DB", "\u9031\u4E94", "\u9031\u516D"]
};
var H = {
    month: n,
    weekday: p
  },
  l = H;
var u = ["####", "###", "##", "#"];
var d = ["{{location.city_prefix}}{{location.city_suffix}}"];
var s = ["\u81FA\u5317", "\u65B0\u5317", "\u6843\u5712", "\u81FA\u4E2D", "\u81FA\u5357", "\u9AD8\u96C4", "\u57FA\u9686", "\u65B0\u7AF9", "\u5609\u7FA9", "\u82D7\u6817", "\u5F70\u5316", "\u5357\u6295", "\u96F2\u6797", "\u5C4F\u6771", "\u5B9C\u862D", "\u82B1\u84EE", "\u81FA\u6771", "\u6F8E\u6E56", "\u91D1\u9580", "\u9023\u6C5F"];
var x = ["\u7E23", "\u5E02"];
var c = ["Taiwan (R.O.C.)"];
var _ = ["######"];
var y = ["\u798F\u5EFA\u7701", "\u53F0\u7063\u7701"];
var b = ["\u5317", "\u65B0\u5317", "\u6843", "\u4E2D", "\u5357", "\u9AD8", "\u57FA", "\u7AF9\u5E02", "\u5609\u5E02", "\u7AF9\u7E23", "\u82D7", "\u5F70", "\u6295", "\u96F2", "\u5609\u7E23", "\u5B9C", "\u82B1", "\u6771", "\u6F8E", "\u91D1", "\u99AC"];
var D = {
  normal: "{{location.street}}{{location.buildingNumber}}\u865F",
  full: "{{location.street}}{{location.buildingNumber}}\u865F {{location.secondaryAddress}}"
};
var h = ["{{person.last_name}}{{location.street_suffix}}"];
var g = ["\u8857", "\u8DEF", "\u5317\u8DEF", "\u5357\u8DEF", "\u6771\u8DEF", "\u897F\u8DEF"];
var O = {
    building_number: u,
    city_pattern: d,
    city_prefix: s,
    city_suffix: x,
    default_country: c,
    postcode: _,
    state: y,
    state_abbr: b,
    street_address: D,
    street_pattern: h,
    street_suffix: g
  },
  w = O;
var R = {
    title: "Chinese (Taiwan)",
    code: "zh_TW",
    country: "TW",
    language: "zh",
    endonym: "\u4E2D\u6587 (\u81FA\u7063)",
    dir: "ltr",
    script: "Hant"
  },
  C = R;
var t = ["\u4FCA\u99B3", "\u51F1\u745E", "\u535A\u6FE4", "\u5609\u61FF", "\u5B50\u6DB5", "\u5B50\u9A2B", "\u5B50\u9ED8", "\u601D\u6DFC", "\u61FF\u8ED2", "\u64CE\u84BC", "\u65ED\u582F", "\u660A\u7131", "\u665F\u777F", "\u667A\u5BB8", "\u667A\u6DF5", "\u66C9\u535A", "\u66C9\u562F", "\u6977\u745E", "\u6A02\u99D2", "\u701F\u7136", "\u70AB\u660E", "\u715C\u57CE", "\u715C\u797A", "\u71A0\u5F64", "\u71C1\u78CA", "\u71C1\u83EF", "\u71C1\u9716", "\u747E\u745C", "\u777F\u6DF5", "\u7ACB\u8ED2", "\u7B11\u611A", "\u8070\u5065", "\u82D1\u535A", "\u8D8A\u5F6C", "\u923A\u8ED2", "\u9326\u7A0B", "\u9756\u742A", "\u98A8\u83EF", "\u9DB4\u8ED2", "\u9DFA\u6D0B", "\u9ECE\u6615"];
var N = null;
var o = ["\u4FEE\u5091", "\u4FEE\u6F54", "\u5049\u5BB8", "\u5049\u6FA4", "\u5049\u797A", "\u5049\u8AA0", "\u5065\u67CF", "\u5065\u96C4", "\u535A\u6587", "\u535A\u8D85", "\u541B\u6D69", "\u54F2\u701A", "\u5609\u7199", "\u5929\u5B87", "\u5929\u78CA", "\u5929\u7FCA", "\u5B50\u8ED2", "\u5C55\u9D6C", "\u5CFB\u7199", "\u5EFA\u8F1D", "\u5F18\u6587", "\u5FD7\u5F37", "\u5FD7\u6FA4", "\u601D\u6E90", "\u601D\u8070", "\u601D\u9060", "\u632F\u5BB6", "\u64CE\u5B87", "\u6587\u535A", "\u6587\u660A", "\u6587\u8ED2", "\u660A\u5929", "\u660A\u5F37", "\u660A\u7136", "\u660E\u54F2", "\u660E\u6770", "\u660E\u8ED2", "\u660E\u8F1D", "\u6649\u9D6C", "\u667A\u8F1D", "\u6893\u6668", "\u69AE\u8ED2", "\u6B63\u8C6A", "\u6D69\u5B87", "\u6D69\u7136", "\u6D69\u8ED2", "\u6FA4\u6D0B", "\u708E\u5F6C", "\u71C1\u5049", "\u745E\u9716", "\u7693\u8ED2", "\u7ACB\u679C", "\u7ACB\u8AA0", "\u7ACB\u8F1D", "\u7D39\u8F1D", "\u7D39\u9F4A", "\u8000\u5091", "\u80E4\u7965", "\u81F4\u9060", "\u8A9E\u5802", "\u8D8A\u6FA4", "\u9060\u822A", "\u91D1\u946B", "\u946B\u78CA", "\u946B\u9D6C", "\u96E8\u6FA4", "\u96EA\u677E", "\u9D3B\u6FE4", "\u9D3B\u714A", "\u9D6C\u6FE4", "\u9D6C\u714A", "\u9D6C\u98DB"];
var P = i(t, o);
var T = ["\u738B", "\u674E", "\u5F35", "\u5289", "\u9673", "\u694A", "\u9EC3", "\u5433", "\u8D99", "\u9031", "\u5F90", "\u5B6B", "\u99AC", "\u6731", "\u80E1", "\u6797", "\u90ED", "\u4F55", "\u9AD8", "\u7F85", "\u912D", "\u6881", "\u8B1D", "\u5B8B", "\u5510", "\u8A31", "\u9127", "\u99AE", "\u97D3", "\u66F9", "\u66FE", "\u5F6D", "\u856D", "\u8521", "\u6F58", "\u7530", "\u8463", "\u8881", "\u65BC", "\u9918", "\u8449", "\u8523", "\u675C", "\u8607", "\u9B4F", "\u7A0B", "\u5442", "\u4E01", "\u6C88", "\u4EFB", "\u59DA", "\u76E7", "\u5085", "\u9418", "\u59DC", "\u5D14", "\u8B5A", "\u5ED6", "\u7BC4", "\u6C6A", "\u9678", "\u91D1", "\u77F3", "\u6234", "\u8CC8", "\u97CB", "\u590F", "\u90B1", "\u65B9", "\u4FAF", "\u9112", "\u718A", "\u5B5F", "\u79E6", "\u767D", "\u6C5F", "\u95BB", "\u859B", "\u5C39", "\u6BB5", "\u96F7", "\u9ECE", "\u53F2", "\u9F8D", "\u9676", "\u8CC0", "\u9867", "\u6BDB", "\u90DD", "\u9F94", "\u90B5", "\u842C", "\u9322", "\u56B4", "\u8CF4", "\u8983", "\u6D2A", "\u6B66", "\u83AB", "\u5B54"];
var z = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var L = null;
var W = [{
  value: "{{person.lastName}}{{person.firstName}}",
  weight: 1
}];
var k = null;
var j = {
    female_first_name: t,
    female_prefix: N,
    first_name: P,
    last_name: T,
    last_name_pattern: z,
    male_first_name: o,
    male_prefix: L,
    name: W,
    prefix: k
  },
  F = j;
var v = ["0#-#######", "02-########", "09##-######"];
var q = {
    formats: v
  },
  A = q;
var E = {
    color: f,
    date: l,
    location: w,
    metadata: C,
    person: F,
    phone_number: A
  },
  B = E;
var eo = new e({
  locale: [B, m, r]
});
export { B as a, eo as b };