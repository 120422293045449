import { b as e } from "./chunk-LSZKOVGW.mjs";
import { j as n, k as o } from "./chunk-GTEBSQTL.mjs";
var a = ["Ltd.", "Co. Ltd."];
var Y = {
    suffix: a
  },
  i = Y;
var t = ["com", "hk", "com.hk", "org.hk"];
var F = {
    domain_suffix: t
  },
  r = F;
var u = ["###", "##", "#"];
var m = ["Aberdeen", "Ap Lei Chau", "Causeway Bay", "Chai Wan", "Cheung Chau", "Cheung Fat", "Cheung Sha Wan", "Choi Hung Chuen", "Choi Ming", "Chuk Yuen", "Cyberport", "Discovery Bay", "Fairview Park", "Fanling", "Fo Tan", "Fu Shan", "Fu Shin", "Fu Tai", "Happy Valley", "Heng Fa Chuen", "Heng On", "Hin Keng", "Ho Man Tin", "Hung Hom Bay", "Kam Tai", "Kam Tin", "Kennedy Town", "Kowloon", "Kowloon Bay", "Kowloon Central", "Kowloon City", "Kowloon East", "Kwai Chung", "Kwai Fong", "Kwai Shing", "Kwong Yuen", "Kwun Tong", "Lai King", "Lai Kok", "Lam Tin", "Lamma", "Lee On", "Lei Muk Shue", "Lei Tung", "Leung King", "Lok Fu", "Ma On Shan", "Mei Foo Sun Chuen", "Mei Lam", "Mong Kok", "Mui Wo", "Ngau Chi Wan", "Ngau Tau Kok", "Oi Man", "Peak", "Peng Chau", "Po Lam", "Pok Fu Lam", "Repulse Bay", "Sai Kung", "Sai Ying Pun", "San Tin", "Sau Mau Ping", "Sha Kok", "Sha Tau Kok", "Sha Tin", "Sham Shui Po", "Shau Kei Wan", "Shek Kip Mei", "Shek Lei", "Shek Wai Kok", "Shek Wu Hui", "Sheung Tak", "Sheung Wan", "Shun Lee", "Siu Sai Wan", "So Uk", "Stanley", "Sun Chui", "Tai Hing", "Tai Kok Tsui", "Tai Koo Shing", "Tai O", "Tai Po", "Tin Yiu", "Tin Yuet", "To Kwa Wan", "Tsat Tsz Mui", "Tseung Kwan O", "Tsim Sha Tsui", "Tsing Yi", "Tsuen Wan", "Tsz Wan Shan", "Tuen Mun", "Tung Chung", "Wah Fu", "Wah Ming", "Wan Chai", "Wan Tau Tong", "Wo Che", "Wong Tai Sin", "Yau Tong", "Yau Yat Tsuen", "Yuen Long"];
var g = ["{{location.city_name}}"];
var p = ["Hong Kong"];
var h = null;
var l = null;
var f = ["Hong Kong Island", "Kowloon", "New Territories"];
var s = ["HK", "KLN", "NT"];
var C = ["Wan", "On", "Tai", "Man", "Fung", "Cheung", "Tung", "Hing", "Po", "Wah", "Tak", "Shing", "Lung", "Yuen", "Wing", "Hong", "Yip", "King", "Kwong", "Hoi", "Ming", "Wa", "Lok", "Yan", "Wai", "Chi", "Fuk", "Lai", "Lee", "Fu", "Tin", "Kai", "Sai", "Shun", "Ping", "Yee", "Wo", "Chung", "Hang", "Ning", "Wong", "Yue", "Choi", "Wang", "Ching", "Sau", "Shan", "Tsui", "Tau", "Sheung", "Lam", "Fat", "Hung", "Chuk", "Shek", "Kok", "Cheong", "Fong", "Nam", "Lei", "Yu", "Mei", "Pak", "Fai", "Kwai", "Sing", "Kung", "Chau", "Tong", "San", "Chiu", "Chun", "Yin", "Yuk", "Ting", "Kam", "Lun", "Oi"];
var T = ["Aldrich", "Arran", "Austin", "Baker", "Battery", "Bel-Air", "Bonham", "Boundary", "Bowen", "Breezy", "Caine", "Cameron", "Canal", "Cape", "Chatham", "Church", "College", "Comet", "Connaught", "Cornwall", "Cox's", "Cross", "Douglas", "Dragon", "Eastern", "Electric", "Expo", "Findlay", "First", "Garden", "Gillies", "Greig", "Hospital", "Jardine's", "Jordan", "Kennedy", "Kimberley", "Leighton", "Maidstone", "Maple", "Marsh", "Monmouth", "Oaklands", "Peel", "Poplar", "Rose", "Second", "Seymour", "Stewart", "Third", "Village", "Water", "Waterloo", "Wylie"];
var d = ["{{location.street_english_part}} {{location.street_suffix}}", "{{location.street_cantonese_part}} {{location.street_cantonese_part}} {{location.street_suffix}}"];
var K = null;
var S = ["Street", "Road", "Lane", "Path", "Terrace", "Avenue", "Drive", "Crescent", "Court"];
var M = {
    building_number: u,
    city_name: m,
    city_pattern: g,
    default_country: p,
    postcode: h,
    postcode_by_state: l,
    state: f,
    state_abbr: s,
    street_cantonese_part: C,
    street_english_part: T,
    street_pattern: d,
    street_prefix: K,
    street_suffix: S
  },
  L = M;
var P = {
    title: "English (Hong Kong)",
    code: "en_HK",
    country: "HK",
    language: "en",
    endonym: "English (Hong Kong)",
    dir: "ltr",
    script: "Latn"
  },
  c = P;
var y = ["Au", "Chan", "Chang", "Chen", "Cheng", "Cheuk", "Cheung", "Chiu", "Cho", "Choi", "Chong", "Chow", "Choy", "Chu", "Chui", "Chung", "Fan", "Fok", "Fu", "Fung", "He", "Ho", "Hong", "Hu", "Huang", "Hui", "Ip", "Kan", "Keung", "Ko", "Kong", "Kwan", "Kwok", "Kwong", "Lai", "Lam", "Lau", "Law", "Lee", "Leung", "Li", "Liang", "Lin", "Ling", "Liu", "Lu", "Lui", "Luk", "Lung", "Ma", "Mak", "Man", "Mok", "Ng", "Ngai", "Pang", "Poon", "Pun", "Shiu", "Shum", "Sin", "Siu", "So", "Suen", "Sun", "Sze", "Szeto", "Tai", "Tam", "Tan", "Tang", "Tong", "Tsang", "Tse", "Tsoi", "Tsui", "Wan", "Wang", "Wong", "Wu", "Xu", "Yan", "Yang", "Yeung", "Yim", "Yin", "Yip", "Yiu", "Yu", "Yue", "Yuen", "Yung", "Zhang", "Zhao", "Zheng", "Zhou", "Zhu"];
var k = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var x = [{
  value: "{{person.firstName}} {{person.lastName}}",
  weight: 1
}];
var D = {
    last_name: y,
    last_name_pattern: k,
    name: x
  },
  _ = D;
var W = ["2### ####", "3### ####", "4### ####", "5### ####", "6### ####", "7### ####", "9### ####"];
var B = {
    formats: W
  },
  H = B;
var b = {
    company: i,
    internet: r,
    location: L,
    metadata: c,
    person: _,
    phone_number: H
  },
  w = b;
var An = new n({
  locale: [w, e, o]
});
export { w as a, An as b };