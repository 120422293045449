<section>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>

  <div
    class="full-width flex flex-row justify-between items-end pb1 mb1 border-bottom-base-03"
    [class.cursor-pointer]="expandable"
    (click)="expandable ? toggle() : undefined"
  >
    <div class="flex-auto">
      <ng-container *polymorpheusOutlet="title as text">
        @if ((text | typeof) === 'string') {
          <h5 class="tui-text_h5 font-weight-normal">{{ text }}</h5>
        } @else {
          {{ text }}
        }
      </ng-container>
    </div>
    @if (actions || expandable) {
      <div class="flex flex-row justify-end items-end gap1">
        @if (actions) {
          <ng-container *polymorpheusOutlet="actions as text">
            {{ text }}
          </ng-container>
        }
        @if (expandable) {
          <button
            tuiIconButton
            icon="tuiIconChevronDown"
            size="xs"
            appearance="text"
            [class.upside-down]="expanded"
          ></button>
        }
      </div>
    }
  </div>
  @if (!expandable) {
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  } @else {
    <tui-expand [expanded]="expanded">
      <ng-template tuiExpandContent>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </ng-template>
    </tui-expand>
  }
</section>
