<ng-container *transloco="let t">
  <ng-template #playerTemplate let-player="player">
    @if (player.firstName) {
      <p [class.tui-skeleton]="!lineup">
        {{ player.lastName | uppercase }} {{ player.firstName }}
      </p>
    }
    @if (!player.firstName) {
      <p [class.tui-skeleton]="!lineup">{{ player }}</p>
    }
  </ng-template>

  <div class="flex flex-row justify-center items-center">
    <ng-template
      [ngTemplateOutlet]="playerTemplate"
      [ngTemplateOutletContext]="{
        player: lineup?.northPlayer || playerPlaceholder
      }"
    ></ng-template>
  </div>
  <div class="flex flex-row justify-center items-center">
    <div class="flex-0 flex flex-row justify-end">
      <ng-template
        [ngTemplateOutlet]="playerTemplate"
        [ngTemplateOutletContext]="{
          player: lineup?.westPlayer || playerPlaceholder
        }"
      ></ng-template>
    </div>
    <div
      class="mat flex flex-column justify-between border-radius-m bg-primary color-text-01-night tui-text_body-l tui-font-heading bold m2 p1"
      >
      <div class="center">
        <span>{{ t('components.cardinalPoints.N.short') }}</span>
      </div>
      <div class="flex flex-row justify-between px1">
        <span>{{ t('components.cardinalPoints.W.short') }}</span>
        <span>{{ t('components.cardinalPoints.E.short') }}</span>
      </div>
      <div class="center">
        <span>{{ t('components.cardinalPoints.S.short') }}</span>
      </div>
    </div>
    <div class="flex-0 flex flex-row justify-start">
      <ng-template
        [ngTemplateOutlet]="playerTemplate"
        [ngTemplateOutletContext]="{
          player: lineup?.eastPlayer || playerPlaceholder
        }"
      ></ng-template>
    </div>
  </div>
  <div class="flex flex-row justify-center items-center">
    <ng-template
      [ngTemplateOutlet]="playerTemplate"
      [ngTemplateOutletContext]="{
        player: lineup?.southPlayer || playerPlaceholder
      }"
    ></ng-template>
  </div>
</ng-container>
