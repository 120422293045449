import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiTabsModule } from '@taiga-ui/kit';

export type TTab = {
  labelTranslationKey: string;
  routerLink: string | string[];
};

@Component({
  selector: 'ffb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, TuiTabsModule, RouterLink, RouterLinkActive],
})
export class TabsComponent {
  @Input() tabs: TTab[] = [];
}
