import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  HostBinding,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TuiOverscrollModule } from '@taiga-ui/cdk';
import {
  TUI_ANIMATION_OPTIONS,
  TuiAnimationOptions,
  tuiSlideInBottom,
} from '@taiga-ui/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { ClickOutsideDirective } from '@lancelot-frontend/core';
import { DropdownMenuDirective } from './dropdown-menu.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[ffbDropdownMenu]',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tuiSlideInBottom],
  standalone: true,
  imports: [TuiOverscrollModule, PolymorpheusModule, ClickOutsideDirective],
})
export class DropdownMenuComponent implements DoCheck {
  private sanitizer = inject(DomSanitizer);
  private readonly directive = inject(DropdownMenuDirective);
  private readonly options = inject(TUI_ANIMATION_OPTIONS);

  content: PolymorpheusContent;

  constructor() {
    this.content = this.directive.content;
  }

  @HostBinding('style')
  get style(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(this.directive.style);
  }

  @HostBinding('@tuiSlideInBottom')
  get animation(): TuiAnimationOptions {
    return {
      value: 'bottom',
      ...this.options,
    };
  }

  onClickOutside() {
    if (this.directive.isVisible) {
      this.directive.ffbDropdownMenuChange.emit(false);
    }
  }

  ngDoCheck() {
    this.directive.check();
  }
}
