import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IEntity,
  IMe,
  IPerson,
  IUser,
  TPaginatedList,
  TPaginationParams,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private http = inject(HttpClient);

  apiUrl = inject(EnvironmentService).get('apiBaseUrl');
  usersUrl = this.apiUrl + '/users';
  signedInUserUrl = `${this.usersUrl}/me`;

  getSignedInUser() {
    return this.http.get<IMe>(`${this.usersUrl}/whoami`);
  }

  getSingedInUserEASIToken() {
    return this.http.get<string>(`${this.signedInUserUrl}/easi-token`);
  }

  validateSignedInUserEmail() {
    return this.http.post<IMe>(`${this.signedInUserUrl}/validate-email`, {});
  }

  registerSignedInUserDevice(data: {
    FCMToken: string;
    model?: string;
    name?: string;
    operatingSystem?: string;
    osVersion?: string;
    platform?: string;
    uuid: string;
  }) {
    return this.http.post(`${this.signedInUserUrl}/devices/register`, data);
  }

  getSignedInUserManageableEntities() {
    return this.http.get<IEntity[]>(
      `${this.signedInUserUrl}/manageable-entities`,
      {
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  deleteSignedInUser() {
    return this.http.delete<void>(this.signedInUserUrl);
  }

  searchForUsers(
    params: TPaginationParams & {
      searchEmail?: IPerson['email'];
      searchFirebaseUid?: IUser['firebaseUid'];
      searchHasPerson?: 0 | 1 | boolean;
      searchHasToken?: 0 | 1 | boolean;
    },
  ) {
    if (typeof params.searchHasPerson === 'boolean') {
      params.searchHasPerson = params.searchHasPerson ? 1 : 0;
    }
    if (typeof params.searchHasToken === 'boolean') {
      params.searchHasToken = params.searchHasToken ? 1 : 0;
    }

    return this.http.get<TPaginatedList<IUser>>(`${this.usersUrl}/search`, {
      params,
    });
  }

  getUser(userId: IUser['id']) {
    return this.http.get<IUser>(`${this.usersUrl}/${userId}`);
  }

  updateUser(userId: IUser['id'], data: Partial<IUser>) {
    return this.http.post<IUser>(`${this.usersUrl}/${userId}`, data);
  }

  createUserToken(userId: IUser['id']) {
    return this.http.post<Pick<IUser, 'token' | 'tokenExpiredAt'>>(
      `${this.usersUrl}/${userId}/create-token`,
      {},
    );
  }

  revokeUserToken(userId: IUser['id']) {
    return this.http.post(`${this.usersUrl}/${userId}/revoke-token`, {});
  }

  deleteUser(userId: IUser['id']) {
    return this.http.delete(`${this.usersUrl}/${userId}`);
  }

  validateFirebaseEmail(token: string) {
    return this.http.post(`${this.apiUrl}/public/emails/validate/${token}`, {});
  }

  updatePassword(userId: IUser['id'], data: { newPassword: string }) {
    return this.http.post(`${this.usersUrl}/${userId}/change-password`, data);
  }

  sendLoginNotification(data: {
    browserName?: string;
    browserVersion?: string;
    datetime: string;
    isVirtual: boolean;
    manufacturer: string;
    model: string;
    operatingSystem: string;
    osVersion: string;
    platform: 'android' | 'ios' | 'web';
  }) {
    return this.http.post(`${this.apiUrl}/notifications/login`, data);
  }
}
