<ng-container *transloco="let t">
  <span class="nowrap">
    @if (openingLead.includes('C')) {
      <ffb-suit-icon
        suit="C"
        [fittext]="true"
      ></ffb-suit-icon>
    }
    @else if (openingLead.includes('D')) {
      <ffb-suit-icon
        suit="D"
        [fittext]="true"
      ></ffb-suit-icon>
    }
    @else if (openingLead.includes('H')) {
      <ffb-suit-icon
        suit="H"
        [fittext]="true"
      ></ffb-suit-icon>
    }
    @else if (openingLead.includes('S')) {
      <ffb-suit-icon
        suit="S"
        [fittext]="true"
      ></ffb-suit-icon>
    }
    <span>
      @if ((card | typeof) === 'string') {
        {{ t('components.cards.' + card + '.short') }}
      }
      @if ((card | typeof) === 'number') {
        {{ card }}
      }
    </span>
  </span>
</ng-container>
