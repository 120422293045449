import { DOCUMENT, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { MediaService } from '@lancelot-frontend/core';

type TControls = {
  fullscreen: boolean;
  mute: boolean;
  playPause: 'simple' | boolean;
  progress: boolean;
};

@Component({
  selector: 'ffb-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TuiButtonModule, TuiSvgModule, NgStyle],
})
export class VideoComponent implements OnChanges {
  private document = inject(DOCUMENT);
  private changeDetector = inject(ChangeDetectorRef);
  private mediaService = inject(MediaService);

  video?: HTMLVideoElement;
  progressBar?: HTMLDivElement;

  @ViewChild('videoPlayer') set videoNativeElement(
    videoPlayer: ElementRef<HTMLVideoElement>,
  ) {
    this.video = videoPlayer?.nativeElement;
  }

  @ViewChild('progressBar') set progressBarElement(
    progressBarRef: ElementRef<HTMLDivElement>,
  ) {
    this.progressBar = progressBarRef?.nativeElement;
  }

  private _muted = true;
  private _controls: TControls = {
    playPause: true,
    progress: true,
    mute: true,
    fullscreen: true,
  };

  @Input() url!: string;
  @Input() mime!: string;
  @Input() poster?: string;
  @Input() autoplay = true;
  @Input() loop = false;
  @Input() disposition: 'background' | 'free' | 'fullScreenWidth' = 'free';

  @Input() set muted(muted) {
    this._muted = muted;
  }

  get muted() {
    return this._muted;
  }

  @Input() set controls(controls: 'simple' | Partial<TControls> | boolean) {
    if (typeof controls === 'boolean') {
      this._controls = Object.keys(this._controls).reduce((acc, key) => {
        acc[key as keyof TControls] = controls;
        return acc;
      }, {} as TControls);
    } else if (controls === 'simple') {
      this._controls = {
        playPause: 'simple',
        progress: true,
        mute: false,
        fullscreen: false,
      };
    } else {
      this._controls = { ...this._controls, ...controls };
    }
  }

  get controls(): TControls {
    return this._controls;
  }

  private readonly _visibleControlsSubject$ = new BehaviorSubject(
    !this.autoplay,
  );

  get visibleControls() {
    return (
      Object.values(this._controls).some((visible) => visible) &&
      (this._visibleControlsSubject$.value || !this.playing)
    );
  }

  playing = this.autoplay;
  fullscreen = false;
  progress = 0;
  videoUrl = '';

  constructor() {
    this._visibleControlsSubject$
      .pipe(
        filter((visible) => visible),
        debounceTime(4000),
      )
      .subscribe(() => {
        this.hideControls();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      this.videoUrl = this.mediaService.getOptimizedVideoUrl(this.url);
      this.changeDetector.detectChanges();
    }
  }

  showControls() {
    this._visibleControlsSubject$.next(true);
  }

  hideControls() {
    this._visibleControlsSubject$.next(false);
  }

  onPlay() {
    this.playing = true;
  }

  onPause() {
    this.playing = false;
  }

  onEnded() {
    if (!this.loop) {
      this.playing = false;
      if (this.video) {
        this.video.currentTime = 0;
      }
    }
  }

  play() {
    this.video?.play();
  }

  pause() {
    this.video?.pause();
  }

  playPause() {
    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  toggleMuted() {
    this._muted = !this._muted;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  onFullscreenChange() {
    const doc = this.document as Document & {
      mozFullScreen: boolean;
      msFullscreenElement: boolean;
      webkitIsFullScreen: boolean;
    };

    if (
      doc.fullscreen ||
      doc.webkitIsFullScreen ||
      doc.mozFullScreen ||
      doc.msFullscreenElement ||
      doc.fullscreenElement
    ) {
      this.fullscreen = true;
    } else {
      this.fullscreen = false;
    }
  }

  openFullscreen() {
    const video = this.video as HTMLVideoElement & {
      mozRequestFullScreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
    };

    if (video?.requestFullscreen) {
      video?.requestFullscreen();
    } else if (video?.mozRequestFullScreen) {
      video?.mozRequestFullScreen();
    } else if (video?.webkitRequestFullscreen) {
      video?.webkitRequestFullscreen();
    } else if (video?.msRequestFullscreen) {
      video?.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    const doc = this.document as Document & {
      mozCancelFullScreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
    };

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  }

  toggleFullscreen() {
    if (this.fullscreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  @HostListener('window:blur')
  onBlur() {
    this.pause();
  }

  updateTime(e: MouseEvent) {
    if (
      this.progressBar &&
      this.progressBar.parentElement?.offsetWidth &&
      this.video
    ) {
      const progressTime =
        (e.offsetX / this.progressBar?.parentElement?.offsetWidth) *
        this.video.duration;
      this.video.currentTime = progressTime;
    }
  }

  onTimeUpdate() {
    if (this.progressBar && this.video) {
      this.progress = Math.floor(
        (this.video.currentTime / this.video.duration) * 100,
      );
    }
  }

  stopEventPropagation(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
}
