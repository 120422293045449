import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  inject,
} from '@angular/core';
import { TUI_SCROLL_REF, TuiLoaderModule } from '@taiga-ui/core';

@Component({
  selector: 'ffb-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TuiLoaderModule],
})
export class InfiniteScrollComponent implements AfterViewInit {
  private readonly documentRef = inject(DOCUMENT);
  private readonly nativeElement = inject(ElementRef).nativeElement;
  private readonly scrollRef = inject(TUI_SCROLL_REF, { optional: true });

  @Input() loading = false;

  scrollHost =
    this.scrollRef?.nativeElement || this.documentRef.documentElement;

  @Output() reachEnd = new EventEmitter<void>();

  private getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (node === null) {
      return null;
    }

    if (node.tagName === 'SECTION' && node.role === 'dialog') {
      return node;
    } else {
      return this.getScrollParent(node.parentElement);
    }
  }

  private onMove() {
    if (this.nativeElement.style.display !== 'none') {
      const bottom = this.nativeElement.getBoundingClientRect?.()?.bottom;

      if (bottom !== undefined) {
        const { clientHeight } = this.scrollHost;

        // Emit event at 100px from bottom
        if (bottom <= clientHeight + 100) {
          this.reachEnd.emit();
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.scrollHost === this.documentRef.documentElement) {
      this.scrollHost =
        this.getScrollParent(this.nativeElement) || this.scrollHost;
    }

    if (this.scrollHost !== this.documentRef.documentElement) {
      this.scrollHost.addEventListener('scroll', () => {
        this.onMove();
      });
      this.scrollHost.addEventListener('resize', () => {
        this.onMove();
      });
    }

    this.onMove();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.scrollHost === this.documentRef.documentElement) {
      this.onMove();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.scrollHost === this.documentRef.documentElement) {
      this.onMove();
    }
  }
}
