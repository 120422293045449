import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IGenericOrganization } from '../types';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private http = inject(HttpClient);

  organizationsUrl =
    inject(EnvironmentService).get('apiBaseUrl') + '/organizations';

  getOrganization(organizationId: IGenericOrganization['id']) {
    return this.http.get<IGenericOrganization>(
      `${this.organizationsUrl}/${organizationId}`,
      {
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  searchForOrganizations(params: { searchValue: string }) {
    return this.http.get<IGenericOrganization[]>(
      `${this.organizationsUrl}/search`,
      {
        params,
      },
    );
  }
}
