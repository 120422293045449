import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IClub,
  ICompetition,
  ICompetitionGroup,
  ICompetitionGroupSession,
  ICompetitionGroupWithPhase,
  ICompetitionPhase,
  ICompetitionPhaseWithStade,
  ICompetitionRegistration,
  ICompetitionRegistrationListItem,
  ICompetitionRegistrationSuccess,
  ICompetitionSessionWithGroupSessions,
  ICompetitionStade,
  ICompetitionStadeRegisteredTeam,
  IDivision,
  IFestival,
  IOrganization,
  IPaymentForm,
  IPerson,
  ISeason,
  ISimultaneous,
  ISimultaneousSessionListItem,
  TPaginatedList,
  TPaginationParams,
  TSearchCompetitionType,
} from '../types';

export type TCompetitionsSearchParams<
  TContext = 'registration' | 'results' | undefined,
> = TPaginationParams & {
  'context[]'?: TContext extends 'registration'
    ? 'registration_status'
    : ['result_status', 'result_data'];
  'searchAgeCategory[]'?: ICompetition['ageCategory'][];
  'searchCategory[]'?: ('mixed' | 'open' | 'women')[];
  searchCompetitionType?: TSearchCompetitionType;
  'searchDivision[]'?: IDivision['id'][];
  searchFestival?: IFestival['id'];
  'searchFormat[]'?: ICompetition['format'][];
  searchLabel?: string;
  'searchLocation[]'?: ICompetitionGroup['location'][];
  searchOrganization?: IOrganization['id'];
  searchPerson?: IPerson['id'];
  searchSeason?: 'current' | number;
};

@Injectable({
  providedIn: 'root',
})
export class CompetitionsService {
  private http = inject(HttpClient);
  private environmentService = inject(EnvironmentService);

  personsUrl = this.environmentService.get('apiBaseUrl') + '/persons';
  seasonsUrl = this.environmentService.get('apiBaseUrl') + '/seasons';
  competitionsUrl = this.environmentService.get('apiBaseUrl') + '/competitions';

  getSeasons(
    params: TPaginationParams & {
      maxSeason?: 'current' | ISeason['id'];
    },
  ) {
    return this.http.get<TPaginatedList<ISeason>>(`${this.seasonsUrl}/search`, {
      params,
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getCurrentSeason() {
    return this.http.get<ISeason>(`${this.seasonsUrl}/current`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getSimultaneous(simultaneousId: ISimultaneous['id']) {
    return this.http.get<ISimultaneous>(
      `${this.competitionsUrl}/simultaneous/${simultaneousId}`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getSimultaneousSessions(
    simultaneousId: ISimultaneous['id'],
    params: TPaginationParams,
  ) {
    return this.http.get<TPaginatedList<ISimultaneousSessionListItem>>(
      `${this.competitionsUrl}/simultaneous/${simultaneousId}/sessions/`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionSession(
    sessionId: ICompetitionSessionWithGroupSessions['id'],
  ): Observable<ICompetitionSessionWithGroupSessions>;
  getCompetitionSession(
    sessionId: ICompetitionSessionWithGroupSessions['id'],
    params: Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<ICompetitionSessionWithGroupSessions<'registration'>>;
  getCompetitionSession(
    sessionId: ICompetitionSessionWithGroupSessions['id'],
    params: Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<ICompetitionSessionWithGroupSessions<'results'>>;
  getCompetitionSession<TContext>(
    sessionId: ICompetitionSessionWithGroupSessions['id'],
    params?: Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<ICompetitionSessionWithGroupSessions<TContext>>(
      `${this.competitionsUrl}/sessions/${sessionId}`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getDivisionsForCompetitionsSearchForm() {
    return this.http.get<IDivision[]>(
      `${this.competitionsUrl}/divisions/searchList`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  searchForCompetitions(
    params: TPaginationParams & {
      searchLabel?: ICompetition['label'];
    },
  ) {
    return this.http.get<TPaginatedList<ICompetition>>(
      `${this.competitionsUrl}/competitions/search`,
      {
        params,
      },
    );
  }

  createCompetition(data: Partial<Omit<ICompetition, 'id' | 'migrationId'>>) {
    return this.http.post<ICompetition>(
      `${this.competitionsUrl}/competitions/`,
      data,
    );
  }

  getCompetition(competitionId: ICompetition['id']) {
    return this.http.get<ICompetition>(
      `${this.competitionsUrl}/competitions/${competitionId}`,
    );
  }

  updateCompetition(
    competitionId: ICompetition['id'],
    data: Partial<Omit<ICompetition, 'id' | 'migrationId'>>,
  ) {
    return this.http.post<ICompetition>(
      `${this.competitionsUrl}/competitions/${competitionId}`,
      data,
    );
  }

  deleteCompetition(competitionId: ICompetition['id']) {
    return this.http.delete(
      `${this.competitionsUrl}/competitions/${competitionId}`,
    );
  }

  searchForDivisions(
    params: TPaginationParams & {
      searchLabel?: IDivision['label'];
    },
  ) {
    return this.http.get<TPaginatedList<IDivision>>(
      `${this.competitionsUrl}/divisions/search`,
      {
        params,
      },
    );
  }

  createDivision(data: Partial<Omit<IDivision, 'id' | 'migrationId'>>) {
    return this.http.post<IDivision>(
      `${this.competitionsUrl}/divisions/`,
      data,
    );
  }

  getDivision(divisionId: IDivision['id']) {
    return this.http.get<IDivision>(
      `${this.competitionsUrl}/divisions/${divisionId}`,
    );
  }

  updateDivision(
    divisionId: IDivision['id'],
    data: Partial<Omit<IDivision, 'id' | 'migrationId'>>,
  ) {
    return this.http.post<IDivision>(
      `${this.competitionsUrl}/divisions/${divisionId}`,
      data,
    );
  }

  deleteDivision(divisionId: IDivision['id']) {
    return this.http.delete(`${this.competitionsUrl}/divisions/${divisionId}`);
  }

  getCompetitionStades(
    params: TCompetitionsSearchParams<'registration'>,
  ): Observable<TPaginatedList<ICompetitionStade<'registration'>>>;
  getCompetitionStades<TContext>(params: TCompetitionsSearchParams<TContext>) {
    return this.http.get<TPaginatedList<ICompetitionStade<TContext>>>(
      `${this.competitionsUrl}/search/stades/`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionStade(
    competitionStadeId: ICompetitionStade['id'],
  ): Observable<ICompetitionStade>;
  getCompetitionStade(
    competitionStadeId: ICompetitionStade['id'],
    params: Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<ICompetitionStade<'registration'>>;
  getCompetitionStade(
    competitionStadeId: ICompetitionStade['id'],
    params: Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<ICompetitionStade<'results'>>;
  getCompetitionStade<TContext>(
    competitionStadeId: ICompetitionStade['id'],
    params?: Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<ICompetitionStade<TContext>>(
      `${this.competitionsUrl}/stades/${competitionStadeId}`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionPhase(
    phaseId: ICompetitionPhase['id'],
  ): Observable<ICompetitionPhaseWithStade>;
  getCompetitionPhase(
    phaseId: ICompetitionPhase['id'],
    params: Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<ICompetitionPhaseWithStade<'registration'>>;
  getCompetitionPhase(
    phaseId: ICompetitionPhase['id'],
    params: Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<ICompetitionPhaseWithStade<'results'>>;
  getCompetitionPhase<TContext>(
    phaseId: ICompetitionPhase['id'],
    params?: Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<ICompetitionPhaseWithStade<TContext>>(
      `${this.competitionsUrl}/phases/${phaseId}`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionGroup(
    groupId: ICompetitionGroup['id'],
  ): Observable<ICompetitionGroupWithPhase>;
  getCompetitionGroup(
    groupId: ICompetitionGroup['id'],
    params: Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<ICompetitionGroupWithPhase<'registration'>>;
  getCompetitionGroup(
    groupId: ICompetitionGroup['id'],
    params: Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<ICompetitionGroupWithPhase<'results'>>;
  getCompetitionGroup<TContext>(
    groupId: ICompetitionGroup['id'],
    params?: Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<ICompetitionGroupWithPhase<TContext>>(
      `${this.competitionsUrl}/groups/${groupId}`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
  ): Observable<ICompetitionGroupSession[]>;
  getCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
    params: Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<ICompetitionGroupSession<'registration'>[]>;
  getCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
    params: Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<ICompetitionGroupSession<'results'>[]>;
  getCompetitionGroupSessions<TContext>(
    groupId: ICompetitionGroup['id'],
    params?: Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<ICompetitionGroupSession<TContext>[]>(
      `${this.competitionsUrl}/groups/${groupId}/groupSessions`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getPaginatedCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
  ): Observable<TPaginatedList<ICompetitionGroupSession>>;
  getPaginatedCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
    params: TPaginationParams &
      Pick<TCompetitionsSearchParams<'registration'>, 'context[]'>,
  ): Observable<TPaginatedList<ICompetitionGroupSession<'registration'>>>;
  getPaginatedCompetitionGroupSessions(
    groupId: ICompetitionGroup['id'],
    params: TPaginationParams &
      Pick<TCompetitionsSearchParams<'results'>, 'context[]'>,
  ): Observable<TPaginatedList<ICompetitionGroupSession<'results'>>>;
  getPaginatedCompetitionGroupSessions<TContext>(
    groupId: ICompetitionGroup['id'],
    params?: TPaginationParams &
      Partial<Pick<TCompetitionsSearchParams<TContext>, 'context[]'>>,
  ) {
    return this.http.get<TPaginatedList<ICompetitionGroupSession<TContext>>>(
      `${this.competitionsUrl}/groups/${groupId}/groupSessions`,
      {
        params: { ...params, paginate: true },
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  getClubGroup(
    clubId: IClub['id'],
    params: {
      seasonId?: 'current' | 'previous' | ISeason['id'];
    } = {},
  ) {
    return this.http.get<ICompetitionGroupWithPhase>(
      `${this.competitionsUrl}/groups/club/${clubId}`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getCompetitionStadeTeams(
    competitionStadeId: ICompetitionStade['id'],
    params: TPaginationParams & {
      searchGroup?: ICompetitionGroup['migrationId'];
      searchPhase?: ICompetitionPhase['migrationId'];
      searchPlayerId?: IPerson['migrationId'];
    } = {},
  ) {
    return this.http.get<TPaginatedList<ICompetitionStadeRegisteredTeam>>(
      `${this.competitionsUrl}/search/stades/${competitionStadeId}/teams/`,
      { params },
    );
  }

  getCompetitionStadeTeamsAverageIv(
    competitionStadeId: ICompetitionStade['id'],
    params: {
      searchGroup?: ICompetitionGroup['migrationId'];
      searchPhase?: ICompetitionPhase['migrationId'];
      searchPlayerId?: IPerson['migrationId'];
    } = {},
  ) {
    return this.http.get<number>(
      `${this.competitionsUrl}/search/stades/${competitionStadeId}/teams/averageIv`,
      { params },
    );
  }

  getCompetitionStadeTeamsAverageIc(
    competitionStadeId: ICompetitionStade['id'],
    params: {
      searchGroup?: ICompetitionGroup['migrationId'];
      searchPhase?: ICompetitionPhase['migrationId'];
      searchPlayerId?: IPerson['migrationId'];
    } = {},
  ) {
    return this.http.get<number>(
      `${this.competitionsUrl}/search/stades/${competitionStadeId}/teams/averageIc`,
      { params },
    );
  }

  checkCompetitionStadePlayerRegistration(
    competitionStadeId: ICompetitionStade['id'],
    player: IPerson['id'],
  ) {
    return this.http.get(
      `${this.competitionsUrl}/stades/${competitionStadeId}/checkPlayer/${player}`,
    );
  }

  checkCompetitionStadeTeamRegistration(
    competitionStadeId: ICompetitionStade['id'],
    players: IPerson['id'][],
  ) {
    return this.http.get(
      `${this.competitionsUrl}/stades/${competitionStadeId}/checkTeam`,
      {
        params: { 'player[]': players },
      },
    );
  }

  getCompetitionStadeTeamAllowedPhase(
    competitionStadeId: ICompetitionStade['id'],
    players: IPerson['id'][],
  ) {
    return this.http.get<{
      phase: ICompetitionPhase;
      prices: ICompetitionPhase['prices'];
    }>(
      `${this.competitionsUrl}/search/stades/${competitionStadeId}/allowedPhase/`,
      {
        params: { 'player[]': players },
      },
    );
  }

  registerTeamToCompetitionStade(
    competitionStadeId: ICompetitionStade['id'],
    data: { group: ICompetitionGroup['id']; players: IPerson['id'][] },
  ) {
    return this.http.post<ICompetitionRegistrationSuccess>(
      `${this.competitionsUrl}/stades/${competitionStadeId}/registerTeam`,
      data,
    );
  }

  cancelCompetitionRegistration(data: {
    competition: number;
    group: number;
    team: number;
  }) {
    return this.http.post(
      `${this.competitionsUrl}/teams/cancelRegistration`,
      data,
    );
  }

  payForCompetitionRegistration(data: {
    failureUrl: string;
    successUrl: string;
    team: ICompetitionRegistrationSuccess['team_id'];
    teamDebt: ICompetitionRegistrationSuccess['pricingData']['payment_id'];
  }) {
    return this.http.post<IPaymentForm>(
      `${this.competitionsUrl}/teams/processPayment`,
      data,
    );
  }

  getUserCompetitionRegistrations(personId: IPerson['id']) {
    return this.http.get<ICompetitionRegistrationListItem[]>(
      `${this.personsUrl}/${personId}/teams`,
    );
  }

  getUserCompetitionRegistration(
    registrationId: ICompetitionRegistration['id'],
  ) {
    return this.http.get<ICompetitionRegistration>(
      `${this.competitionsUrl}/teams/${registrationId}`,
    );
  }
}
