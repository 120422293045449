import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IClub } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ClubsService {
  private http = inject(HttpClient);

  clubsUrl = inject(EnvironmentService).get('apiBaseUrl') + '/clubs';

  getClub(clubId: IClub['id']) {
    return this.http.get<IClub>(`${this.clubsUrl}/${clubId}`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  searchForClubs(params: { searchValue: string }) {
    return this.http.get<IClub[]>(`${this.clubsUrl}/search`, {
      params,
    });
  }
}
