import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiSvgModule } from '@taiga-ui/core';
import { TSuit } from '@lancelot-frontend/api';

@Component({
  selector: 'ffb-suit-icon',
  templateUrl: './suit-icon.component.html',
  styleUrls: ['./suit-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiSvgModule],
})
export class SuitIconComponent {
  @Input() suit!: 'NT' | TSuit;
  @Input() fittext?: boolean;

  suitIcons: Record<TSuit, string> = {
    C: 'club',
    D: 'diamond',
    H: 'heart',
    S: 'spade',
  };
}
