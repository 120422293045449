import { fakerFR as faker } from '@faker-js/faker';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { EGender, IPerson } from '../types';

export const getMockedPersons = (n = 4): IPerson[] => {
  const persons = [];

  do {
    const id = (persons.length + 1) as number;
    faker.seed(id);
    persons.push({
      id,
      user: { id },
      migrationId: id,
      valid: true,
      firstName: faker.person.firstName(),
      lastName: faker.person.lastName(),
      gender: EGender.F,
      email: faker.internet.email(),
      emailInvalid: false,
      emailVerified: true,
      disabledPlayer: false,
      avatarUrl: persons.length % 3 === 0 ? undefined : faker.image.avatar(),
      nationality: { code: TuiCountryIsoCode.FR },
    });
  } while (persons.length < n);

  return persons;
};

export const getMockedPerson = () => getMockedPersons(1)[0];
