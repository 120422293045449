<div
  tuiMode="onDark"
  class="page-header flex flex-column justify-center"
  *ffbContentAdornment="2; backgroundColor: 'dark'"
  >
  <ffb-row>
    @if (title) {
      <h1
        class="title tui-text_h1 mb3 center mx-auto"
        [ngClass]="{ 'tui-text_h2': (breakpointObserver.ltMd$ | async) === true }"
        data-test-id="page-title"
        >
        {{ title }}
      </h1>
    }
    @if (subtitle) {
      <h5
        class="subtitle tui-text_h5 center mx-auto"
        [ngClass]="{ 'tui-text_h6': (breakpointObserver.ltMd$ | async) === true }"
        data-test-id="page-subtitle"
        >
        {{ subtitle }}
      </h5>
    }
    <ng-content></ng-content>
  </ffb-row>
</div>
