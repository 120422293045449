import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Directive,
  DoCheck,
  INJECTOR,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';
import {
  PolymorpheusContent,
  PolymorpheusModule,
  PolymorpheusTemplate,
} from '@tinkoff/ng-polymorpheus';
import variant1 from './variants/1';
import variant2 from './variants/2';
import variant3 from './variants/3';
import variant4 from './variants/4';
import variant5 from './variants/5';

export const contentAdornmentVariants = [1, 2, 3, 4, 5] as const;
export type TContentAdornmentVariant =
  (typeof contentAdornmentVariants)[number];

export const contentAdornmentBackgroundColors = [
  'white',
  'light',
  'dark',
] as const;
export type TContentAdornmentBackgroundColor =
  (typeof contentAdornmentBackgroundColors)[number];

type TCard = {
  class: string;
  style: { [klass: string]: unknown };
};
type TCardsLayer = {
  background: TCard[];
  foreground: TCard[];
};
type TOrientation = 'landscape' | 'portrait';

@Directive({
  selector: '[ffbContentAdornment]',
  standalone: true,
})
export class ContentAdornmentDirective
  extends PolymorpheusTemplate<Record<string, unknown>>
  implements OnInit, OnDestroy
{
  private readonly injector = inject(INJECTOR);
  private readonly viewContainerRef = inject(ViewContainerRef);
  readonly content: TemplateRef<Record<string, unknown>> = inject(TemplateRef);

  private contentAdornmentRef: ComponentRef<ContentAdornmentComponent> | null =
    null;

  @Input('ffbContentAdornment')
  variant: TContentAdornmentVariant = 1;

  @Input('ffbContentAdornmentBackgroundColor')
  backgroundColor: TContentAdornmentBackgroundColor = 'dark';

  ngOnInit() {
    if (this.contentAdornmentRef !== null) {
      return;
    }

    this.contentAdornmentRef = this.viewContainerRef.createComponent(
      ContentAdornmentComponent,
      { injector: this.injector },
    );
    this.contentAdornmentRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.contentAdornmentRef === null) {
      return;
    }

    this.viewContainerRef.clear();
    this.contentAdornmentRef = null;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[ffbContentAdornment]',
  templateUrl: './content-adornment.component.html',
  styleUrls: ['./content-adornment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ResizeObserverModule, NgStyle, PolymorpheusModule],
})
export class ContentAdornmentComponent implements DoCheck {
  private readonly directive = inject(ContentAdornmentDirective);

  content: PolymorpheusContent;
  variant: TContentAdornmentVariant;
  backgroundColor: TContentAdornmentBackgroundColor;
  cards?: TCardsLayer;
  orientation?: TOrientation;

  private variants: Record<
    TContentAdornmentVariant,
    Record<TOrientation, TCardsLayer> & { breakpoint?: number }
  > = {
    1: variant1,
    2: variant2,
    3: variant3,
    4: variant4,
    5: variant5,
  };

  constructor() {
    this.content = this.directive.content;
    this.variant = this.directive.variant;
    this.backgroundColor = this.directive.backgroundColor;
  }

  ngDoCheck() {
    this.directive.check();
  }

  onResize(entries: ResizeObserverEntry[]) {
    if (entries[0]) {
      this.orientation =
        entries[0].contentRect.width >
          (this.variants[this.variant]?.breakpoint || 600) ||
        entries[0].contentRect.width > entries[0].contentRect.height
          ? 'landscape'
          : 'portrait';

      this.cards = this.variants[this.variant][this.orientation];
    }
  }
}
