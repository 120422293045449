import { ClipboardModule } from '@angular/cdk/clipboard';
import { AsyncPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiHintModule } from '@taiga-ui/core';
import { TUI_COPY_TEXTS } from '@taiga-ui/kit/tokens';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import {
  Observable,
  Subject,
  map,
  merge,
  of,
  startWith,
  switchMap,
  timer,
} from 'rxjs';

@Component({
  selector: 'ffb-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ClipboardModule,
    PolymorpheusModule,
    TuiButtonModule,
    TuiHintModule,
  ],
})
export class CopyButtonComponent {
  private readonly _copyTexts$ = inject(TUI_COPY_TEXTS);

  @Input() value? = '';

  protected readonly copy$ = new Subject<void>();

  @tuiPure
  get hintText$(): Observable<PolymorpheusContent> {
    return this._copyTexts$.pipe(
      switchMap((texts) =>
        this.copy$.pipe(
          switchMap(() =>
            merge(of(texts[1]), timer(3000).pipe(map(() => texts[0]))),
          ),
          startWith(texts[0]),
        ),
      ),
    );
  }
}
