import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IFestival } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FestivalsService {
  private http = inject(HttpClient);

  festivalsUrl =
    inject(EnvironmentService).get('apiBaseUrl') + '/competitions/festivals';

  getFestival(festivalId: IFestival['id']) {
    return this.http.get<IFestival>(`${this.festivalsUrl}/${festivalId}`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  searchForFestivals(params: { searchValue: string }) {
    return this.http.get<IFestival[]>(`${this.festivalsUrl}/search`, {
      params,
    });
  }
}
