import { b as i } from "./chunk-LSZKOVGW.mjs";
import { j as a, k as e } from "./chunk-GTEBSQTL.mjs";
var o = ["082 ### ####", "084 ### ####", "083 ### ####", "065 ### ####", "082#######", "082 #######"];
var B = {
    formats: o
  },
  n = B;
var t = ["Pty Ltd", "Ltd", "CC"];
var L = {
    suffix: t
  },
  l = L;
var u = ["co.za", "com", "org.za", "info", "net.za"];
var _ = {
    domain_suffix: u
  },
  h = _;
var m = ["Polokwane", "eGoli", "Pretoria", "uTshwane", "eThekwini", "umGungundlovu", "Mbombela", "eKapa", "Stellenbosch", "iBhayi", "eMonti", "Kimberley", "Rustenburg", "Bloemfontein"];
var r = ["{{location.city_name}}"];
var s = ["South Africa"];
var p = ["#####", "####"];
var f = ["EGoli", "IFuleyisitata", "IKwaZulu-Natali", "ILimpopo", "IMpumalanga Kapa", "IMpumalanga", "INtshonalanga Kapa", "INyakatho Kapa", "INyakatho-Ntshonalanga"];
var d = ["{{person.firstName}} {{location.street_suffix}}", "{{person.lastName}} {{location.street_suffix}}"];
var D = {
    city_name: m,
    city_pattern: r,
    default_country: s,
    postcode: p,
    state: f,
    street_pattern: d
  },
  z = D;
var T = {
    title: "Zulu (South Africa)",
    code: "zu_ZA",
    country: "ZA",
    language: "zu",
    endonym: "isiZulu (Iningizimu Afrika)",
    dir: "ltr",
    script: "Latn"
  },
  b = T;
var N = ["Amahle", "Ayanda", "Ayize", "Buhle", "Busisiwe", "Duduzile", "Hlengiwe", "Inyoni", "Isisa", "Jabulile", "Khanyisile", "Khethiwe", "Khulekani", "Lerato", "Liyana", "Lukhona", "Mbalienhle", "Mhambi", "Mthunzi", "Nandi", "Ndondoloza", "Nhlakanipho", "Nkosingiphile", "Nofoto", "Nokuthula", "Nolwazi", "Nomvula", "Nonhlanhla", "Nonjabulo", "Nonkululeko", "Nozipho", "Ntokozo", "Ntombizodwa", "Samukelisiwe", "Sibongile", "Sindisiwe", "Sinenhlanhla", "Siphephelo", "Sizakele", "Sizani", "Thabisa", "Thadie", "Thobeka", "Thulisile", "Uluthando", "Ulwazi", "Yibanathi", "Zanele", "Zobuhle"];
var k = ["Amahle", "Anele", "Ayanda", "Ayize", "Bafana", "Bangizwe", "Bhekimuzi", "Bhekizizwe", "Bhekumbuso", "Bonginkosi", "Buhle", "Busisiwe", "Duduzile", "Dumisani", "Fanyana", "Funani", "Gatsha", "Hlengiwe", "Inyoni", "Isisa", "Jabulile", "Kagiso", "Kgabu", "Khanyisile", "Khethiwe", "Khulekani", "Langa", "Lerato", "Linda", "Liyana", "Lukhona", "Lungelo", "Lwandle", "Mandla", "Maphikelela", "Mbalienhle", "Mhambi", "Mpilo", "Mpumelelo", "Msizi", "Mthunzi", "Musawenkosi", "Nandi", "Ndleleni", "Ndondoloza", "Nhlakanipho", "Nkanyezi", "Nkosenye", "Nkosingiphile", "Nkosinhle", "Nkosiyabo", "Nofoto", "Nokuthula", "Nolwazi", "Nomvula", "Nonhlanhla", "Nonjabulo", "Nonkululeko", "Nozipho", "Ntokozo", "Ntombizodwa", "Phila", "Philani", "Phiwokwakhe", "Samukelisiwe", "Sanele", "Sfiso", "Shaka", "Sibongile", "Sibusiso", "Sifiso", "Sindisiwe", "Sinenhlanhla", "Siphephelo", "Siphiwe", "Sipho", "Sithembiso", "Siyanda", "Sizakele", "Sizani", "Solomon", "Thabisa", "Thadie", "Thamsanqa", "Thando", "Themba", "Thobeka", "Thulani", "Thulisile", "Uluthando", "Ulwazi", "Velaphi", "Vusumuzi", "Yibanathi", "Zanele", "Zenzele", "Zithulele", "Zobuhle"];
var y = ["Bayeni", "Bengu", "Bhembe", "Bhengani", "Bhengu", "Bhuyeni", "Biyela", "Buthelezi", "Cebekhulu", "Cele", "Chiliza", "Delazy", "Dhlomo", "Dingiswayo", "Dlamini", "Dube", "Fuze", "Gabhezi", "Gama", "Gigaba", "Guliwe", "Gumede", "Gwacela", "Gwala", "Hlongwa", "Khawula", "Khumalo", "Khuzwayo", "Kunene", "Lamula", "Lembede", "Luthuli", "Mabhida", "Mabizela", "Maduma", "Magoza", "Malinga", "Maphisa", "Mashinini", "Mavundla", "Mbatha", "Mbende", "Mbuso", "Mehloluhlaza", "Mfeka", "Mfumu", "Mgenge", "Mkhatshwa", "Moseley", "Mqwebu", "Msibi", "Mthembu", "Mthethwa", "Mtolo", "Musi", "Ncusi", "Ndandali", "Ndebele", "Ngcolosi", "Ngema", "Ngubane", "Ngwazi", "Nhleko", "Nomvethe", "Nondlela", "Nonyana", "Nozulu", "Nsele", "Ntanzi", "Ntombela", "Nyanda", "Nzimande", "Radebe", "Seme", "Senzangakhona", "Shezi", "Sibiya", "Sithuli", "Siwele", "Siyaya", "Sokhela", "Sondisa", "Sothole", "Thoyana", "Thumbeza", "Thusi", "Tshabalala", "Vezi", "Vilakazi", "Wosiyane", "Yengwa", "Zondi", "Zondo", "Zubane", "Zuma", "Zungu"];
var w = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var M = ["Bonginkosi", "Kagiso", "Bhekizizwe", "Bhekumbuso", "Shaka", "Funani", "Kgabu", "Solomon", "Gatsha", "Langa", "Phila", "Msizi", "Nkosiyabo", "Linda", "Mpilo", "Siyanda", "Nkanyezi", "Bafana", "Lwandle", "Sfiso", "Thulani", "Thando", "Sanele", "Anele", "Lungelo", "Dumisani", "Bangizwe", "Fanyana", "Bhekimuzi", "Mandla", "Maphikelela", "Mpumelelo", "Mthunzi", "Philani", "Musawenkosi", "Nkosenye", "Nkosinhle", "Phiwokwakhe", "Sifiso", "Zithulele", "Sithembiso", "Sipho", "Siphiwe", "Sibusiso", "Velaphi", "Thamsanqa", "Vusumuzi", "Themba", "Zenzele", "Ndleleni"];
var K = {
    female_first_name: N,
    first_name: k,
    last_name: y,
    last_name_pattern: w,
    male_first_name: M
  },
  S = K;
var g = ["01# ### #####", "02# ### #####", "03# ### #####", "04# ### #####", "05# ### #####", "0800 ### ###", "0860 ### ###", "01#########", "01# ########"];
var Z = {
    formats: g
  },
  c = Z;
var A = {
    cell_phone: n,
    company: l,
    internet: h,
    location: z,
    metadata: b,
    person: S,
    phone_number: c
  },
  x = A;
var Da = new a({
  locale: [x, i, e]
});
export { x as a, Da as b };