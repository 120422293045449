import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import { IGameScore } from '@lancelot-frontend/api';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { ContractComponent } from '../contract/contract.component';
import { OpeningLeadComponent } from '../opening-lead/opening-lead.component';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'ffb-teams-table-scores',
  templateUrl: './teams-table-scores.component.html',
  styleUrls: ['./teams-table-scores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    TranslocoDirective,
    TuiLetModule,
    TuiButtonModule,
    ContractComponent,
    OpeningLeadComponent,
    TableComponent,
  ],
})
export class TeamsTableScoresComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() gameScores: IGameScore[] = [];
  @Input() selectedRowHandler?: (row: IGameScore) => boolean;
  @Input() loading?: boolean;
  @Input() getRowLink?: (
    row: IGameScore,
    index: number,
  ) => [
    commands: (number | string)[] | number | string,
    navigationExtras?: NavigationExtras,
  ];

  getCellData = (board: IGameScore, column: string) => {
    switch (column) {
      case 'nsScore': {
        if (board.contract.toUpperCase() === 'PASS') {
          return '';
        }

        return board.nsScore || `-${board.ewScore}`;
      }
      case 'nsNote': {
        return board.nsScore !== 'Bye' ? board.nsNote : undefined;
      }
      default: {
        return board[column as keyof IGameScore];
      }
    }
  };
}
