import { Pipe, PipeTransform } from '@angular/core';
import { stringifyPersonInformation } from '../utils';

@Pipe({
  name: 'personInformation',
  standalone: true,
})
export class PersonInformationPipe implements PipeTransform {
  transform(
    ...params: Parameters<typeof stringifyPersonInformation>
  ): ReturnType<typeof stringifyPersonInformation> {
    return stringifyPersonInformation(...params);
  }
}
