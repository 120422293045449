<ng-container *transloco="let t">
  @if (suit !== 'NT') {
    <tui-svg
      src="/assets/icon-set/{{ suitIcons[suit] }}.svg"
      [class.fit-text]="fittext"
    ></tui-svg>
  }
  @else {
    <span class="color-black">
      {{ t('components.noTrump.short') }}
    </span>
  }
</ng-container>
