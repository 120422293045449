const landscape = {
  background: [
    {
      style: {
        height: '20%',
        top: 0,
        left: 0,
        transform: 'translate(15%, 15%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        left: 0,
        transform: 'translate(-15%, 33%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '30%',
        top: 0,
        right: '50%',
        transform: 'translate(33%, -50%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '80%',
        top: 0,
        left: 0,
        transform: 'translate(-80%, -50%)',
      },
      class: 'bg-yellow',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        left: 0,
        transform: 'translate(-75%, 70%)',
      },
      class: 'bg-purple',
    },
  ],
  foreground: [
    {
      style: {
        height: '15%',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 50%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        right: 0,
        transform: 'translate(60%, 80%)',
      },
      class: 'bg-orange',
    },
  ],
};

const portrait = {
  background: [
    {
      style: {
        width: '40%',
        bottom: 0,
        left: 0,
        transform: 'translate(-25%, 20%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        width: '25%',
        top: '56.25vw',
        right: 0,
        transform: 'translate(-10%, 10%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        width: '75%',
        bottom: 0,
        right: 0,
        transform: 'translate(50%, 75%)',
      },
      class: 'bg-yellow',
    },
  ],
  foreground: [
    {
      style: {
        width: '15%',
        top: '56.25vw',
        left: '55%',
        transform: 'translateY(-50%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        width: '30%',
        top: 0,
        right: 0,
        transform: 'translate(60%, -40%)',
      },
      class: 'bg-orange',
    },
    {
      style: {
        width: '30%',
        top: 0,
        left: 0,
        transform: 'translate(-60%, -60%)',
      },
      class: 'bg-purple',
    },
  ],
};

export default {
  landscape,
  portrait,
  breakpoint: 960,
};
