<ng-container *transloco="let t">
  <ng-template #emptyTemplate>
    <p class="center italic my2">
      {{ t('components.frequencies.noData') }}
    </p>
  </ng-template>

  <ng-template #noteTemplate let-cellData="cellData">
    {{ cellData | decimalWithUnit: {}:(scoringType === 'percent' ? '%' : '') }}
  </ng-template>

  <ffb-table
    name="frequencies-table"
    [data]="frequencies"
    [columns]="['nsScore', 'ewScore', 'nsNote', 'ewNote', 'count']"
    [columnHeaders]="[
      [
        {
          key: 'nsScore',
          label:
            t('components.cardinalPoints.N.short') +
            t('components.cardinalPoints.S.short')
        },
        {
          key: 'ewScore',
          label:
            t('components.cardinalPoints.E.short') +
            t('components.cardinalPoints.W.short')
        },
        {
          key: 'nsNote',
          label:
            t('components.cardinalPoints.N.short') +
            t('components.cardinalPoints.S.short')
        },
        {
          key: 'ewNote',
          label:
            t('components.cardinalPoints.E.short') +
            t('components.cardinalPoints.W.short')
        },
        {
          key: 'count',
          label: '#'
        }
      ]
    ]"
    [rowCellTemplates]="{
      ewNote: noteTemplate,
      nsNote: noteTemplate
    }"
    [layout]="{
      nsScore: { align: 'right' },
      ewScore: { align: 'right' },
      nsNote: { align: 'right' },
      ewNote: { align: 'right' },
      count: { align: 'right' }
    }"
    [emptyTemplate]="emptyTemplate"
    [selectedRowHandler]="selectedRowHandler"
  >
  </ffb-table>
</ng-container>
