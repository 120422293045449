<ng-container *transloco="let t">
  <ng-container *tuiLet="breakpointObserver.ltMd$ | async as ltMd">
    <ng-template #roomTemplate let-cellData="cellData">
      {{ t('components.room.' + cellData + '.short') }}
    </ng-template>

    <ng-template
      #contractTemplate
      let-cellData="cellData"
      let-rowData="rowData"
      >
      @if (cellData && cellData !== '-') {
        <ffb-contract [contract]="cellData"></ffb-contract>
      }
      @if (ltMd && cellData && cellData.toUpperCase() !== 'PASS') {
        {{ t('components.cardinalPoints.' + rowData.declarer + '.short') }}
        {{ rowData.result }}
      }
    </ng-template>

    <ng-template #declarerTemplate let-cellData="cellData">
      @if (cellData && cellData !== '-') {
        {{ t('components.cardinalPoints.' + cellData + '.short') }}
      }
    </ng-template>

    <ng-template #leadTemplate let-cellData="cellData">
      @if (cellData && cellData !== '-') {
        <ffb-opening-lead [openingLead]="cellData"></ffb-opening-lead>
      } @else {
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
      }
    </ng-template>

    <ng-template #scoreTemplate let-cellData="cellData">
      @if (cellData !== '') {
        @if (ltMd || cellData[0] !== '-') {
          {{ cellData }}
        }
      } @else {
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
      }
    </ng-template>

    <ng-template #placeholder>
      <span class="color-text-03">—</span>
    </ng-template>

    <ng-template #rowHeaderTemplate let-rowData="rowData">
      @if (rowData.lineup.room === 'O') {
        <div class="bg-base-03 p1">
          @if (rowData.lineup.segment.game.tableNumber) {
            <div class="full-width center">
              {{ t('components.table') }}
              {{ rowData.lineup.segment.game.tableNumber }}
            </div>
          }
          <div class="full-width flex flex-row bold">
            <div class="flex-0 center">
              {{ getCellData(rowData, 'homeTeam') }}
              @if (rowData.nsNote !== 0) {
                <span class="color-primary ml1">
                  @if (rowData.nsNote > 0) {
                    +
                    }{{ rowData.nsNote }}
                  </span>
                }
              </div>
              <div class="center">vs</div>
              <div class="flex-0 center">
                {{ getCellData(rowData, 'awayTeam') }}
                @if (rowData.nsNote !== 0) {
                  <span class="color-primary ml1">
                    @if (rowData.ewNote > 0) {
                      +
                      }{{ rowData.ewNote }}
                    </span>
                  }
                </div>
              </div>
            </div>
          }
        </ng-template>

        <ffb-table
          name="teams-board-scores-table"
          [loading]="loading"
          [data]="boardScores"
          [getCellData]="getCellData"
      [columns]="
        ltMd
          ? ['room', 'contract', 'lead', 'nsScore']
          : [
              'table',
              'homeTeam',
              'awayTeam',
              'room',
              'contract',
              'declarer',
              'lead',
              'result',
              'nsScore',
              'ewScore',
              'IMP.homeTeam',
              'IMP.awayTeam'
            ]
      "
      [columnHeaders]="
        ltMd
          ? [
              [
                { key: 'room', label: t('components.room') },
                { key: 'contract', label: t('components.contract.short') },
                { key: 'lead', label: t('components.openingLead.short') },
                {
                  key: 'nsScore',
                  label:
                    t('components.score') +
                    ' ' +
                    t('components.orientation.NS.short')
                }
              ]
            ]
          : [
              [
                { key: 'table', label: t('components.table') },
                { key: 'homeTeam', label: t('components.homeTeam') },
                { key: 'awayTeam', label: t('components.awayTeam') },
                { key: 'room', label: t('components.room') },
                { key: 'contract', label: t('components.contract.short') },
                { key: 'declarer', label: t('components.declarer.short') },
                { key: 'lead', label: t('components.openingLead.short') },
                { key: 'result', label: t('components.result.short') },
                { key: 'nsScore', label: t('components.orientation.NS.short') },
                { key: 'ewScore', label: t('components.orientation.EW.short') },
                { key: 'IMP.homeTeam', label: t('components.homeTeam.short') },
                { key: 'IMP.awayTeam', label: t('components.awayTeam.short') }
              ]
            ]
      "
      [rowCellTemplates]="{
        room: roomTemplate,
        contract: contractTemplate,
        declarer: declarerTemplate,
        lead: leadTemplate,
        nsScore: scoreTemplate
      }"
        [rowHeaderTemplate]="ltMd ? rowHeaderTemplate : undefined"
      [layout]="{
        table: {
          cell: {
            rowSpan: 2
          }
        },
        homeTeam: {
          cell: {
            rowSpan: 2
          }
        },
        awayTeam: {
          cell: {
            rowSpan: 2,
            borders: {
              right: true
            }
          }
        },
        room: { align: 'center' },
        contract: { align: 'center' },
        declarer: { align: 'center' },
        lead: { align: 'center' },
        result: { align: 'center' },
        nsScore: { align: 'center' },
        ewScore: { align: 'center' },
        'IMP.homeTeam': {
          align: 'center',
          cell: {
            rowSpan: 2,
            borders: {
              left: true
            }
          }
        },
        'IMP.awayTeam': {
          align: 'center',
          cell: {
            rowSpan: 2
          }
        }
      }"
[selectedRowHandler]="selectedRowHandler"
[actions]="actions"
(clickOnRow)="clickOnRow.emit($event)"
>
</ffb-table>
</ng-container>
</ng-container>
