<button
  tuiIconButton
  icon="tuiIconCopy"
  size="xs"
  appearance="icon"
  [disabled]="!value"
  [tuiHint]="hint"
  [tuiHintShowDelay]="0"
  [cdkCopyToClipboard]="value"
  (cdkCopyToClipboardCopied)="copy$.next()"
>
</button>

<ng-template #hint>
  <ng-container *polymorpheusOutlet="hintText$ | async as text">
    {{ text }}
  </ng-container>
</ng-template>
