import { b as t } from "./chunk-LSZKOVGW.mjs";
import { j as e, k as o } from "./chunk-GTEBSQTL.mjs";
var n = ["Boot", "I", "Nu", "Nur", "Tu", "Um", "a", "becoose-a", "boot", "bork", "burn", "chuuses", "cumplete-a", "cun", "cunseqooences", "curcoomstunces", "dee", "deeslikes", "denuoonceeng", "desures", "du", "eccuoont", "ectooel", "edfuntege-a", "efueeds", "egeeen", "ell", "ere-a", "feend", "foolt", "frum", "geefe-a", "gesh", "greet", "heem", "heppeeness", "hes", "hoo", "hoomun", "idea", "ifer", "in", "incuoonter", "injuy", "itselff", "ixcept", "ixemple-a", "ixerceese-a", "ixpleeen", "ixplurer", "ixpuoond", "ixtremely", "knoo", "lebureeuoos", "lufes", "meestekee", "mester-booeelder", "moost", "mun", "nu", "nut", "oobteeen", "oocceseeunelly", "ooccoor", "ooff", "oone-a", "oor", "peeen", "peeenffool", "physeecel", "pleesoore-a", "poorsooe-a", "poorsooes", "preeesing", "prucoore-a", "prudooces", "reeght", "reshunelly", "resooltunt", "sume-a", "teecheengs", "teke-a", "thees", "thet", "thuse-a", "treefiel", "troot", "tu", "tueel", "und", "undertekes", "unnuyeeng", "uny", "unyune-a", "us", "veell", "veet", "ves", "vheech", "vhu", "yuoo", "zee", "zeere-a"];
var l = {
    words: n
  },
  r = l;
var m = {
    title: "English (Bork)",
    code: "en_BORK",
    variant: "BORK",
    language: "en",
    endonym: "English (Bork)",
    dir: "ltr",
    script: "Latn"
  },
  i = m;
var s = [{
  value: "{{person.last_name}}",
  weight: 95
}, {
  value: "{{person.last_name}}-{{person.last_name}}",
  weight: 5
}];
var p = {
    last_name_pattern: s
  },
  a = p;
var f = {
    lorem: r,
    metadata: i,
    person: a
  },
  u = f;
var O = new e({
  locale: [u, t, o]
});
export { u as a, O as b };