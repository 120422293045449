import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TLineup, getMockedPerson } from '@lancelot-frontend/api';

const playerPlaceholder = getMockedPerson();

@Component({
  selector: 'ffb-lineup',
  templateUrl: './lineup.component.html',
  styleUrls: ['./lineup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, NgTemplateOutlet, UpperCasePipe],
})
export class LineupComponent {
  @Input() lineup?: TLineup;
  playerPlaceholder = playerPlaceholder;
}
