import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiErrorModule, TuiModeModule } from '@taiga-ui/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'ffb-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    PolymorpheusModule,
    TuiErrorModule,
    TuiButtonModule,
    TuiModeModule,
  ],
})
export class ErrorComponent {
  @Input() action?: 'reload' | 'retry' | null = 'reload';
  @Input() error?: PolymorpheusContent;

  reload() {
    window.location.reload();
  }
}
