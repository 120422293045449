<ffb-section
  [title]="titleTemplate"
  [actions]="actions"
  class="border-base-03 border-radius-m overflow-hidden"
>
  <ng-template #titleTemplate>
    <div class="px2 pt2">
      <ng-container *polymorpheusOutlet="title as text">
        @if ((text | typeof) === 'string') {
          <p class="tui-text_body-m color-black">{{ text }}</p>
        } @else {
          {{ text }}
        }
      </ng-container>
    </div>
  </ng-template>

  <div class="px1 mtn1">
    <div class="bg-base-02 bordered p2 mtn1 mxn1">
      <tui-elastic-container>
        <ng-content></ng-content>
      </tui-elastic-container>
    </div>
  </div>
</ffb-section>
