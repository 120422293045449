const landscape = {
  background: [
    {
      style: {
        height: '45%',
        top: 0,
        right: 0,
        transform: 'translate(40%, -40%)',
      },
      class: 'bg-pink',
    },
    {
      style: {
        height: '45%',
        bottom: 0,
        right: '50%',
        transform: 'translate(10%, 40%)',
      },
      class: 'bg-yellow',
    },
    {
      style: {
        height: '22.5%',
        bottom: 0,
        left: '50%',
        transform: 'translate(-15%, 30%)',
      },
      class: 'bg-purple',
    },
    {
      style: {
        height: '15%',
        top: 0,
        left: '70%',
        transform: 'translate(0, -40%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        height: '22.5%',
        top: 0,
        left: 0,
        transform: 'translate(-50%, 30%)',
      },
      class: 'bg-orange',
    },
  ],
  foreground: [],
};

const portrait = {
  background: [
    {
      style: {
        width: '30%',
        top: 0,
        right: 0,
        transform: 'translate(50%, -40%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        width: '90%',
        bottom: 0,
        left: 0,
        transform: 'translate(-80%, -20%)',
      },
      class: 'bg-yellow',
    },
    {
      style: {
        width: '60%',
        bottom: 0,
        left: 0,
        transform: 'translate(-30%, -150%)',
      },
      class: 'bg-purple',
    },
  ],
  foreground: [],
};

export default {
  landscape,
  portrait,
};
