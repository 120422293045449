@if (!isPlatformServer) {
  <tui-line-clamp
    [lineHeight]="lineHeight"
    [linesLimit]="linesLimit"
    [content]="content"
  ></tui-line-clamp>
}
@else {
  <div>
    <ng-container *polymorpheusOutlet="content as text">
      {{ text }}
    </ng-container>
  </div>
}
