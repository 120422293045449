<ng-container *transloco="let t">
  @if (!isNativePlatform) {
    <div class="wrapper flex flex-row justify-center items-center pl2 pr1">
      <span>
        {{ t('actions.share') }}
      </span>
      @for (platform of availablePlatforms; track platform) {
        <button
          tuiIconButton
          type="button"
          appearance="icon"
          tuiMode="onLight"
          size="s"
          class="ml1"
          (click)="shareWith(platform)"
          >
          <tui-svg src="/assets/icon-set/{{ platform }}.svg"></tui-svg>
        </button>
      }
    </div>
  }
  @else {
    <button
      tuiButton
      appearance="whiteblock"
      shape="rounded"
      size="m"
      class="native-platform-share-button"
      (click)="nativeShare()"
      >
      <tui-svg class="mr2" src="/assets/icon-set/share.svg"></tui-svg>
      {{ t('actions.share') }}
    </button>
  }
</ng-container>
