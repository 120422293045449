import {
  EGender,
  IMigratedItem,
  TAddress,
  TCountryIsoCode,
  TPhone,
} from './common.types';
import { ISeason } from './competitions.types';
import { IClub, ICommittee } from './organizations.types';

export const IVs = [
  100, 96, 92, 88, 84, 80, 76, 72, 68, 64, 60, 58, 56, 52, 50, 48, 44, 42, 40,
  38, 36, 34, 32, 31, 30, 28, 26, 24, 22, 20,
] as const;

export type TIV = (typeof IVs)[number];

type TPersonRanking = {
  ic: {
    id: number;
  };
  iv: TIV;
  pe: number;
  pec: number;
  pp: number;
  ppc: number;
};

/**
 * Model definition for person
 */
export interface IPerson extends IMigratedItem {
  address?: TAddress;
  avatarUrl?: string;
  bboPseudo?: null | string;
  birthdate?: string;
  club?: Pick<IClub, 'ffbCode' | 'id' | 'label' | 'migrationId' | 'type'>;
  committee?: Pick<
    ICommittee,
    'ffbCode' | 'id' | 'label' | 'labelArticle' | 'migrationId' | 'type'
  >;
  deathSeason?: ISeason;
  disabledPlayer: boolean;
  email: string;
  emailInvalid: boolean;
  emailVerified: boolean;
  ffbId?: number;
  firstName: string;
  funbridgePseudo?: null | string;
  funbridgeToken?: null | string;
  gender: EGender;
  lastName: string;
  level?: 0 | 1 | 2 | 3;
  licence?: boolean;
  mathLab?: boolean;
  nationality: {
    code: TCountryIsoCode;
  };
  phone1?: TPhone;
  previousRanking?: TPersonRanking;
  ranking?: TPersonRanking;
  realEmail?: string;
  student?: boolean;
  teacher?: boolean;
  user?: { id: IUser['id'] };
  valid: boolean;
}

/**
 * Model definition for user
 */
export interface IUser {
  cmsToken?: string;
  firebaseUid: string;
  id: number;
  person?: { id: IPerson['id'] };
  roles: (
    | 'ROLE_ADMIN'
    | 'ROLE_BOARDLIBRARY_ADMIN'
    | 'ROLE_BOARDLIBRARY_QUALIF'
    | 'ROLE_BOARDLIBRARY_USER'
    | 'ROLE_CMS_AUTHOR'
    | 'ROLE_HELPDESK'
    | 'ROLE_PLAYER'
    | 'ROLE_PRO'
    | 'ROLE_STUDENT'
    | 'ROLE_TEACHER'
    | 'ROLE_TECH_REALEMAILBYFFBID'
    | 'ROLE_TECH_RESULTIMPORT'
    | 'ROLE_TOOLS'
    | 'ROLE_USER'
  )[];
  token?: string;
  tokenExpiredAt?: string;
}

export interface IMe extends IUser {
  person: Pick<
    IPerson,
    | 'avatarUrl'
    | 'email'
    | 'emailInvalid'
    | 'emailVerified'
    | 'firstName'
    | 'id'
    | 'lastName'
    | 'valid'
  >;
}

export enum AccountErrorCode {
  'accountExistingWithAnotherEmail' = 1,
  'accountInitiatedWithAnotherEmail',
  'accountExisting',
  'ffbIdUnknown',
  'emailAlreadyInUse',
  'multipleExistingAccounts',
}

export interface IAccountError {
  clubLabel: null | string;
  code: 0 | AccountErrorCode;
  email: null | string;
  student: boolean | null;
}
