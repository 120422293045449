import { Component, Input } from '@angular/core';
import { TuiModeModule } from '@taiga-ui/core';
import { TuiElasticContainerModule } from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { TypeofPipe } from '@lancelot-frontend/core';
import { SectionComponent } from '../section/section.component';

@Component({
  selector: 'ffb-sub-section',
  templateUrl: './sub-section.component.html',
  styleUrls: ['./sub-section.component.scss'],
  standalone: true,
  imports: [
    PolymorpheusModule,
    TuiElasticContainerModule,
    TuiModeModule,
    SectionComponent,
    TypeofPipe,
  ],
})
export class SubSectionComponent {
  @Input() title?: PolymorpheusContent;
  @Input() actions?: PolymorpheusContent;
}
