<ng-container *transloco="let t">
  <h3 class="tui-form__header tui-form__header_margin-top_none">
    {{ confirmationTitle || t('components.confirmationDialog.title') }}
  </h3>
  <p>
    {{
    confirmationMessage ||
    t('components.confirmationDialog.confirmationMessage')
    }}
  </p>

  @if (typeToConfirmValue) {
    <div class="mt2">
      <p class="mb1">
        {{
        typeToConfirmMessage ||
        t('components.confirmationDialog.typeToConfirmMessage')
        }}
      </p>
      <tui-primitive-textfield [(value)]="typeToConfirmInputValue">
        {{ typeToConfirmValue }}
      </tui-primitive-textfield>
    </div>
  }

  <div class="tui-form__buttons tui-form__buttons_align_end">
    <button
      tuiButton
      type="button"
      class="tui-form__button"
      appearance="icon"
      (click)="cancel()"
      data-test-id="confirmation-dialog-cancel-button"
      >
      {{ t('components.confirmationDialog.actions.cancel') }}
    </button>
    <button
      tuiButton
      type="button"
      class="tui-form__button"
      appearance="secondary-destructive"
      (click)="confirm()"
      [disabled]="
        !!(
          typeToConfirmValue &&
          typeToConfirmInputValue.toLowerCase() !==
            typeToConfirmValue.toLowerCase()
        )
      "
      data-test-id="confirmation-dialog-confirm-button"
      >
      {{
      confirmationButtonLabel ||
      t('components.confirmationDialog.actions.confirm')
      }}
    </button>
  </div>
</ng-container>
