import type { PartialDeep } from 'type-fest';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  AccountErrorCode,
  IAccountError,
  IOrganization,
  IPerson,
  IPersonSchoolGroup,
  IPersonSchoolStudent,
  ISeason,
  ITeacherClub,
  TPaginatedList,
  TPaginationParams,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class PersonsService {
  private http = inject(HttpClient);

  apiUrl = inject(EnvironmentService).get('apiBaseUrl');
  personsUrl = this.apiUrl + '/persons';
  signedInPersonUrl = `${this.personsUrl}/me`;

  getSignedInPerson() {
    return this.http.get<IPerson>(`${this.signedInPersonUrl}/`);
  }

  updateSignedInPerson(data: PartialDeep<IPerson>) {
    return this.http.post<IPerson>(this.signedInPersonUrl, data);
  }

  updateSignedInPersonEmail(data: { newEmail: IPerson['email'] }) {
    return this.http.post<IPerson>(
      `${this.signedInPersonUrl}/change-email`,
      data,
    );
  }

  updateSignedInPersonAvatar(file: Blob) {
    const formData = new FormData();

    formData.append('avatarFile', file);

    return this.http.post<IPerson>(
      `${this.signedInPersonUrl}/avatar`,
      formData,
    );
  }

  deleteSignedInPersonAvatar() {
    return this.http.delete<void>(`${this.signedInPersonUrl}/avatar`);
  }

  setSignedInPersonAsTeacher() {
    return this.http.post<IPerson>(`${this.signedInPersonUrl}/setTeacher`, {});
  }

  setSignedInPersonAsStudent() {
    return this.http.post<IPerson>(`${this.signedInPersonUrl}/setStudent`, {});
  }

  getSignedInTeacherSchoolGroups(
    params: {
      seasonId?: 'current' | 'previous' | ISeason['id'];
    } = {},
  ) {
    return this.http.get<IPersonSchoolGroup[]>(
      `${this.signedInPersonUrl}/schoolGroups`,
      { params },
    );
  }

  getSignedInTeacherClubs() {
    return this.http.get<ITeacherClub[]>(
      `${this.signedInPersonUrl}/teacher-clubs`,
    );
  }

  getSignedInStudentSchoolGroups(
    params: {
      seasonId?: 'current' | 'previous' | ISeason['id'];
    } = {},
  ) {
    return this.http.get<IPersonSchoolStudent[]>(
      `${this.signedInPersonUrl}/schoolStudents`,
      { params },
    );
  }

  searchForPersons(
    params: TPaginationParams & {
      searchEmail?: IPerson['email'];
      'searchExcludedIds[]'?: IPerson['id'][];
      searchFfbCode?: IOrganization['ffbCode'];
      searchFfbId?: IPerson['ffbId'];
      searchName?: string;
      searchTeacher?: 0 | 1 | boolean;
    },
  ) {
    if (typeof params.searchTeacher === 'boolean') {
      params.searchTeacher = params.searchTeacher ? 1 : 0;
    }

    return this.http.get<TPaginatedList<IPerson>>(`${this.personsUrl}/search`, {
      params,
    });
  }

  getPerson(personId: IPerson['id']) {
    return this.http.get<IPerson>(`${this.personsUrl}/${personId}`);
  }

  updatePerson(personId: IPerson['id'], data: PartialDeep<IPerson>) {
    return this.http.post<IPerson>(`${this.personsUrl}/${personId}`, data);
  }

  updateEmail(personId: IPerson['id'], data: { newEmail: IPerson['email'] }) {
    return this.http.post<IPerson>(
      `${this.personsUrl}/${personId}/change-email`,
      data,
    );
  }

  sendAccountCreationEmail(personId: IPerson['id']) {
    return this.http.post(
      `${this.personsUrl}/${personId}/sendAccountCreationEmail`,
      {},
    );
  }

  createInEASI(personId: IPerson['id']) {
    return this.http.post<IPerson>(
      `${this.personsUrl}/${personId}/create-in-easi`,
      {},
    );
  }

  syncWithEASI(personId: IPerson['id']) {
    return this.http.post<IPerson>(
      `${this.personsUrl}/${personId}/sync-easi-from-lcl`,
      {},
    );
  }

  // Check account information
  checkAccountInformation(
    data: Pick<
      IPerson,
      'birthdate' | 'email' | 'ffbId' | 'firstName' | 'lastName'
    >,
  ) {
    return this.http.post<IAccountError>(`${this.personsUrl}/check`, data).pipe(
      map((data) => {
        if (data.code in AccountErrorCode) {
          return data;
        }
        return null;
      }),
    );
  }
}
