<div
  [ngClass]="{
    'bg-primary': backgroundColor === 'primary',
    'bg-white': backgroundColor === 'white',
    'bg-blue-dark-overlay': !backgroundColor,
    transparent: backgroundColor === 'transparent',
    opaque: !!backgroundColor && backgroundColor !== 'transparent'
  }"
  class="overlay flex flex-column justify-center items-center center"
  tuiOverscroll="all"
>
  <ng-content></ng-content>
</div>
