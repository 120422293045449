<ng-container *transloco="let t">
  <ng-container
    *tuiLet="optimumScore ? (optimumScore | objectEntries) : [] as data"
    >
    <ng-template #emptyTemplate>
      <p class="center italic my2">
        {{ t('components.optimumScore.noData') }}
      </p>
    </ng-template>

    <ng-template #suitTemplate let-columnHeader="columnHeader">
      @if (columnHeader.key !== 'declarer') {
        <ffb-suit-icon
          [suit]="columnHeader.key"
          [fittext]="true"
        ></ffb-suit-icon>
      }
    </ng-template>

    <ng-template #declarerTemplate let-cellData="cellData">
      {{ t('components.cardinalPoints.' + cellData + '.short') }}
    </ng-template>

    <ng-template #tricksTemplate let-cellData="cellData">
      {{ cellData === 0 ? '-' : cellData }}
    </ng-template>

    <ffb-table
      name="optimum-score-table"
      [data]="data"
      [columns]="['declarer', 'C', 'D', 'H', 'S', 'NT']"
      [columnHeaders]="[
        [
          {
            key: 'declarer',
            label: ''
          },
          {
            key: 'C'
          },
          {
            key: 'D'
          },
          {
            key: 'H'
          },
          {
            key: 'S'
          },
          {
            key: 'NT'
          }
        ]
      ]"
    [getCellData]="getCellData"
      [headerCellTemplates]="{
        C: suitTemplate,
        D: suitTemplate,
        H: suitTemplate,
        S: suitTemplate,
        NT: suitTemplate
      }"
      [rowCellTemplates]="{
        declarer: declarerTemplate,
        C: tricksTemplate,
        D: tricksTemplate,
        H: tricksTemplate,
        S: tricksTemplate,
        NT: tricksTemplate
      }"
      [layout]="{
        C: { align: 'center' },
        D: { align: 'center' },
        H: { align: 'center' },
        S: { align: 'center' },
        NT: { align: 'center' }
      }"
    [emptyTemplate]="emptyTemplate"
    >
  </ffb-table>
</ng-container>
</ng-container>
