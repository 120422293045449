import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ffb-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class RowComponent {
  @Input() maxWidth: 1 | 2 | 3 | 4 | 5 | null = 5;
  @Input() xPadding = true;
  @Input() yPadding = true;
}
