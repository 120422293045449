<!--  Free / Full Screen Width -->
@if (disposition === 'free' || disposition === 'fullScreenWidth') {
  <img
    [src]="imageUrl"
    [attr.alt]="alternativeText"
    [attr.width]="width"
    [attr.height]="height"
    [class]="imageClass"
    [class.screen-width]="disposition === 'fullScreenWidth'"
    [class.bg-base-02]="loading"
    />
}

<!--  As background -->
@if (disposition === 'background') {
  <div
    [class]="backgroundClass"
    [class.bg-base-02]="loading"
    [ngStyle]="{
      backgroundImage: 'url(' + imageUrl + ')',
      backgroundSize: this.backgroundSize,
      backgroundPosition: this.backgroundPosition
    }"
  ></div>
}
