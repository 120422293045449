import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TIV } from '@lancelot-frontend/api';
import { ReplaceSuitIconsPipe } from '../../pipes/replace-suit-icons.pipe';

@Component({
  selector: 'ffb-iv-badge',
  templateUrl: './iv-badge.component.html',
  styleUrls: ['./iv-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, ReplaceSuitIconsPipe],
})
export class IvBadgeComponent {
  @Input({ required: true }) iv!: TIV;
}
