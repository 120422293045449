<ng-container *transloco="let t">
  <ng-container *tuiLet="breakpointObserver.ltMd$ | async as ltMd">
    <ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
      <ng-template
        #boardNumberTemplate
        let-cellData="cellData"
        let-rowData="rowData"
        let-rowIndex="rowIndex"
        >
        <div class="flex flex-row justify-between">
          {{ cellData }}
          @if (getRowLink?.(rowData, rowIndex)) {
            <button
              tuiIconButton
              size="xs"
              appearance="primary-outline"
              shape="rounded"
              icon="tuiIconPlus"
              class="no-print"
            ></button>
          }
        </div>
      </ng-template>

      <ng-template
        #contractTemplate
        let-cellData="cellData"
        let-rowData="rowData"
        >
        @if (cellData && cellData !== '-') {
          <ffb-contract [contract]="cellData"></ffb-contract>
        }
        @if (ltMd && cellData && cellData.toUpperCase() !== 'PASS') {
          {{ t('components.cardinalPoints.' + rowData.declarer + '.short') }}
          @if (ltSm) {
            {{ rowData.result }}
          }
        }
      </ng-template>

      <ng-template #declarerTemplate let-cellData="cellData">
        @if (cellData && cellData !== '-') {
          {{ t('components.cardinalPoints.' + cellData + '.short') }}
        }
      </ng-template>

      <ng-template #leadTemplate let-cellData="cellData">
        @if (cellData && cellData !== '-') {
          <ffb-opening-lead [openingLead]="cellData"></ffb-opening-lead>
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
      </ng-template>

      <ng-template #resultTemplate let-cellData="cellData">
        @if (cellData && cellData !== '-') {
          {{ cellData }}
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
      </ng-template>

      <ng-template #scoreTemplate let-cellData="cellData">
        @if (cellData !== '') {
          {{ cellData }}
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
      </ng-template>

      <ng-template #noteTemplate let-cellData="cellData">
        @if (cellData !== undefined) {
          {{
          cellData
          | decimalWithUnit: {}:(boardScoringType === 'percent' ? '%' : '')
          }}
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
      </ng-template>

      <ng-template #placeholder>
        <span class="color-text-03">—</span>
      </ng-template>


      <ng-template
        #rowHeaderTemplate
        let-rowData="rowData"
        let-rowIndex="rowIndex"
        >
        <ng-container *tuiLet="rowData.lineup | as: TLineup as rowLineup">
          @if (
            rowLineup &&
            (rowIndex === 0 ||
            pairSessionScores[rowIndex - 1].lineup.id !== rowLineup.id)
            ) {
            <div
              class="flex flex-row justify-between items-center gap2 p1 bg-base-03 bold"
              *tuiLet="getOpponentsForBoard(rowData) as opponents"
              >
              <ng-template #lineupScoreTemplate>
                <ng-container
                  *tuiLet="
                    getScoreForLineup(rowLineup.id, rowIndex) as lineupScore
                  "
                  >
                  @if (lineupScore !== undefined) {
                    {{
                    lineupScore
                    | decimalWithUnit
                    : { maximumFractionDigits: 2 }
                    : (boardScoringType === 'percent' ? '%' : '')
                    }}
                  }
                </ng-container>
              </ng-template>
              <div>
                <div class="hidden">
                  <ng-container
                    *ngTemplateOutlet="lineupScoreTemplate"
                  ></ng-container>
                </div>
              </div>
              <div class="flex-auto center">
                <ffb-team-players [players]="opponents"></ffb-team-players>
              </div>
              <div class="right-align">
                <ng-container
                  *ngTemplateOutlet="lineupScoreTemplate"
                ></ng-container>
              </div>
            </div>
          }
        </ng-container>
      </ng-template>

      <ffb-table
        name="roadmap-table"
        [loading]="loading"
        [data]="pairSessionScores"
        [getCellData]="getCellData"
        [columns]="
          ltSm
            ? ['boardNumber', 'contract', 'lead', 'score', 'note']
            : ltMd
            ? ['boardNumber', 'contract', 'lead', 'result', 'score', 'note']
            : [
                'boardNumber',
                'contract',
                'declarer',
                'lead',
                'result',
                'nsScore',
                'ewScore',
                'note'
              ]
        "
        [columnHeaders]="[
          ltSm
            ? [
                { key: 'boardNumber', label: t('components.board.short') },
                { key: 'contract', label: t('components.contract.short') },
                { key: 'lead', label: t('components.openingLead.short') },
                { key: 'score', label: t('components.score') },
                { key: 'note', label: t('components.note') }
              ]
            : ltMd
            ? [
                { key: 'boardNumber', label: t('components.board') },
                { key: 'contract', label: t('components.contract.short') },
                { key: 'lead', label: t('components.openingLead.short') },
                { key: 'result', label: t('components.result.short') },
                { key: 'score', label: t('components.score') },
                { key: 'note', label: t('components.note') }
              ]
            : [
                { key: 'boardNumber', label: t('components.board') },
                { key: 'contract', label: t('components.contract') },
                { key: 'declarer', label: t('components.declarer') },
                { key: 'lead', label: t('components.openingLead') },
                { key: 'result', label: t('components.result') },
                {
                  key: 'nsScore',
                  label:
                    t('components.score') +
                    ' ' +
                    t('components.orientation.NS.short')
                },
                {
                  key: 'ewScore',
                  label:
                    t('components.score') +
                    ' ' +
                    t('components.orientation.EW.short')
                },
                { key: 'note', label: t('components.note') }
              ]
        ]"
        [rowCellTemplates]="{
          boardNumber: boardNumberTemplate,
          contract: contractTemplate,
          declarer: declarerTemplate,
          lead: leadTemplate,
          result: resultTemplate,
          score: scoreTemplate,
          nsScore: scoreTemplate,
          ewScore: scoreTemplate,
          note: noteTemplate
        }"
        [rowHeaderTemplate]="rowHeaderTemplate"
        [layout]="{
          contract: { align: 'center' },
          declarer: { align: 'center' },
          lead: { align: 'center' },
          result: { align: 'center' },
          score: { align: 'right' },
          nsScore: { align: 'right' },
          ewScore: { align: 'right' },
          note: { align: 'right' }
        }"
        [selectedRowHandler]="selectedRowHandler"
        [getRowLink]="getRowLink"
        >
      </ffb-table>
    </ng-container>
  </ng-container>
</ng-container>
