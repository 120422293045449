import {
  Directive,
  EventEmitter,
  HostListener,
  INJECTOR,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[confirm]',
  standalone: true,
})
export class ConfirmDirective implements OnInit, OnChanges {
  private readonly dialogService = inject(TuiDialogService);
  private readonly injector = inject(INJECTOR);

  private confirmationDialog?: Observable<boolean>;

  @Input() confirmationTitle?: string;
  @Input() confirmationMessage?: string;
  @Input() confirmationButtonLabel?: string;
  @Input() typeToConfirmValue?: string;
  @Input() typeToConfirmMessage?: string;

  @Output() confirm = new EventEmitter<void>();

  ngOnInit() {
    this.setConfirmationDialog();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.confirmationTitle && !changes.confirmationTitle.firstChange) ||
      (changes.confirmationMessage &&
        !changes.confirmationMessage.firstChange) ||
      (changes.confirmationButtonLabel &&
        !changes.confirmationButtonLabel.firstChange) ||
      (changes.typeToConfirmValue && !changes.typeToConfirmValue.firstChange) ||
      (changes.typeToConfirmMessage &&
        !changes.typeToConfirmMessage.firstChange)
    ) {
      this.setConfirmationDialog();
    }
  }

  setConfirmationDialog() {
    this.confirmationDialog = this.dialogService.open<boolean>(
      new PolymorpheusComponent(ConfirmationDialogComponent, this.injector),
      {
        data: {
          confirmationTitle: this.confirmationTitle,
          confirmationMessage: this.confirmationMessage,
          confirmationButtonLabel: this.confirmationButtonLabel,
          typeToConfirmValue: this.typeToConfirmValue,
          typeToConfirmMessage: this.typeToConfirmMessage,
        },
      },
    );
  }

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.confirmationDialog?.subscribe({
      next: (confirmation) => {
        if (confirmation) {
          this.confirm.emit();
        }
      },
    });
  }
}
