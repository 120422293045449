<ng-template #playerInformation let-player="player">
  {{ player | personInformation: personInformationOptions }}
</ng-template>

<ng-template #winnerIcon>
  @if (winner) {
    <tui-svg
      src="/assets/icon-set/cup.svg"
      class="fit-text-height color-warning fill-yellow"
    [ngStyle]="{
      order: iconLeft ? -2 : undefined
    }"
    ></tui-svg>
  }
</ng-template>

@if (
  !hint && !expand && !asTeamLabel) {
  @if (iconLeft) {
    <ng-container *ngTemplateOutlet="winnerIcon"></ng-container>
  }
  @for (player of players; track player; let i = $index) {
    @if (
      maxNumberOfPlayersDisplayed === undefined ||
      i < maxNumberOfPlayersDisplayed
      ) {
      @if (inline) {
        <span class="white-space-normal">
          @if (i > 0) {
            -
          }
          <ng-container
            *ngTemplateOutlet="playerInformation; context: { player: player }"
          ></ng-container>
        </span>
      }
      @if (!inline) {
        <p>
          <ng-container
            *ngTemplateOutlet="playerInformation; context: { player: player }"
          ></ng-container>
        </p>
      }
    }
  }
  @if (!iconLeft) {
    <ng-container *ngTemplateOutlet="winnerIcon"></ng-container>
  }
} @else {
  @if (asTeamLabel) {
    <ng-container
      [ngTemplateOutlet]="teamLabelTemplate"
    ></ng-container>
  } @else {
    <div class="flex flex-row items-baseline" [class.justify-end]="justifyEnd">
      <span [ngStyle]="{ order: -3 }"></span>
      @if (hint) {
        <ng-container *ngTemplateOutlet="teamLabelTemplate"></ng-container>
        @if (players.length) {
          <button
            tuiIconButton
            appearance="icon"
            shape="rounder"
            size="xs"
            icon="/assets/icon-set/users.svg"
            class="no-print"
            [tuiHint]="teamHintTemplate"
            [class.ml1]="!iconLeft"
            [class.mr1]="iconLeft"
            [ngStyle]="{ order: iconLeft ? -1 : undefined }"
            (click)="preventDefault($event)"
          ></button>
        }
        <ng-template #teamHintTemplate>
          <div tuiMode="onDark">
            @for (player of players; track player) {
              <p>
                <ng-container
                  *ngTemplateOutlet="playerInformation; context: { player: player }"
                ></ng-container>
              </p>
            }
          </div>
        </ng-template>
      }
      @if (expand) {
        <div class="flex flex-row cursor-pointer" (click)="toggle($event)">
          <ng-container *ngTemplateOutlet="teamLabelTemplate"></ng-container>
          @if (players.length) {
            <tui-svg
              src="tuiIconChevronDown"
              class="icon no-print"
              [class.upside-down]="expanded"
            ></tui-svg>
          }
        </div>
      }
      <ng-container *ngTemplateOutlet="winnerIcon"></ng-container>
    </div>
    @if (expand && players.length) {
      <tui-expand [expanded]="expanded">
        <ng-template tuiExpandContent>
          @for (player of players; track player; let i = $index) {
            @if (i > 0 || teamLabel) {
              <p>
                <ng-container
                  *ngTemplateOutlet="playerInformation; context: { player: player }"
                ></ng-container>
              </p>
            }
          }
        </ng-template>
      </tui-expand>
    }
  }
}

<ng-template #teamLabelTemplate>
  @if (asTeamLabel && winner) {
    <ng-container *ngTemplateOutlet="winnerIcon"></ng-container>
  }
  <span>
    @if (teamLabel) {
      {{ teamLabel }}
    }
    @if (!teamLabel) {
      <ng-container
        *ngTemplateOutlet="playerInformation; context: { player: players[0] }"
      ></ng-container>
    }
  </span>
</ng-template>


