import { a as e, b as i } from "./chunk-LSZKOVGW.mjs";
import { j as r, k as o } from "./chunk-GTEBSQTL.mjs";
var n = ["Pvt Ltd", "Group", "Ltd", "Limited"];
var b = {
    suffix: n
  },
  m = b;
var p = ["np", "com", "info", "net", "org"];
var f = ["worldlink.com.np", "gmail.com", "yahoo.com", "hotmail.com"];
var N = {
    domain_suffix: p,
    free_email: f
  },
  u = N;
var l = ["Bhaktapur", "Biratnagar", "Birendranagar", "Birgunj", "Butwal", "Damak", "Dharan", "Gaur", "Gorkha", "Hetauda", "Itahari", "Janakpur", "Kathmandu", "Lahan", "Nepalgunj", "Pokhara"];
var h = ["{{location.city_name}}"];
var s = ["Nepal"];
var d = ["1####", "2####", "3####", "4####", "5####"];
var c = ["Baglung", "Banke", "Bara", "Bardiya", "Bhaktapur", "Bhojupu", "Chitwan", "Dailekh", "Dang", "Dhading", "Dhankuta", "Dhanusa", "Dolakha", "Dolpha", "Gorkha", "Gulmi", "Humla", "Ilam", "Jajarkot", "Jhapa", "Jumla", "Kabhrepalanchok", "Kalikot", "Kapilvastu", "Kaski", "Kathmandu", "Lalitpur", "Lamjung", "Manang", "Mohottari", "Morang", "Mugu", "Mustang", "Myagdi", "Nawalparasi", "Nuwakot", "Palpa", "Parbat", "Parsa", "Ramechhap", "Rauswa", "Rautahat", "Rolpa", "Rupandehi", "Sankhuwasabha", "Sarlahi", "Sindhuli", "Sindhupalchok", "Sunsari", "Surket", "Syangja", "Tanahu", "Terhathum"];
var k = ["{{person.firstName}} {{location.street_suffix}}", "{{person.lastName}} {{location.street_suffix}}"];
var K = {
    city_name: l,
    city_pattern: h,
    default_country: s,
    postcode: d,
    state: c,
    street_pattern: k
  },
  D = K;
var L = {
    title: "Nepali",
    code: "ne",
    language: "ne",
    endonym: "\u0928\u0947\u092A\u093E\u0932\u0940",
    dir: "ltr",
    script: "Deva"
  },
  g = L;
var a = ["Ajita", "Amita", "Ashmi", "Asmita", "Bina", "Deepika", "Laxmi", "Manisha", "Nikita", "Pooja", "Rajina", "Ratna", "Sarita", "Shilpa", "Shirisha", "Shristi", "Sunita", "Susan"];
var t = ["Aarav", "Amit", "Amrit", "Arijit", "Bibek", "Bijay", "Bikash", "Bishal", "Bishnu", "Buddha", "Dipendra", "Gagan", "Ganesh", "Khem", "Krishna", "Nabin", "Niraj", "Nischal", "Padam", "Prabin", "Prakash", "Prashant", "Prem", "Purna", "Rajendra", "Raju", "Rakesh", "Ranjan", "Sagar", "Sandeep", "Sanjay", "Santosh", "Siddhartha", "Subash", "Sumeet", "Suraj", "Sushant"];
var x = e(a, t);
var y = ["Adhikari", "Aryal", "Baral", "Basnet", "Bastola", "Basynat", "Bhandari", "Bhattarai", "Chettri", "Devkota", "Dhakal", "Dongol", "Ghale", "Gurung", "Gyawali", "Hamal", "Jung", "KC", "Kafle", "Karki", "Khadka", "Koirala", "Lama", "Limbu", "Magar", "Maharjan", "Niroula", "Pandey", "Pradhan", "Rana", "Raut", "Sai", "Shai", "Shakya", "Sherpa", "Shrestha", "Subedi", "Tamang", "Thapa"];
var S = [{
  value: "{{person.last_name}}",
  weight: 1
}];
var R = {
    female_first_name: a,
    first_name: x,
    last_name: y,
    last_name_pattern: S,
    male_first_name: t
  },
  B = R;
var _ = ["##-#######", "+977-#-#######", "+977########"];
var A = {
    formats: _
  },
  P = A;
var M = {
    company: m,
    internet: u,
    location: D,
    metadata: g,
    person: B,
    phone_number: P
  },
  j = M;
var La = new r({
  locale: [j, i, o]
});
export { j as a, La as b };