import { DOCUMENT, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TUI_SCROLL_REF, TuiLoaderModule } from '@taiga-ui/core';

@Component({
  selector: 'ffb-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, TuiLoaderModule],
})
export class PullToRefreshComponent implements AfterViewInit, OnChanges {
  private readonly documentRef = inject(DOCUMENT);
  private readonly nativeElement = inject(ElementRef).nativeElement;
  private readonly scrollRef = inject(TUI_SCROLL_REF, { optional: true });
  private changeDetectorRef = inject(ChangeDetectorRef);

  @Input() refreshing = false;
  @Input() disabled = false;

  activated = false;
  pullToRefresh = 90;
  startY = 0;
  startX = 0;
  moveY = 0;
  moveX = 0;
  pull = 0;
  maxPull = 138;
  animateY = 80;

  scrollHost =
    this.scrollRef?.nativeElement || this.documentRef.documentElement;

  @Output() refresh = new EventEmitter<void>();

  private getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (node === null) {
      return null;
    }

    if (node.tagName === 'SECTION' && node.role === 'dialog') {
      return node;
    } else {
      return this.getScrollParent(node.parentElement);
    }
  }

  ngAfterViewInit() {
    if (this.scrollHost === this.documentRef.documentElement) {
      this.scrollHost =
        this.getScrollParent(this.nativeElement) || this.scrollHost;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.refreshing?.currentValue === false ||
      changes.disabled?.currentValue === true
    ) {
      this.reset();
    }
  }

  @HostListener('window:touchstart', ['$event'])
  onTouchStart(e: TouchEvent) {
    if (this.disabled || this.activated) {
      return;
    }

    this.reset();

    this.startY = e.touches[0].pageY;
    this.startX = e.touches[0].pageX;
  }

  @HostListener('window:touchmove', ['$event'])
  onTouchMove(e: TouchEvent) {
    if (this.disabled) {
      return;
    }

    this.moveY = e.touches[0].pageY;
    this.moveX = e.touches[0].pageX;

    const shiftY = this.moveY - this.startY;
    const shiftX = this.moveX - this.startX;
    const ratio = Math.abs(shiftX) / Math.abs(shiftY);

    if (
      this.scrollHost.scrollTop === 0 &&
      this.moveY >= this.startY &&
      ratio <= 0.3
    ) {
      const pullShiftY = shiftY / 2;

      this.pull =
        pullShiftY >= this.maxPull
          ? this.maxPull
          : pullShiftY + (this.maxPull - pullShiftY) * 0.5;

      this.activated = this.pull >= this.pullToRefresh;

      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('window:touchend', ['$event'])
  @HostListener('window:touchcancel', ['$event'])
  onTouchEnd() {
    if (this.disabled) {
      return;
    }

    if (this.activated) {
      this.pull = this.animateY;

      this.refresh.emit();
    } else {
      this.reset();
    }
  }

  reset() {
    this.startY = 9999999;
    this.moveY = 0;

    this.activated = false;
    this.pull = 0;

    this.changeDetectorRef.detectChanges();
  }
}
