import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IBoardScore,
  IClub,
  ICompetitionDivision,
  ICompetitionGroup,
  ICompetitionGroupSessionWithGroup,
  ICompetitionRound,
  ICompetitionSession,
  IFestival,
  IPairSessionScore,
  IPerson,
  IRank,
  IRankTeam,
  IRoundButlerRank,
  IRoundGame,
  ISeason,
  ISimultaneousGroup,
  ITeamGame,
  ITeamRankings,
  TCompetitionsWithResultsListItem,
  TFederalCompetitionWithResults,
  TFestivalWithResults,
  TPaginatedList,
  TPaginationParams,
  TSearchCompetitionType,
} from '../types';
import { TCompetitionsSearchParams } from './competitions.service';

export type TCompetitionsWithResultsSearchParams<
  TType extends TSearchCompetitionType,
  TContext = 'registration' | 'results' | undefined,
> = Omit<TCompetitionsSearchParams<TContext>, 'searchCompetitionType'> & {
  searchCompetitionType: TType;
};

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  private http = inject(HttpClient);

  resultsUrl = inject(EnvironmentService).get('apiBaseUrl') + '/results';

  searchForCompetitionsWithResults<
    TType extends TSearchCompetitionType,
    TContext,
  >(params: TCompetitionsWithResultsSearchParams<TType, TContext>) {
    return this.http.get<
      TPaginatedList<TCompetitionsWithResultsListItem<TType>>
    >(`${this.resultsUrl}/search/`, {
      params,
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getFederalCompetitionResults(
    competitionDivisionId: ICompetitionDivision['id'],
    params: {
      seasonId?: 'current' | 'previous' | ISeason['id'];
    } = {},
  ) {
    return this.http.get<TFederalCompetitionWithResults>(
      `${this.resultsUrl}/competitionDivisions/${competitionDivisionId}`,
      {
        params,
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  getFestivalResults(
    festivalId: IFestival['id'],
    params: {
      seasonId?: 'current' | 'previous' | ISeason['id'];
    } = {},
  ) {
    return this.http.get<TFestivalWithResults>(
      `${this.resultsUrl}/festivals/${festivalId}`,
      {
        params,
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  getSignedInUserResults(params: TPaginationParams) {
    return this.http.get<
      TPaginatedList<ICompetitionGroupSessionWithGroup<'results'>>
    >(`${this.resultsUrl}/search/me`, {
      params,
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getSignedInUserLastResult() {
    return this.http.get<ICompetitionGroupSessionWithGroup<'results'> | null>(
      `${this.resultsUrl}/search/me/last`,
      {
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  getSessionPlayerRank(
    sessionId: ICompetitionSession['id'],
    ffbId: IPerson['ffbId'],
  ) {
    return this.http.get<IRank>(
      `${this.resultsUrl}/sessions/${sessionId}/ranking/${ffbId}`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getSessionPlayerGames(
    sessionId: ICompetitionSession['id'],
    ffbId: IPerson['ffbId'],
  ) {
    return this.http.get<IRoundGame[]>(
      `${this.resultsUrl}/sessions/${sessionId}/games/${ffbId}`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getSimultaneousSessionGroups(sessionId: ICompetitionSession['id']) {
    return this.http.get<ISimultaneousGroup[]>(
      `${this.resultsUrl}/sessions/${sessionId}/simultaneousIds`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getSessionRanking(
    sessionId: ICompetitionSession['id'],
    params: {
      simultaneousId?: IClub['ffbCode'] | ICompetitionGroup['migrationId'];
    } = {},
  ) {
    return this.http.get<IRank[]>(
      `${this.resultsUrl}/sessions/${sessionId}/ranking`,
      { params, context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getPaginatedSessionRanking(
    sessionId: ICompetitionSession['id'],
    params: TPaginationParams & {
      simultaneousId?: IClub['ffbCode'] | ICompetitionGroup['migrationId'];
    } = {},
  ) {
    return this.http.get<TPaginatedList<IRank>>(
      `${this.resultsUrl}/sessions/${sessionId}/ranking`,
      {
        params: { ...params, paginate: true },
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }

  getRoundRanking(roundId: ICompetitionRound['id']) {
    return this.http.get<IRank[]>(
      `${this.resultsUrl}/rounds/${roundId}/ranking`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getRoundGames(roundId: ICompetitionRound['id']) {
    return this.http.get<IRoundGame[]>(
      `${this.resultsUrl}/rounds/${roundId}/games`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getGroupButler(
    groupId: ICompetitionGroup['id'],
    roundId: ICompetitionRound['id'],
  ) {
    return this.http.get<IRoundButlerRank[]>(
      `${this.resultsUrl}/groups/${groupId}/butlerRanking/${roundId}`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getGroupGames(groupId: ICompetitionGroup['id']) {
    return this.http.get<ITeamGame[]>(
      `${this.resultsUrl}/groups/${groupId}/games`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getTeam(teamId: IRank['team']['id']) {
    return this.http.get<IRankTeam>(`${this.resultsUrl}/teams/${teamId}`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getPairSessionScores(
    teamId: IRank['team']['id'],
    sessionId: ICompetitionSession['id'],
  ) {
    return this.http.get<IPairSessionScore[]>(
      `${this.resultsUrl}/teams/${teamId}/session/${sessionId}/scores`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getTeamGames(teamId: IRank['team']['id']) {
    return this.http.get<ITeamGame[]>(
      `${this.resultsUrl}/teams/${teamId}/games`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getTeamRankings(teamId: IRank['team']['id']) {
    return this.http.get<ITeamRankings>(
      `${this.resultsUrl}/teams/${teamId}/rankings`,
      { context: new HttpContext().set(IS_CACHE_ENABLED, true) },
    );
  }

  getGame(gameId: ITeamGame['id']) {
    return this.http.get<ITeamGame>(`${this.resultsUrl}/games/${gameId}`, {
      context: new HttpContext().set(IS_CACHE_ENABLED, true),
    });
  }

  getBoardScores(boardId: number) {
    return this.http.get<IBoardScore[]>(
      `${this.resultsUrl}/scores/board/${boardId}`,
      {
        context: new HttpContext().set(IS_CACHE_ENABLED, true),
      },
    );
  }
}
