import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruitmentService {
  private http = inject(HttpClient);

  recruitmentUrl =
    inject(EnvironmentService).get('apiBaseUrl') + '/recruitment';

  activateLearnInClubOffer(data: { email: string; zipcode: string }) {
    return this.http.post(`${this.recruitmentUrl}/learn-in-club`, {
      data,
    });
  }

  activateLearnOnlineOffer(data: { email: string; zipcode: string }) {
    return this.http.post(`${this.recruitmentUrl}/learn-online`, {
      data,
    });
  }

  activateBridgeBox(data: { code: string; email: string }) {
    return this.http.post(`${this.recruitmentUrl}/box`, {
      data,
    });
  }

  participateToBelambraDraw() {
    return this.http.post(`${this.recruitmentUrl}/belambra-2024-04`, {
      data: {},
    });
  }
}
