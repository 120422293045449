import { NgStyle, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MediaService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle],
})
export class ImageComponent implements OnChanges {
  private changeDetector = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);
  private mediaService = inject(MediaService);

  @Input() url!: string;
  @Input() alternativeText?: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() aspectRatio?: number;
  @Input() disposition: 'background' | 'free' | 'fullScreenWidth' = 'free';
  @Input() backgroundSize = 'cover';
  @Input() backgroundPosition = 'center';
  @Input() backgroundClass = '';
  @Input() imageClass = 'mx-auto';

  loading = true;
  imageUrl = '';

  getHighDefImageUrl(originalUrl: string) {
    if (this.aspectRatio) {
      return this.mediaService.getImageUrlForAspectRatio(
        originalUrl,
        this.aspectRatio,
      );
    }
    return this.url;
  }

  getLowDefImageUrl(highDefImageUrl: string) {
    return this.mediaService.getImageUrlForLQIP(highDefImageUrl);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      const highDefImageUrl = this.getHighDefImageUrl(this.url);
      const lowDefImageUrl = this.getLowDefImageUrl(highDefImageUrl);

      if (!isPlatformServer(this.platformId)) {
        this.imageUrl = lowDefImageUrl;
        const imageToLoad = new Image();
        imageToLoad.src = highDefImageUrl;
        imageToLoad.onload = () => {
          this.imageUrl = highDefImageUrl;
          this.loading = false;
          this.changeDetector.detectChanges();
        };
      } else {
        this.imageUrl = highDefImageUrl;
        this.loading = false;
      }
    }
  }
}
