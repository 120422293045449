import { b as e } from "./chunk-LSZKOVGW.mjs";
import { j as t, k as o } from "./chunk-GTEBSQTL.mjs";
var r = ["082 ### ####", "083 ### ####", "085 ### ####", "086 ### ####", "087 ### ####", "089 ### ####"];
var h = {
    formats: r
  },
  i = h;
var n = ["ie", "com", "net", "info", "eu"];
var g = {
    domain_suffix: n
  },
  a = g;
var f = ["{{location.city_prefix}} {{person.firstName}}{{location.city_suffix}}", "{{location.city_prefix}} {{person.firstName}}", "{{person.firstName}}{{location.city_suffix}}", "{{person.last_name}}{{location.city_suffix}}"];
var m = ["Carlow", "Cavan", "Clare", "Cork", "Donegal", "Dublin", "Galway", "Kerry", "Kildare", "Kilkenny", "Laois", "Leitrim", "Limerick", "Longford", "Louth", "Mayo", "Meath", "Monaghan", "Offaly", "Roscommon", "Sligo", "Tipperary", "Waterford", "Westmeath", "Wexford", "Wicklow"];
var p = ["Ireland"];
var l = ["A## ****", "D## ****", "E## ****", "F## ****", "H## ****", "K## ****", "N## ****", "P## ****", "R## ****", "T## ****", "V## ****", "W## ****", "X## ****", "Y## ****"];
var s = ["{{person.firstName}} {{location.street_suffix}}", "{{person.lastName}} {{location.street_suffix}}"];
var L = {
    city_pattern: f,
    county: m,
    default_country: p,
    postcode: l,
    street_pattern: s
  },
  c = L;
var I = {
    title: "English (Ireland)",
    code: "en_IE",
    country: "IE",
    language: "en",
    endonym: "English (Ireland)",
    dir: "ltr",
    script: "Latn"
  },
  u = I;
var d = [{
  value: "{{person.last_name}}",
  weight: 95
}, {
  value: "{{person.last_name}}-{{person.last_name}}",
  weight: 5
}];
var N = {
    last_name_pattern: d
  },
  x = N;
var y = ["01 #######", "021 #######", "022 #######", "023 #######", "024 #######", "025 #######", "026 #######", "027 #######", "028 #######", "029 #######", "0402 #######", "0404 #######", "041 #######", "042 #######", "043 #######", "044 #######", "045 #######", "046 #######", "047 #######", "049 #######", "0504 #######", "0505 #######", "051 #######", "052 #######", "053 #######", "056 #######", "057 #######", "058 #######", "059 #######", "061 #######", "062 #######", "063 #######", "064 #######", "065 #######", "066 #######", "067 #######", "068 #######", "069 #######", "071 #######", "074 #######", "090 #######", "091 #######", "093 #######", "094 #######", "095 #######", "096 #######", "097 #######", "098 #######", "099 #######"];
var E = {
    formats: y
  },
  _ = E;
var P = {
    cell_phone: i,
    internet: a,
    location: c,
    metadata: u,
    person: x,
    phone_number: _
  },
  D = P;
var ft = new t({
  locale: [D, e, o]
});
export { D as a, ft as b };