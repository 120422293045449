const landscape = {
  background: [
    {
      style: {
        height: '25%',
        top: 0,
        left: 0,
        transform: 'translate(15%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        left: 0,
        transform: 'translate(-60%, 25%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '30%',
        top: 0,
        right: '50%',
        transform: 'translate(-50%, -45%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '40%',
        top: 0,
        right: 0,
        transform: 'translate(-50%, -50%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        height: '80%',
        top: 0,
        right: 0,
        transform: 'translate(40%, -40%)',
      },
      class: 'bg-pink',
    },
    {
      style: {
        height: '40%',
        bottom: 0,
        left: '50%',
        transform: 'translate(10%, 65%)',
      },
      class: 'bg-purple',
    },
    {
      style: {
        height: '30%',
        top: 0,
        left: '70%',
        transform: 'translate(0, -50%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        height: '50%',
        bottom: 0,
        left: 0,
        transform: 'translate(-20%, 60%)',
      },
      class: 'bg-orange',
    },
  ],
  foreground: [],
};

const portrait = {
  background: [
    {
      style: {
        width: '40%',
        top: 0,
        left: 0,
        transform: 'translate(-50%, -40%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        width: '90%',
        bottom: 0,
        left: 0,
        transform: 'translate(-60%, 40%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        width: '40%',
        top: 0,
        right: 0,
        transform: 'translate(75%, -25%)',
      },
      class: 'bg-white-overlay',
    },
    {
      style: {
        width: '90%',
        bottom: 0,
        right: 0,
        transform: 'translate(60%, 80%)',
      },
      class: 'bg-pink',
    },
    {
      style: {
        width: '30%',
        top: 0,
        right: 0,
        transform: 'translate(20%, -50%)',
      },
      class: 'bg-blue',
    },
    {
      style: {
        width: '25%',
        bottom: 0,
        left: 0,
        transform: 'translate(-60%, 25%)',
      },
      class: 'bg-orange',
    },
  ],
  foreground: [],
};

export default {
  landscape,
  portrait,
};
