import type { PartialDeep } from 'type-fest';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IClub,
  ICommittee,
  IDistrict,
  IGenericOrganization,
  ILeague,
  IZone,
} from '../types';

type TOmitData =
  | 'clubs'
  | 'committees'
  | 'districts'
  | 'ffbCode'
  | 'id'
  | 'migrationId'
  | 'phone1'
  | 'type';

@Injectable({
  providedIn: 'root',
})
export class EntitiesService {
  private http = inject(HttpClient);

  organizationsUrl =
    inject(EnvironmentService).get('apiBaseUrl') + '/organizations';
  genericOrganizationsUrl = this.organizationsUrl + '/genericOrganizations';
  clubsUrl = this.organizationsUrl + '/clubs';
  committeesUrl = this.organizationsUrl + '/committees';
  groupmentsUrl = this.organizationsUrl + '/groupments';
  districtsUrl = this.groupmentsUrl + '/districts';
  leaguesUrl = this.groupmentsUrl + '/leagues';
  zonesUrl = this.groupmentsUrl + '/zones';

  createGenericOrganization(
    data: Omit<IGenericOrganization, TOmitData> & {
      phone1: Omit<IGenericOrganization['phone1'], 'id'>;
    },
  ) {
    return this.http.post<IGenericOrganization>(
      `${this.genericOrganizationsUrl}/`,
      data,
    );
  }

  getGenericOrganization(organizationId: IGenericOrganization['id']) {
    return this.http.get<IGenericOrganization>(
      `${this.genericOrganizationsUrl}/${organizationId}`,
    );
  }

  updateGenericOrganization(
    organizationId: IGenericOrganization['id'],
    data: PartialDeep<Omit<IGenericOrganization, TOmitData>> & {
      phone1?: Partial<IGenericOrganization['phone1']>;
    },
  ) {
    return this.http.post<IGenericOrganization>(
      `${this.genericOrganizationsUrl}/${organizationId}`,
      data,
    );
  }

  deleteGenericOrganization(organizationId: IGenericOrganization['id']) {
    return this.http.delete(
      `${this.genericOrganizationsUrl}/${organizationId}`,
    );
  }

  createClub(
    data: Omit<IClub, TOmitData> & {
      phone1: Omit<IClub['phone1'], 'id'>;
    },
  ) {
    return this.http.post<IClub>(`${this.clubsUrl}/`, data);
  }

  getClub(clubId: IClub['id']) {
    return this.http.get<IClub>(`${this.clubsUrl}/${clubId}`);
  }

  updateClub(
    clubId: IClub['id'],
    data: PartialDeep<Omit<IClub, TOmitData>> & {
      phone1?: Partial<IClub['phone1']>;
    },
  ) {
    return this.http.post<IClub>(`${this.clubsUrl}/${clubId}`, data);
  }

  deleteClub(clubId: IClub['id']) {
    return this.http.delete(`${this.clubsUrl}/${clubId}`);
  }

  createCommittee(
    data: Omit<ICommittee, TOmitData> & {
      phone1: Omit<ICommittee['phone1'], 'id'>;
    },
  ) {
    return this.http.post<ICommittee>(`${this.committeesUrl}/`, data);
  }

  getCommittee(committeeId: ICommittee['id']) {
    return this.http.get<ICommittee>(`${this.committeesUrl}/${committeeId}`);
  }

  updateCommittee(
    committeeId: ICommittee['id'],
    data: PartialDeep<Omit<ICommittee, TOmitData>> & {
      phone1?: Partial<ICommittee['phone1']>;
    },
  ) {
    return this.http.post<ICommittee>(
      `${this.committeesUrl}/${committeeId}`,
      data,
    );
  }

  deleteCommittee(committeeId: ICommittee['id']) {
    return this.http.delete(`${this.committeesUrl}/${committeeId}`);
  }

  createDistrict(data: Omit<IDistrict, TOmitData>) {
    return this.http.post<IDistrict>(`${this.districtsUrl}/`, data);
  }

  getDistrict(districtId: IDistrict['id']) {
    return this.http.get<IDistrict>(`${this.districtsUrl}/${districtId}`);
  }

  updateDistrict(
    districtId: IDistrict['id'],
    data: PartialDeep<Omit<IDistrict, TOmitData>>,
  ) {
    return this.http.post<IDistrict>(
      `${this.districtsUrl}/${districtId}`,
      data,
    );
  }

  deleteDistrict(districtId: IDistrict['id']) {
    return this.http.delete(`${this.districtsUrl}/${districtId}`);
  }

  createLeague(data: Omit<ILeague, TOmitData>) {
    return this.http.post<ILeague>(`${this.leaguesUrl}/`, data);
  }

  getLeague(leagueId: ILeague['id']) {
    return this.http.get<ILeague>(`${this.leaguesUrl}/${leagueId}`);
  }

  updateLeague(
    leagueId: ILeague['id'],
    data: PartialDeep<Omit<ILeague, TOmitData>>,
  ) {
    return this.http.post<ILeague>(`${this.leaguesUrl}/${leagueId}`, data);
  }

  deleteLeague(leagueId: ILeague['id']) {
    return this.http.delete(`${this.leaguesUrl}/${leagueId}`);
  }

  createZone(data: Omit<IZone, TOmitData>) {
    return this.http.post<IZone>(`${this.zonesUrl}/`, data);
  }

  getZone(zoneId: IZone['id']) {
    return this.http.get<IZone>(`${this.zonesUrl}/${zoneId}`);
  }

  updateZone(zoneId: IZone['id'], data: PartialDeep<Omit<IZone, TOmitData>>) {
    return this.http.post<IZone>(`${this.zonesUrl}/${zoneId}`, data);
  }

  deleteZone(zoneId: IZone['id']) {
    return this.http.delete(`${this.zonesUrl}/${zoneId}`);
  }
}
