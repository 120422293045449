<ng-container *transloco="let t">
  <ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
    <ng-template #table>
      <table
        tuiTable
        [columns]="columns"
        [attr.data-test-id]="name"
        [class.withActions]="!!actions"
        [class.withRowHeader]="!!rowHeaderTemplate"
        [class.withoutHeader]="!columnHeaders.length"
        class="ffb-table"
      >
        <!-- HEAD -->
        @if (columnHeaders.length) {
          <thead attr.data-test-id="{{ name }}-head">
            @for (row of columnHeaders; track row; let i = $index) {
              <tr tuiThGroup>
                @for (columnHeader of row; track columnHeader) {
                  <th
                    tuiTh
                    (click)="
                      columnHeader.sortable && updateSort(columnHeader.key)
                    "
                    [sorter]="null"
                    [attr.rowSpan]="layout[columnHeader.key]?.header?.rowSpan"
                    [attr.colSpan]="layout[columnHeader.key]?.header?.colSpan"
                    [ngClass]="{
                      sortable: columnHeader.sortable,
                      'left-align':
                        (layout.align === 'left' &&
                          !layout[columnHeader.key]?.align) ||
                        layout[columnHeader.key]?.align === 'left' ||
                        layout[columnHeader.key]?.header?.align === 'left',
                      'right-align':
                        (layout.align === 'right' &&
                          !layout[columnHeader.key]?.align) ||
                        layout[columnHeader.key]?.align === 'right' ||
                        layout[columnHeader.key]?.header?.align === 'right',
                      center:
                        (layout.align === 'center' &&
                          !layout[columnHeader.key]?.align) ||
                        layout[columnHeader.key]?.align === 'center' ||
                        layout[columnHeader.key]?.header?.align === 'center',
                      'border-top':
                        layout[columnHeader.key]?.header?.borders?.top,
                      'border-right':
                        layout[columnHeader.key]?.header?.borders?.right,
                      'border-bottom':
                        layout[columnHeader.key]?.header?.borders?.bottom,
                      'border-left':
                        layout[columnHeader.key]?.header?.borders?.left
                    }"
                    [attr.id]="columnHeader.key"
                    [attr.data-test-id]="columnHeader.key"
                  >
                    <ng-template
                      #defaultHeaderCellTemplate
                      let-columnHeader="columnHeader"
                    >
                      {{
                        columnHeader.labelTranslationKey
                          ? t(columnHeader.labelTranslationKey)
                          : columnHeader.label
                      }}
                    </ng-template>
                    <ng-template
                      [ngTemplateOutlet]="
                        headerCellTemplates[columnHeader.key] ||
                        defaultHeaderCellTemplate
                      "
                      [ngTemplateOutletContext]="{ columnHeader: columnHeader }"
                    ></ng-template>
                    @if (columnHeader.sortable) {
                      <tui-svg
                        [src]="
                          sortBy === columnHeader.key && direction === -1
                            ? 'tuiIconSortDown'
                            : sortBy === columnHeader.key && direction === 1
                              ? 'tuiIconSortUp'
                              : 'tuiIconSortOff'
                        "
                        class="fit-text"
                      ></tui-svg>
                    }
                  </th>
                }
              </tr>
            }
          </thead>
        }

        <!-- BODY -->
        <tbody tuiTbody [data]="data" attr.data-test-id="{{ name }}-body">
          @for (item of data; track item; let i = $index) {
            @if (rowHeaderTemplate) {
              <tr class="ffb-table-row-header">
                <td [colSpan]="columns.length">
                  <ng-template
                    [ngTemplateOutlet]="rowHeaderTemplate"
                    [ngTemplateOutletContext]="{ rowData: item, rowIndex: i }"
                  ></ng-template>
                </td>
              </tr>
            }
            <ng-container *tuiLet="getRowLink?.(item, i) as rowLink">
              @if (rowLink) {
                <a
                  class="ffb-table-row-link"
                  [routerLink]="rowLink?.[0]"
                  [state]="rowLink?.[1]?.state"
                  [queryParams]="rowLink?.[1]?.queryParams"
                >
                  <ng-template [ngTemplateOutlet]="row"></ng-template>
                </a>
              } @else {
                <ng-template [ngTemplateOutlet]="row"></ng-template>
              }
              <ng-template #row>
                @if (rowTemplate) {
                  <tr
                    class="ffb-table-row"
                    [class.cursor-pointer]="clickOnRow.observers.length > 0"
                    [class.selected]="selectedRowHandler?.(item)"
                    [ngClass]="getRowClass?.(item, i)"
                    (click)="onClickOnRow(item, i)"
                    [attr.id]="
                      getRowId ? getRowId(item, i) : name + '-row-' + i
                    "
                    attr.data-test-id="{{ name }}-row-{{ i }}"
                  >
                    <ng-template
                      [ngTemplateOutlet]="rowTemplate"
                      [ngTemplateOutletContext]="{ rowData: item, rowIndex: i }"
                    ></ng-template>
                  </tr>
                } @else {
                  <tr
                    tuiTr
                    class="ffb-table-row"
                    [class.selected]="selectedRowHandler?.(item)"
                    [class.cursor-pointer]="clickOnRow.observers.length > 0"
                    [ngClass]="getRowClass?.(item, i)"
                    (click)="onClickOnRow(item, i)"
                    [attr.id]="
                      getRowId ? getRowId(item, i) : name + '-row-' + i
                    "
                    attr.data-test-id="{{ name }}-row-{{ i }}"
                  >
                    @for (column of columns; track column) {
                      <td
                        *tuiCell="column"
                        tuiTd
                        [attr.rowSpan]="layout[column]?.cell?.rowSpan"
                        [ngClass]="{
                          'left-align':
                            (layout.align === 'left' &&
                              !layout[column]?.align) ||
                            layout[column]?.align === 'left' ||
                            layout[column]?.cell?.align === 'left' ||
                            layout.cells?.align === 'left',
                          'right-align':
                            (layout.align === 'right' &&
                              !layout[column]?.align) ||
                            layout[column]?.align === 'right' ||
                            layout[column]?.cell?.align === 'right' ||
                            layout.cells?.align === 'right',
                          center:
                            (layout.align === 'center' &&
                              !layout[column]?.align) ||
                            layout[column]?.align === 'center' ||
                            layout[column]?.cell?.align === 'center' ||
                            layout.cells?.align === 'center',
                          'align-middle':
                            layout[column]?.cell?.verticalAlign === 'middle' ||
                            layout.cells?.verticalAlign === 'middle' ||
                            (layout[column]?.cell?.rowSpan ?? 1 > 2),
                          'border-top':
                            layout[column]?.cell?.borders?.top ||
                            layout.cells?.borders?.top,
                          'border-right':
                            layout[column]?.cell?.borders?.right ||
                            layout.cells?.borders?.right,
                          'border-bottom':
                            layout[column]?.cell?.borders?.bottom ||
                            layout.cells?.borders?.bottom,
                          'border-left':
                            layout[column]?.cell?.borders?.left ||
                            layout.cells?.borders?.left
                        }"
                        [hidden]="
                          layout[column]?.cell?.rowSpan &&
                          i % layout[column]?.cell?.rowSpan! !== 0
                        "
                        [attr.id]="column"
                        attr.data-test-id="{{ name }}-row-{{ i }}-{{ column }}"
                      >
                        @if (column === 'rowIndex') {
                          {{
                            i +
                              1 +
                              (ltSm
                                ? 0
                                : ((pagination?.page ?? 1) - 1) *
                                  (pagination?.itemsPerPage ?? 0))
                          }}
                        }
                        @if (column !== 'rowIndex' && column !== 'actions') {
                          <ng-template
                            #defaultRowCellTemplate
                            let-cellData="cellData"
                          >
                            {{ cellData }}
                          </ng-template>
                          <ng-template
                            [ngTemplateOutlet]="
                              rowCellTemplates[column] ||
                              rowCellTemplate ||
                              defaultRowCellTemplate
                            "
                            [ngTemplateOutletContext]="{
                              column: column,
                              cellData: getCellData(item, column, i),
                              rowData: item,
                              rowIndex: i
                            }"
                          ></ng-template>
                        }
                        @if (column === 'actions') {
                          <ng-container
                            *polymorpheusOutlet="
                              actions as text;
                              context: { item: item }
                            "
                          >
                            {{ text }}
                          </ng-container>
                        }
                      </td>
                    }
                  </tr>
                }
              </ng-template>
            </ng-container>
          }

          <ng-template #defaultEmptyTemplate>
            <p class="center italic my2">
              {{ t('components.noData') }}
            </p>
          </ng-template>

          @if (!loading && !data.length) {
            <tr>
              <td [colSpan]="columns.length">
                <ng-template
                  [ngTemplateOutlet]="emptyTemplate || defaultEmptyTemplate"
                ></ng-template>
              </td>
            </tr>
          }
        </tbody>

        <!-- FOOT -->
        @if (
          !ltSm &&
          !forceInfiniteScroll &&
          pagination &&
          pagination.pageCount > 1
        ) {
          <tfoot>
            <tr>
              <td [colSpan]="columns.length">
                <tui-pagination
                  [length]="pagination.pageCount"
                  [index]="pagination.page - 1"
                  (indexChange)="onPageChange($event + 1)"
                ></tui-pagination>
              </td>
            </tr>
          </tfoot>
        }
      </table>
    </ng-template>

    <!--  DESKTOP TABLE  -->
    @if (!ltSm && !forceInfiniteScroll) {
      <div class="relative">
        <tui-loader [overlay]="true" [showLoader]="loading || loadingNewPage">
          <ng-container *ngTemplateOutlet="table"></ng-container>
        </tui-loader>
      </div>
    } @else {
      <!--  MOBILE TABLE WITH INFINITE SCROLL  -->
      <ffb-infinite-scroll (reachEnd)="onReachEnd()">
        <tui-loader [overlay]="true" [showLoader]="loading">
          <ng-container *ngTemplateOutlet="table"></ng-container>
        </tui-loader>
        <tui-loader
          [showLoader]="loadingNewPage"
          [class.my2]="loadingNewPage"
        ></tui-loader>
      </ffb-infinite-scroll>
    }
  </ng-container>
</ng-container>
