import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiOverscrollModule } from '@taiga-ui/cdk';

@Component({
  selector: 'ffb-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TuiOverscrollModule],
})
export class OverlayComponent {
  @Input() backgroundColor?: 'primary' | 'transparent' | 'white';
}
