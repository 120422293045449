import { NgClass, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiSvgModule } from '@taiga-ui/core';
import {
  IPerson,
  TCardinalPoint,
  TContract,
  TDeal,
  TLead,
  TParsedDeal,
  TParsedLead,
  TScoringType,
  TSuit,
  TVulnerability,
  cardinalPoints,
  parseDeal,
  parseLead,
  vulnerabilities,
} from '@lancelot-frontend/api';
import {
  AsPipe,
  DecimalWithUnitPipe,
  MediaService,
  TypeofPipe,
} from '@lancelot-frontend/core';
import { ContractComponent } from '../contract/contract.component';
import { DeclarerComponent } from '../declarer/declarer.component';
import { ImageComponent } from '../image/image.component';
import { SuitIconComponent } from '../suit-icon/suit-icon.component';

@Component({
  selector: 'ffb-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    ResizeObserverModule,
    NgTemplateOutlet,
    TuiLetModule,
    NgClass,
    ImageComponent,
    TuiSvgModule,
    SuitIconComponent,
    ContractComponent,
    DeclarerComponent,
    UpperCasePipe,
    TypeofPipe,
    DecimalWithUnitPipe,
    AsPipe,
  ],
})
export class BoardComponent implements OnInit, OnChanges {
  private mediaService = inject(MediaService);

  @Input() boardNumber?: number;
  @Input() deal?: TDeal;
  @Input() dealer?: TCardinalPoint;
  @Input() declarer?: '-' | TCardinalPoint;
  @Input() vulnerability?: TVulnerability;
  @Input() lead?: '-' | TLead;
  @Input() lineup?: Partial<Record<TCardinalPoint, IPerson | string>>;
  @Input() contract?: '-' | TContract;
  @Input() tricks?: number;
  @Input() result?: string;
  @Input() score?: string;
  @Input() note?: number;
  @Input() scoringType?: TScoringType;

  parsedDeal?: TParsedDeal;
  parsedLead?: TParsedLead;
  shrunk?: boolean;

  TCardinalPoint!: TCardinalPoint;
  TSuit!: TSuit;
  TContract!: TContract;

  ngOnInit() {
    this.parseDeal();
    this.parseLead();
    if (!this.vulnerability) {
      this.computeVulnerability();
    }
    if (!this.dealer) {
      this.computeDealer();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dealer) {
      this.dealer = changes.dealer?.currentValue;
    }
    if (changes.vulnerability) {
      this.vulnerability = changes.vulnerability?.currentValue;
    }
    if (changes.deal && !changes.deal.firstChange) {
      this.parseDeal();
      if (!changes.dealer?.currentValue) {
        this.computeDealer();
      }
    }
    if (changes.lead && !changes.lead.firstChange) {
      this.parseLead();
    }
    if (changes.boardNumber && !changes.boardNumber.firstChange) {
      if (!changes.vulnerability?.currentValue) {
        this.computeVulnerability();
      }
      if (!changes.dealer?.currentValue) {
        this.computeDealer();
      }
    }
  }

  parseDeal() {
    if (this.deal) {
      this.parsedDeal = parseDeal(this.deal);
    }
  }

  parseLead() {
    if (this.lead && this.lead !== '-') {
      this.parsedLead = parseLead(this.lead);
    } else {
      this.parsedLead = undefined;
    }
  }

  computeVulnerability() {
    if (this.boardNumber !== undefined) {
      this.vulnerability = vulnerabilities[(this.boardNumber - 1) % 16];
    }
  }

  computeDealer() {
    if (this.boardNumber !== undefined) {
      this.dealer = cardinalPoints[(this.boardNumber - 1) % 4];
    } else if (this.deal) {
      this.dealer = this.deal[0] as TCardinalPoint;
    }
  }

  getAvatarUrl(player: IPerson | string) {
    if (typeof player === 'string' || !player.avatarUrl) {
      return '';
    }

    return this.mediaService.getAvatarUrl(player.avatarUrl);
  }

  onResize(entries: ResizeObserverEntry[]) {
    if (entries[0]) {
      this.shrunk = entries[0].contentRect.width < 400;
    }
  }
}
