import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IPerson,
  IUser,
  TAddress,
  TEntityLog,
  TPhone,
  TResultsImportLog,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private http = inject(HttpClient);

  apiUrl = inject(EnvironmentService).get('apiBaseUrl');

  getResultsImportsLogs(date = '') {
    return this.http.get<TResultsImportLog[]>(
      `${this.apiUrl}/results/imports/logs/${date}`,
    );
  }

  getUserLogs(id: IUser['id']) {
    return this.http.get<TEntityLog[]>(`${this.apiUrl}/users/${id}/logs`);
  }

  getPersonLogs(id: IPerson['id']) {
    return this.http.get<TEntityLog[]>(`${this.apiUrl}/persons/${id}/logs`);
  }

  getAddressLogs(id: TAddress['id']) {
    return this.http.get<TEntityLog[]>(`${this.apiUrl}/addresses/${id}/logs`);
  }

  getPhoneLogs(id: TPhone['id']) {
    return this.http.get<TEntityLog[]>(`${this.apiUrl}/phones/${id}/logs`);
  }
}
